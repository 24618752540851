import React, { useState } from 'react';

function Button({ btnName, btnImg, hoverImg }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='w-full text-white hover:text-agro_darkgreen justify-center'>
      <button 
        className='w-52 flex flex-row gap-4 items-center p-2 px-3 rounded-md hover:bg-agro_light/60 '
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img src={isHovered ? hoverImg : btnImg} className='w-7 h-7' alt="icon" />
        <h1 className='text-sm font-bold '>{btnName}</h1>
      </button>
    </div>
  );
}

export default Button;
