import React, { useEffect, useState } from 'react';
import CustomAxios from '../../../../API/CustomAxios';
import { useSelector } from 'react-redux';
import { API_BASE_NORMALSCAP } from '../../../../API/APIUrls';

function CustomerDetailsperorder({ farmerID, newBillingAdress }) {
  const [farmerDetails, setFarmerDetails] = useState("");
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  const [userId, setUserID] = useState(useSelector((state) => state.auth.userID));

  useEffect(() => {
    getcustomerDetails();
  }, [farmerID]);

  // Fetch the customer details by using the farmer username
  const getcustomerDetails = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_NORMALSCAP,
        url: `supplier/farmer/${userId}/${farmerID}`,
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });
      if (response.status === 200) {
        console.log("farmer", response.data);
        setFarmerDetails(response.data.user);
        newBillingAdress(response.data.user.address);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rounded-md p-4 flex flex-col gap-6 bg-white shadow-md w-auto">
      <h1>CUSTOMER DETAILS</h1>
      <div className="w-full text-[12px] text-black/50 flex flex-col gap-5">
        <div className="flex flex-row justify-between w-full">
          <label className="font-semibold w-2/5">Name</label>
          <span className="w-3/5 flex-wrap break-words">: {farmerDetails.name}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <label className="font-semibold w-2/5">Email</label>
          <span className="w-3/5 flex-wrap break-words">: {farmerDetails.email}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <label className="font-semibold w-2/5">Contact</label>
          <span className="w-3/5 flex-wrap break-words">: {farmerDetails.contactno}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <label className="font-semibold w-2/5">Address</label>
          <span className="w-3/5 flex-wrap break-words">: {farmerDetails.address}</span>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetailsperorder;
