import React, { useEffect, useState } from 'react';
import CustomAxios from '../../../API/CustomAxios';
import { useSelector, useDispatch } from 'react-redux';
import edit from '../../../Assests/edit.png';
import deleteimg from "../../../Assests/delete.png";
import Alert from '../../../Components/Alert';
import HarvestForm from '../Harvest/HarvestForm';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { setCultivationData } from '../../ReduxManagement/cultivationSlice';

function CultivationDetails({ setSelectedCultivation,setGetAllCultivations }) {

  const dispatch = useDispatch();
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const userId = useSelector((state) => state.auth.userID);
  const [cultivationDetails, setCultivationDetails] = useState([]);  // Store all cultivation details
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deleteCultivation, setDeleteCultivation] = useState("");
  const [showHarvestForm, setShowHarvestForm] = useState(false);
  const [selectedCultivationForHarvest, setSelectedCultivationForHarvest] = useState(null);


  useEffect(()=>{
    setGetAllCultivations(()=>getCultivationDetails);
    getCultivationDetails();
  },[])

  // // Fetch cultivation details
  // useEffect(() => {
    
  
  //   getCultivationDetails();
  // }, [userId, dispatch]);
  
  const getCultivationDetails = async () => {
    try {
      const response = await CustomAxios({
        method: 'GET',
        baseURL: API_BASE_SMARTSCAP,
        url: 'getcultivationByFarmer',
        params: { farmerID: userId },
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        const sortedCultivations = response.data.Cultivation.sort(
          (a, b) => new Date(b.cultivationDate) - new Date(a.cultivationDate) // Sort in descending order
        );

        // Set cultivationDetails state with sorted array
        setCultivationDetails(sortedCultivations);

        // Dispatch the last added (latest) cultivation
        // dispatch(setCultivationData(sortedCultivations[0]));  // This will be the last added cultivation
      }
    } catch (error) {
      setAlertProps({
        message: 'An error occurred while fetching cultivation details.',
        severity: 'error',
        show: true,
      });
    }
  };
  // Handle Edit Cultivation
  const handleEdit = (cultivate) => {
    setSelectedCultivation(cultivate);
  };

  // Handle Delete Cultivation
  const handleDeleteCultivation = async () => {
    try {
      const response = await CustomAxios({
        method: 'DELETE',
        baseURL: API_BASE_SMARTSCAP,
        url: 'deleteCultivation',
        params: { 
          id: deleteCultivation.cultivationid 
        },
        headers: { 
          'Content-Type': 'application/json'
        },
      });
      if (response.status === 200) {
        setAlertProps({ message: `${deleteCultivation.season} Cultivation deleted successfully!`, severity: 'success', show: true });
        setShowDeleteModel(false);
        setCultivationDetails(cultivationDetails.filter(cultivate => cultivate.cultivationid !== deleteCultivation.cultivationid));
      }
    } catch (error) {
      setAlertProps({ message: 'An error occurred while deleting the cultivation.', severity: 'error', show: true });
      setShowDeleteModel(false);
    }
  };

  // Show delete confirmation model
  const handleSelectingDelete = (cultivate) => {
    setDeleteCultivation(cultivate);
    setShowDeleteModel(true);
  };

  // Show harvest form modal
  const handleAddHarvest = (cultivate) => {
    setSelectedCultivationForHarvest({
      landid:cultivate.landid,
      cultivationid: cultivate.cultivationid,  // Ensure it's the correct key
      farmerid: userId,            // Ensure it's the correct key
      ...cultivate,                           // Spread the rest of the cultivate details
    });
    setShowHarvestForm(true);  // Show the HarvestForm
  };

  // Hide alert
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full h-full">
      <h2 className="text-lg font-semibold text-center mb-6">Cultivation Details</h2>

      <div className="relative">
        <div className="absolute sm:left-4 mob:left-2 top-0 h-full border-l-2 border-gray-300"></div>
        <div className="mob:pl-10 mob2:pl-6 space-y-4">
          {cultivationDetails && cultivationDetails.map((cultivation, index) => (
            <div key={cultivation.CultivationID} className="relative flex items-center mb-4">
              <div className="absolute left-[-5px] top-[-5px] w-4 h-4 bg-green-500 rounded-full"></div>
              <div className="bg-gray-100 flex flex-row justify-between w-full items-center p-3 rounded-md shadow-sm flex-1">
                <div className="flex flex-col w-3/4">
                  <h3 className="font-semibold text-gray-700">{cultivation.season}</h3>
                  <p className="text-sm text-gray-600">
                    <span className="text-xs">Cultivated Size:</span> {cultivation.cultivated_landsize} acres
                  </p>
                  <p className="text-sm text-gray-600">
                    <span className="text-xs">Seed Type:</span> {cultivation.seed_type}
                  </p>
                  <p className="text-sm text-gray-600">
                    <span className="text-xs">Start Date:</span> {cultivation.start_date}
                  </p>
                </div>

                <div className="flex flex-row gap-2">
                  <button
                    className="w-24 h-6 bg-agro_light text-xs text-center p-1 rounded-md"
                    onClick={() => handleAddHarvest(cultivation)}
                  >
                    Add Harvest
                  </button>
                  <button className="w-5 h-5" onClick={() => handleEdit(cultivation)}>
                    <img src={edit} alt="Edit" />
                  </button>
                  <button className="w-5 h-5" onClick={() => handleSelectingDelete(cultivation)}>
                    <img src={deleteimg} alt="Delete" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>

      <Alert
        message={alertProps.message}
        severity={alertProps.severity}
        show={alertProps.show}
        onClose={hideAlert}
      />

      {showDeleteModel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4">
            <h1 className="text-sm text-center">
              Are you sure you want to delete the Cultivation for <span className="font-semibold text-md">{deleteCultivation.season}</span>?
            </h1>
            <div className="flex flex-row justify-between gap-12 text-white text-sm font-semibold">
              <button className="bg-agro_greenlight py-2 rounded-md px-4" onClick={handleDeleteCultivation}>
                Delete
              </button>
              <button className="bg-red-500 py-2 rounded-md px-4" onClick={() => setShowDeleteModel(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showHarvestForm && selectedCultivationForHarvest && (
        <HarvestForm
          cultivate={selectedCultivationForHarvest}
          closeForm={() => setShowHarvestForm(false)}
          refreshCultivations={() => setCultivationDetails([...cultivationDetails])}  // Optionally, you can refresh the cultivations after adding a harvest.
        />
      )}

    </div>
  );
}

export default CultivationDetails;
