import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CustomerDetailsperorder from '../OrderIdDetails/CustomerDetailsperorder';
import ItemsOrderedPerOder from '../OrderIdDetails/ItemsOrderedPerOder';
import PlacedOrderDetails from './PlacedOrderDetails';
import CustomAxios from '../../../../API/CustomAxios';
import { API_BASE_SMARTSCAP } from '../../../../API/APIUrls';
import { useSelector } from 'react-redux';

function CustomerPastOrderDetails() {
    const { orderID } = useParams();
    const location = useLocation();
    const { orderItemId } = location.state || {};
    const [orderDetails, setOrderDetails] = useState(null);
    const [filteredOrderItem, setFilteredOrderItem] = useState(null);
    const [billingAddress, setBillingAddress] = useState();
    const userId = useSelector((state) => state.auth.userID);


    useEffect(() => {
        getOrderDetailsByID();
    }, []);


    const getOrderDetailsByID = async () => {
        try {
            const response = await CustomAxios({
                method: 'GET',
                baseURL: API_BASE_SMARTSCAP,
                url: 'getOrdersBySupplierByOrderID',
                params: {
                    orderId: orderID,
                    supplierId: userId,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const orderData = response.data.orders[0];
                setOrderDetails(orderData);
                console.log("orderData",orderData)

                // Filter the specific OrderItem by orderItemId
                const matchedOrderItem = orderData.order_items.find(
                    (item) => item.orderItemId === orderItemId
                );
                console.log("maa", matchedOrderItem)
                setFilteredOrderItem(matchedOrderItem);
            
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    
    return (
        <div className="flex flex-col mt-2 gap-4 h-auto overflow-y-auto">
            <h1 className="text-2xl font-semibold text-gray-500 lg:ml-0">
                Order ID: <span className="text-agro_darkgreen font-bold text-md ml-2">{filteredOrderItem?.orderItemId}</span>
            </h1>

            {/* Order Details */}
            <div className="w-full rounded-md p-4 flex flex-col gap-6 bg-white shadow-md">
                <h1>Order Id: {orderDetails?.orderid}</h1>
                <div className="flex flex-row text-[10px] text-black/50 font-semibold gap-8">
                    <div className="flex flex-row px-2 py-2 gap-3 bg-agro_lightgreen/70 rounded-lg">
                        <h3>Placed On:</h3>
                        <span>{orderDetails?.order_startdate}</span>
                    </div>
                    <div className="flex flex-row px-2 py-2 gap-3 bg-agro_lightgreen/70 rounded-lg">
                        <h3>Ends On:</h3>
                        <span>{orderDetails?.order_enddate}</span>
                    </div>
                </div>
            </div>

            {/* Additional Details */}
            <div className="grid lg:grid-cols-3 gap-2 md:grid-cols-2 mob2:grid-cols-1 w-auto">
                <CustomerDetailsperorder farmerID={orderDetails?.farmerid} newBillingAdress={setBillingAddress} />
                <PlacedOrderDetails  orderAmount={filteredOrderItem?.supplierTotalPrice} status={filteredOrderItem?.orderStatus}/>

                {/* Billing Address */}
                <div className="bg-white shadow-md rounded-md p-4 flex flex-col gap-4 lg:col-span-1 md:col-span-2">
                    <h1>BILLING ADDRESS</h1>
                    <p className="text-black/50 text-[12px]">{billingAddress}</p>
                </div>
            </div>

            {/* Items Ordered */}
                <ItemsOrderedPerOder orderItems={filteredOrderItem} orderID={orderID} onStatusChange={getOrderDetailsByID}/>
        </div>
    );
}

export default CustomerPastOrderDetails;
