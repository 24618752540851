import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import PasswordChange from './PasswordChange';
import ProfileDetails from './ProfileDetails';
import CustomAxios from '../../API/CustomAxios';


function Profile() {
  const [verifiedStatus, setVerifiedStatus] = useState(useSelector((state) =>state.availability.showBlur))
  return (
      <div className={`${verifiedStatus ? "" : "pointer pointer-events-none"} h-full flex flex-row w-full justify-center min-h-[85vh] lg:h-auto lg:mt-8 xl:mt-0 md:mt-0 mob2:mt-4 items-center`}>
        <div className="grid xl:grid-cols-3 lg:grid-cols-1 md:grid-cols-2 mob2:grid-cols-1 sm:gap-3 mob:gap-2 mob2:gap-4 h-full sm:p-3 mob:p-2 mob2:p-0 justify-center rounded-md shadow-lg mob:bg-agro_darkgreen lg:w-[70vw]  mob2:w-full">
          {/* PasswordChange Component */}
          <div className="col-span-1 flex w-full h-full">
            <PasswordChange />
          </div>
          {/* ProfileDetails Component */}
          <div className="xl:col-span-2 lg:col-span-1 md:col-span-1 w-full h-full">
            <ProfileDetails />
          </div>
        </div>
      </div>
  );
}

export default Profile;
