import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Alert from '../../Components/Alert';
import { FaCamera } from 'react-icons/fa';
import edit from "../../Assests/newEdit.png";
import showpw from '../../Assests/showpw.png';
import hidepw from '../../Assests/hidepw.png';
import CustomAxios from '../../API/CustomAxios';
import * as Yup from 'yup';
import { setUpdatingEmail, setUserImg } from '../ReduxManagement/authSlice';
import PhoneInput from 'react-phone-input-2';
import { setHideBlur, setShowBlur } from '../ReduxManagement/availabilitySlice';
import EmailVerification from '../../Components/EmailVerification';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import GridLoader from 'react-spinners/GridLoader';
import { setChangingEmail } from '../ReduxManagement/authSlice';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';


function NewProfile() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [model, setModel] = useState(false);
    const updatingEmail = useSelector((state) => state.auth.updatingEmail);
    const username= useSelector((state) => state.signup.username);  // Access the username from the Redux store
    const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));//access the uer role form redux
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
    const token = useSelector((state) => state.auth.ACCESSTOKEN);
    const [editPassword, setEditPassword] = useState(false);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const [confirmtype, setConfirmtype] = useState("password");
    const[confirmImg,setConfirmImg] = useState(hidepw);
    const [type, setType] = useState("password");
    const[img,setImg] = useState(hidepw);
    const [curtype, setCurtype] = useState("password");
    const[curImg,setCurImg] = useState(hidepw);
    const [showEmailVerifyModel, setShowEmailVerifyModel] = useState(false);
    const [verifiedStatus, setVerifiedStatus] = useState(useSelector((state) =>state.availability.showBlur))

    
// useEffect(()=>{
//       if(location.state?.emailVerified){
//         UpdateEmail();
//       }
//     },[location.state])
    // useState(() => {
    //   dispatch(setShowBlur(true));
    //   return () => {
    //     // Reset blur effect when the component unmounts if needed
    //     dispatch(setShowBlur(false));
    //   };
    // }, [setShowBlur]);

    //handle password toggle
    const handleConfirmPwToggle = () => {
      if (confirmtype === "password") {
          setConfirmImg(showpw); 
          setConfirmtype("text"); 
      } else {
          setConfirmImg(hidepw); 
          setConfirmtype("password");
      }
    };


    //handle current password toggle
    const handleCurPwToggle = () => {
      if (curtype === "password") {
          setCurImg(showpw); 
          setCurtype("text"); 
      } else {
          setCurImg(hidepw); 
          setCurtype("password");
      }
    };

    const handlePwToggle = () => {
      if (type === "password") {
          setImg(showpw); 
          setType("text"); 
      } else {
        setImg(hidepw); 
        setType("password");
      }
    };


    //hide alerts
    const hideAlert = () => {
      setAlertProps({ ...alertProps, show: false });
    };
    
  
    const [formData, setFormData] = useState({
        profilePhoto: null,
        username: '',
        name: '',
        address: '',
        contactno: '',
        country:"",
        nic: '',
        email: '',
        brandName:"",
      });

      const validate = Yup.object({
        username: Yup.string().required('*Required'),
        name: Yup.string().required('*Required'),
        address: Yup.string().required('*Required'),
        contactno: Yup.string().required('*Required'),
        country: Yup.string().required('*Required'),
        nic: Yup.string().required('*Required'),
        email: Yup.string().required('*Required').email('*Invalid email'),
      });


      const passwordValidate = Yup.object({
        currentPassword:Yup.string()
        .required("*Required"),
        newPassword:Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
        .required("*Password is Required"),
        confirmpassword:Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'), 
      })


      useEffect(()=>{
        getPhoto();
      },[])


      //get the profile photo
      const getPhoto = async()=>{
        try{
          const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/profileimage/${userId}`,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
            responseType: 'arraybuffer'
          })

          if (response.status === 200) {
            // Convert the binary data to a Blob object
            const imageBlob = new Blob([response.data], { type: 'image/png' }); 
            // Create an object URL from the Blob
            const imageUrl = URL.createObjectURL(imageBlob);
            dispatch(setUserImg(imageUrl));
            // Update the state with the image URL
            setFormData((prevData) => ({
              ...prevData,
              profilePhoto: imageUrl,
            }));
          }
        }catch(error){
          console.log(error);
        }
      }
      

      //get the user profile data 
      useEffect(() => {
        userRole === "supplier" ? fetchSupplierProfileData() : fetchProfileData();
      }, [username]); // Refetch when the username changes

    
      // Handle profile update
      const handleprofileUpdate =(values) => {
        console.log("hii",values)
       userRole === "supplier" ? handleSupplierUpdate(values) : userRole === "farmer" ? handleFarmerUpdate(values) : handleAdminUpdate(values);
      };

      //handle the password update
      const handlePasswordUpdate =(values)=>{
        userRole === "admin"? handleUserPasswordUpdate(values) :  handleSupplierPasswordUpdate(values);
      }


      const handleUserPasswordUpdate = async(values)=>{
        const {confirmpassword, ...passwordvalues} = values;
        console.log(values)
        try{
          const response = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/resetpassword/${userId}`,
            data:passwordvalues,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
          })
          if(response.status === 200){
            setAlertProps({severity: "success",message: response.data.message,show: true,});
          }else{
            setAlertProps({severity: "error",message: response.response.data.error,show: true,});
          }
        }catch(error){
          // console.log(error);
          const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
          setAlertProps({ severity: "error", message: errorMessage, show: true });
        }
      }


      const handleSupplierPasswordUpdate = async(values)=>{
        const {confirmpassword, ...passwordvalues} = values;
        try{
          const response = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/resetpassword/${userId}`,
            data:passwordvalues,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
          })
          if(response.status === 200){
            setAlertProps({severity: "success",message: response.data.message,show: true,});
          }else{
            setAlertProps({severity: "error",message: response.response.data.error,show: true,});
          }
        }catch(error){
          // console.log(error);
          const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
          setAlertProps({ severity: "error", message: errorMessage, show: true });
        }
      }


    //fetch profile details of farmer
    const fetchProfileData = async () => {
      try {
        const response = await CustomAxios({
          method: "GET",
          baseURL: API_BASE_NORMALSCAP,
          url: `user/${userId}`,
          headers: {
            "Content-Type": "application/json",
            "token": token,
          },
        })
    
        if(response.status === 200){

        const data = response.data.user;
          setFormData({
            username: data.username,
            name:data.name,
            address: data.address,
            contactno: data.contactno,
            country: data.country,
            nic: data.uid,
            email: data.email,
          })

        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };


        //fetch supplier profile details
    const fetchSupplierProfileData = async()=>{
            try{
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_NORMALSCAP,
                url: `supplier/${userId}`,
                headers: {
                "Content-Type": "application/json",
                "token": token,
                },
            })
            if(response.status === 200){
                const data = response.data.supplier
                setFormData({
                username: data.username,
                name:data.name,
                address: data.address,
                contactno: data.contactno,
                country:data.country,
                nic: data.uid,
                email: data.email,
                brandName:data.brandName,
                })
                console.log("supplier data",formData);
            }
            }catch(error){
            console.log(error);
            }
        }


        const handleSupplierUpdate = async (values) => {
          console.log("UPDATING VALUES", values);
          const { email, ...otherValues } = values;
        
          try {
            // Check if the email has changed
            const isEmailChanged = email !== formData.email;

            // Prepare data for the profile update
            const dataToSend = isEmailChanged ? otherValues : values; // Exclude email if it changed
        
            // Update profile
            const profileUpdateResponse = await CustomAxios({
              method: "PUT",
              baseURL: API_BASE_NORMALSCAP,
              url: `supplier/${userId}`,
              data: otherValues,
              headers: {
                "Content-Type": "application/json",
                "token": token,
              },
            });
        
            if (profileUpdateResponse.status === 200) {
              console.log("Profile updated successfully");
              // fetchSupplierProfileData();
        

              if(isEmailChanged){
                setModel(true)
                localStorage.setItem("updatedEmail", email);
                handleEmailVerification(email);
              }

              if(isEmailChanged === false){  
              setAlertProps({severity: "success",message: "Profile updated successfully.",show: true,});
              fetchSupplierProfileData();
              }
              // If email changed, send verification email
              // if (isEmailChanged) {
              //   localStorage.setItem("updatedEmail", email);
              //   const isEmailVerified = await handleEmailVerification();
              //   if(isEmailVerified){
              //     setShowEmailVerifyModel(true);
              //   }
              //   if (!isEmailVerified) {
              //     setAlertProps({
              //       severity: "error",
              //       message: "Failed to send verification email.",
              //       show: true,
              //     });
              //     return;
              //   }
              // }
        
            }
          } catch (error) {
              console.error("Profile update failed:", error);
              setAlertProps({severity: "error",message: "Error occurred while updating profile.",show: true,});
          }
        };
        

        //handle profile update of supplier
        // const handleSupplierUpdate =async(values)=>{
        //   console.log("UPDATING VALUES", values);
        //   const {email, ...otherValues} = values;

        //   if(email !== formData.email){
        //     const isEmailVerified = await handleEmailVerification(true,email);
        //     if(!isEmailVerified){
        //       setAlertProps({ severity: "error", message: "Email is already in use.", show: true });
        //       return;
        //     }
        //     UpdateEmail(email);
        //   }

        //   const { profilePhoto, nic, ...newValues } = values;
        //     try{
        //     const response = await CustomAxios({
        //         method: "PUT",
        //         baseURL: API_BASE_NORMALSCAP,
        //         url: `supplier/${userId}`,
        //         data: newValues,
        //         headers: {
        //         "Content-Type": "application/json",
        //         "token": token
        //         },
        //     })

        //     if(response.status === 200){
        //         console.log("updated supplier details",response.data);
        //         fetchSupplierProfileData();
        //     }

        //     }catch(error){
        //     console.log(error);
        //     }
        // }


        //handle profile update for farmer
        const handleFarmerUpdate = async(values)=>{
          const { profilePhoto, nic, ...newValues } = values;
          const { email, ...otherValues } = newValues;
          console.log("gg",nic);
          try{
             // Check if the email has changed
             const isEmailChanged = email !== formData.email;

             // Prepare data for the profile update
             const dataToSend = isEmailChanged ? otherValues : values;

            const response = await CustomAxios({
              method: "PUT",
              baseURL: API_BASE_NORMALSCAP,
              url: `user/${userId}`,
              data: otherValues,
              headers: {
              "Content-Type": "application/json",
              "token": token
              }
            })

            if(response.status === 200){
                // console.log("updated supplier details",response.data);

                if(isEmailChanged){
                  setModel(true)
                  localStorage.setItem("updatedEmail", email);
                  handleEmailVerification(email);
                }
  
                if(isEmailChanged === false){  
                setAlertProps({severity: "success",message: "Profile updated successfully.",show: true,});
                fetchProfileData();
                }
                
            }

          }catch(error){
            console.log(error);
            setAlertProps({severity: "error",message: "Error occurred while updating profile.",show: true,});
          }
        }
        

        // handle profile update for admin
        const handleAdminUpdate = async(values)=>{
            const { email,nic,profilePhoto, ...otherValues } = values;
            console.log("admin other", otherValues);

            try{
              const isEmailChanged = email !== formData.email;
              const dataToSend = isEmailChanged ? otherValues : values;
 
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_NORMALSCAP,
                url: `admin/${userId}`,
                data:otherValues,
                headers: {
                "Content-Type": "application/json",
                "token": token,
                },
            })

            if(response.status === 200){
              // console.log("updated supplier details",response.data);

              if(isEmailChanged){
                setModel(true)
                handleEmailVerification(email);
              }

              if(isEmailChanged === false){  
                setAlertProps({severity: "success",message: "Profile updated successfully.",show: true,});
                fetchProfileData();
              }
              
          }
            }catch(error){
              setAlertProps({severity: "error",message: "Error occurred while updating profile.",show: true,});
              console.log(error);
            }
        }


      const handlePhotoUpload = (e) => {
        const file = e.target.files[0];
        if(file){
          const photoUrl = URL.createObjectURL(file);
          setFormData((prev) => ({
            ...prev,
            profilePhoto: photoUrl
          }));
        }
        handleSubmitPhoto(file);
        // setFormData((prev) => ({
        //   ...prev,
        //   profilePhoto: URL.createObjectURL(e.target.files[0]),
        // }));
      };
    

      const handleSubmitPhoto = async(file) =>{
        console.log(file);
          const formData = new FormData();
          formData.append("profile_image", file);
          formData.append("uid",userId )
        try{
          const response = await CustomAxios({
            method: "POST",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/profileimage/${userId}`,
            data:formData,
            headers: {
            "Content-Type": "multipart/form-data",
            token: token,
            },
          })

          if(response.status === 200){
            console.log("image", response.data);
          }
        }catch(error){
          console.log(error);
        }
      }

    //handle EmailVerification
    const handleEmailVerificationAfterSignup = async()=>{
      try{
        const response =await CustomAxios({
          method:"PUT",
          baseURL:API_BASE_NORMALSCAP,
          url:"user/sendverifyemail",
          data:{
            role:userRole,
            recipient_name: formData.name,
            username: username,
            email: formData.email,
            state:"signup"
          } ,
          headers:{
            "Content-Type":"application/json",
          },
        })
        if(response.status === 200){
            setShowEmailVerifyModel(true)
        }else{
          setAlertProps({severity: "error",message: "Error Occured in Sending Verification Email",show: true,});
        }
      }catch(error){
        setAlertProps({severity: "error",message: "Error Occured in Sending Verification Email",show: true,});
        console.log(error);
      }
    }


    const handleEmailVerification = async (email) => {
      // const updatingEmail = useSelector((state) => state.auth.updatingEmail);

      try {
        const response = await CustomAxios({
          method: "PUT",
          baseURL: API_BASE_NORMALSCAP,
          url: "user/sendverifyemail",
          data: {
            role: userRole,
            recipient_name: formData.name,
            username: username,
            email: email,
            state:"profileupdate"
          },
          headers: {
            "Content-Type": "application/json",
            "token":token
          },
        });

        if (response.status === 200) {
          setModel(false);
          setShowEmailVerifyModel(true);
        }
      } catch (error) {
        console.error("Email verification failed:", error);
        
      }
    };
 
    

return (
<>
    {model && (
        <div className="fixed inset-0 flex items-center justify-center z-30 bg-gray-400 bg-opacity-70 ">
            <GridLoader color="#0c3e26" size={22} height={150} margin={5} speedMultiplier={1} width={30} className="z-50" />
          {/* <div className="absolute inset-0 bg-zinc-200 opacity-70"></div> */}
        </div>
    )}

    <div className={` ${verifiedStatus ? "" : "pointer pointer-events-none"} mob2:px-0  sm:px-8 flex flex-row justify-center items-center min-h-[85vh] w-full lg:mt-6 mob2:mt-6 xl:mt-0`}>
        <div className=' grid lg:grid-cols-1 md:grid-cols-3 mob2:grid-cols-1 xl:grid-cols-3 xl:gap-8 lg:gap-4 md:gap-4 mob2:gap-4  h-full w-full'>
            <div className='flex xl:flex-col sm:flex-row mob2:flex-col md:flex-col xl:col-span-1 md:col-span-1 sm:col-span-1 rounded-lg shadow-md xl:p-4 lg:p-6 md:p-4 sm:p-4 mob2:p-4 h-full bg-white items-center gap-8'>
                <div className='lg:h-48 lg:w-48  md:w-24 md:h-24 sm:w-72 sm:h-48  mob2:w-48 mob2:h-48 rounded-full bg-white flex justify-center relative border '>
                    <img src={formData.profilePhoto} className="h-full w-full object-cover rounded-full" />
                    <label className="absolute -bottom-4 bg-agro_greenlight p-2 rounded-full text-white cursor-pointer">
                        <FaCamera className="text-lg" />
                        <input type="file" className="hidden" onChange={handlePhotoUpload} />
                    </label>
                </div>

            <div className='flex flex-col justify-end items-start w-full'>
                <div className='flex flex-col justify-center items-center w-full mt-2 relative'>
                    <h3 className='text-start mb-4 md:text-sm font-semibold tracking-wider sm:text-lg'>Password Change</h3>
                    <button onClick={()=>{setEditPassword(!editPassword)}}><img src={edit} className='w-3 h-3 absolute top-1 lg:right-8 mob2:right-0'/></button>
                    <Formik
                      initialValues={{
                        currentPassword:"",
                        newPassword:"",
                        confirmpassword:"",
                      }}
                      validationSchema={passwordValidate}
                      onSubmit={handlePasswordUpdate}
                    >
                      {(formik) =>(
                        <Form className='flex flex-col w-full xl:px-12 lg:px-4 gap-2 items-center'>
                          <div className='flex flex-col w-full h-12 relative'>
                            <Field
                                type={curtype}
                                id="currentPassword"
                                name="currentPassword"
                                placeholder="Current Password"
                                className="p-3 text-[12px] w-full text-black/60 rounded-md bg-agro_lightgreen border-none border-gray-400 focus:outline-none"
                              /> 
                              <button onClick={(e) =>{e.preventDefault(); handleCurPwToggle();}}>
                                  <img src={curImg} className='absolute w-[20px] h-[20px] bottom-4 right-4'/>
                              </button> 
                            <ErrorMessage 
                              name="currentPassword" 
                              component="div" 
                              className="text-red-500 text-[9px] font-medium italic " 
                            />                           
                          </div>
  
                          <div className='flex flex-col h-12 w-full relative'>
                            <Field
                              type={type}
                              id="password"
                              name="newPassword"
                              placeholder="newPassword"
                              className="p-3 text-[12px] w-full text-black/60 rounded-md bg-agro_lightgreen border-none border border-gray-400 focus:outline-none"
                            />
                              <button onClick={(e) =>{e.preventDefault(); handlePwToggle();}}>
                                  <img src={img} className='absolute w-[20px] h-[20px] bottom-4 right-4'/>
                              </button>                            
                            <ErrorMessage 
                              name="newPassword" 
                              component="div" 
                              className="text-red-500 text-[9px] font-medium italic " 
                            />
                          </div>

                          <div className='flex flex-col h-12 w-full relative'>
                          <Field
                              type={confirmtype}
                              id="confirmpassword"
                              name="confirmpassword"
                              placeholder="Confirm Password"
                              className="p-3 text-[12px] w-full text-black/60 rounded-lg bg-agro_lightgreen border-none border border-gray-400 focus:outline-none"
                            />
                              <button onClick={(e) =>{e.preventDefault(); handleConfirmPwToggle();}}>
                                  <img src={confirmImg} className='absolute w-[20px] h-[20px] bottom-4 right-4'/>
                              </button>                           
                          <ErrorMessage 
                              name="confirmpassword" 
                              component="div" 
                              className="text-red-500 text-[9px] font-medium italic " 
                            />
                          </div>
                            <button type="submit" className={`${editPassword ? "bg-agro_greenlight" :"hidden "}  w-48 mt-4 text-sm font-semibold text-white rounded-md p-2`}>Update</button>
                        </Form>
                      )}
                    </Formik>
                </div>
            </div>
            </div>


      <div className='flex flex-col xl:col-span-2 lg:col-span-1 md:col-span-2 h-full'>
            <Formik
                initialValues={formData}
                validationSchema={validate}
                onSubmit={handleprofileUpdate}
                enableReinitialize // This allows Formik to update with the fetched data
            >
          {(formik) => (
            <Form className="w-full flex flex-col py-8 px-12 fitems-center justify-center rounded-lg h-full bg-white shadow-md ">
              {/* Username Field */}
              <div className="flex flex-col w-full text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">User name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="username"
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

              {/* Name Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


            {/* brand name Field */}
              <div className={` ${userRole === "supplier" ? "" :"hidden"} flex flex-col text-[14px] h-18 mb-4`}>
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32  font-semibold mob:justify-start mob:flex">Brand Name</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="brandName"
                      name="brandName"
                      value={formik.values.brandName}
                      onChange={formik.handleChange}
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="brandName"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


              {/* Address Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Address</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

             {/* Phone Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Contact No</label>
                  <div className="w-full flex flex-col">
                    <PhoneInput
                      country={formData.country || "us"} // Default or fetched country code
                      value={formik.values.contactno}
                      enableSearch={true}
                      onChange={(phone, countryData) => {
                        formik.setFieldValue("contactno", phone); // Update contact number
                        formik.setFieldValue("country", countryData.name); // Update country name
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "23px",
                        paddingLeft: "80px", // Ensure enough space for flag and country code
                        borderRadius: "0.375rem",
                        border: "1px solid #d1d5db",
                        fontSize: "12px",
                        color: "#00000",
                      }}
                      buttonStyle={{
                        border: "none",
                        background: "transparent",
                      }}
                      containerClass="phone-input-container"
                      inputClass="phone-input"
                    />
                    <ErrorMessage
                      name="contactno"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>


              {/* NIC Field */}
              {/* <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">UID</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="text"
                      id="nic"
                      name="nic"
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="nic"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div> */}

              {/* Email Field */}
              <div className="flex flex-col text-[14px] h-18 mb-4">
                <div className="flex sm:flex-row mob2:flex-col sm:gap-12 sm:items-center mob2:items-start mob2:gap-1">
                  <label className="w-32 sm:text-right font-semibold mob:justify-start mob:flex">Email</label>
                  <div className="w-full flex flex-col">
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="w-full p-2 text-[12px] text-black/60 rounded-lg bg-transparent border border-gray-400 focus:outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-[9px] font-medium italic mt-1"
                    />
                  </div>
                </div>
              </div>

            <div className='flex items-center flex-row justify-center'>
                <button type="submit" className='bg-agro_greenlight p-2 text-white  w-64 flex items-center justify-center rounded-md '>Update</button>
            </div>
              
            <div className={` ${verifiedStatus ? "hidden" : " pointer-events-auto"} flex flex-col items-end justify-end mt-2 gap-2`}>
              <button className='bg-agro_darkgreen text-xs text-white p-2 rounded-md' onClick={(e)=>{e.preventDefault(); handleEmailVerificationAfterSignup()}}> Verify Email</button>
              <span className='text-xs font-bold text-red-500 animate animate-pulse'>Email is not Verified Please Verifiy Your email</span>
            </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>

        {/* when the email verify model is true */}
        {showEmailVerifyModel && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/50 z-50 pointer-events-auto">
           <EmailVerification onClose={() => {navigate("/"); setShowEmailVerifyModel(false);}}/>
         </div>
        )}

        {/* Calling the Alert component */}
        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
    </div>
  </>
  )
}

export default NewProfile