import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Alert from '../../../Components/Alert';
import * as Yup from 'yup';
import CustomAxios from '../../../API/CustomAxios';
import Seeds from '../../../Assests/FarmerDashboard/seeds.jpg';
import Model from '../../../Components/Model';
import {API_BASE_SMARTAJAP} from '../../../API/APIUrls';

function EditProductItem({ productDetails, onClose, itemDetails,showUpdatedItems }) {
    const [model, setModel] = useState(false);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const [previewImage, setPreviewImage] = useState(itemDetails.productImage ? `data:image/png;base64,${itemDetails.productImage}` : Seeds); 

    // Validation schema
    const validate = Yup.object({
        productPrice: Yup.string().required("*Required"),
        productStock: Yup.string().required("*Required"),
        productVolume: Yup.string().required("*Required"),
        productImage: Yup.mixed().nullable(),
    });

    // Hide alert
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };

    // Handle product update
    const handleProductUpdate = async (values) => {
        setModel(true);
        console.log(values);
        const formData = new FormData();
  
        const jsonData = {
            productID: productDetails.productID,
            productDetailsID: itemDetails.productDetailsID,
            updatedDetail: {
                productVolume:values.productVolume,
                productPrice: parseFloat(values.productPrice),
                productStock:values.productStock,
            },
        }

        formData.append("jsonData", JSON.stringify(jsonData));
        // formData.append("productImage", values.productImage); 
        
        if (values.productImage instanceof File) {
            formData.append("productImage", values.productImage);
        }

        // values.updatedDetail.forEach((detail) => {
        //     if (detail.productImage) {
        //         formData.append("productImage", detail.productImage); // Append image file directly
        //     }
        // });
        console.log("updating al",values)
        try {
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_SMARTAJAP,
                url: `UpdateProductDetail`,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                setModel(false);
                setAlertProps({ severity: "success", message: "Product Item Updated Successfully!", show: true });
                showUpdatedItems();
                onClose();
            }
        } catch (error) {
            setModel(false);
            console.error(error)
            setAlertProps({ severity: "error", message: "Error updating product!", show: true });
            onClose();
        }
    };

    // Handle image change
    const handleImageChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setFieldValue("productImage", file);
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <Model showModel={model}/>
            <div className="bg-white p-6 sm:rounded-lg shadow-lg max-w-[550px] flex flex-col gap-4 mob2:w-full overflow-y-auto no-scrollbar">
                <h1 className="text-agro_darkgreen font-semibold tracking-wide text-center sm:text-lg mob2:my-6 sm:my-0 mob2:text-2xl">
                    Updating {productDetails.productName}
                </h1>
                <Formik
                    initialValues={{
                        productPrice: itemDetails.productPrice,
                        productStock: itemDetails.productStock,
                        productVolume: itemDetails.productVolume,
                        productImage: itemDetails.productImage ? `data:image/png;base64,${itemDetails.productImage}` : Seeds, // Make sure you have a fallback
                    }}
                    validationSchema={validate}
                    onSubmit={handleProductUpdate}
                >
                    {(formik) => (
                        <Form className="flex flex-col sm:gap-5 mob2:gap-8">
                            {/* Current Image Preview */}
    
                                <div className="flex flex-col items-center">
                                    <img src={previewImage} alt="Preview" className="w-32 h-32 object-cover rounded-lg" />
                                </div>
                            

                            {/* Image Upload */}
                            <div className="flex w-full items-center">
                                <label className="text-[13px] text-black w-1/4">Image</label>
                                <div className="relative w-3/4">
                                    <input
                                        type="file"
                                        id="productImage"
                                        name="productImage"
                                        accept="image/*"
                                        onChange={(event) => handleImageChange(event, formik.setFieldValue)}
                                        className="w-full border focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                                    />
                                    <ErrorMessage
                                        name="productImage"
                                        component="div"
                                        className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                                    />
                                </div>
                            </div>

                            {/* Field for product volume */}
                            <div className="flex w-full items-center">
                                <label className="text-[13px] text-black w-1/4">Volume</label>
                                <div className="relative w-3/4">
                                    <Field
                                        type="text"
                                        id="productVolume"
                                        name="productVolume"
                                        className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                                    />
                                    <ErrorMessage
                                        name="productVolume"
                                        component="div"
                                        className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                                    />
                                </div>
                            </div>

                            {/* Field for product price */}
                            <div className="flex w-full items-center">
                                <label className="text-[13px] text-black w-1/4">Price</label>
                                <div className="relative w-3/4">
                                    <Field
                                        type="text"
                                        id="productPrice"
                                        name="productPrice"
                                        className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                                    />
                                    <ErrorMessage
                                        name="productPrice"
                                        component="div"
                                        className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                                    />
                                </div>
                            </div>

                            {/* Field for product stock */}
                            <div className="flex w-full items-center">
                                <label className="text-[13px] text-black w-1/4">Stock</label>
                                <div className="relative w-3/4">
                                    <Field
                                        type="text"
                                        id="productStock"
                                        name="productStock"
                                        className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                                    />
                                    <ErrorMessage
                                        name="productStock"
                                        component="div"
                                        className="absolute left-0 top-full text-red-500 text-[9px] font-medium italic mt-1"
                                    />
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-row w-full justify-evenly text-sm text-white font-semibold gap-12">
                                <button
                                    className="sm:py-2 px-8 w-full bg-agro_greenlight hover:bg-agro_greenlight/80 rounded-md mob2:py-3"
                                    type="submit"
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    className="sm:py-2 px-8 w-full bg-red-500 hover:bg-red-400 rounded-md mob2:py-3"
                                    onClick={onClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            {/* Calling the Alert component */}
            <Alert
                message={alertProps.message}
                severity={alertProps.severity}
                show={alertProps.show}
                onClose={hideAlert}
            />
        </div>
    );
}

export default EditProductItem;
