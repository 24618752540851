import React from 'react';
import pageNotFound from './404 Page Not Found.json';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assests/sidebarlogo.png'


function PageNotFound() {
    const navigate = useNavigate();
  return (
    <div className="relative w-full h-screen flex justify-center items-center bg-agro_darkgreen">
      <Lottie 
        animationData={pageNotFound} 
        loop={true} 
        // style={{ width: "100%", height: "100%" }}
      />

      <div className='absolute top-12  text-agro_darkgreen text-md font-bold'>
        <button onClick={()=>navigate("/")} className='bg-agro_gold p-3 px-12 rounded-md'>Go to Login</button>
        {/* <img src={logo} className='w-full h-20'/> */}
      </div>
    </div>
  );
}

export default PageNotFound;
