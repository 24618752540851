import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from "../../Components/Header";
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomAxios from '../../API/CustomAxios';
import { setShowBlur } from '../ReduxManagement/availabilitySlice';
import MessageAlert from '../../Components/MessageAlert';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';


function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showsidebar, setShowSidebar] = useState(false);
  const userRole = useSelector((state) => state.auth.userRole); // Fetch directly from Redux
  const userId = useSelector((state) => state.auth.userID);
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  const verifiedStatus = useSelector((state) => state.availability.showBlur); // Directly fetch from Redux
  const [user, setUser] = useState("");


// console.log("token", token)
// console.log("refresh", refresh)
  // Update `user` based on `userRole`
  useEffect(() => {
    if (userRole === "farmer") {
      setUser("user");
    } else if (userRole === "supplier") {
      setUser("user");
    }else if(userRole === "admin"){
      setUser("user")
    }
  }, [userRole]);


  // Call the API when `user` is updated
  useEffect(() => {
    if (user) {
      getEmailVerifyStatus();
    }
  }, [user, verifiedStatus]);


  const getEmailVerifyStatus = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_NORMALSCAP,
        url: `user/emailverification/${userId}/${userRole}`,
        headers: {
          "Content-Type": "application/json",
           "token": token,
        },
      });
      if (response.status === 200) {
        const status = response.data["Email Verification"];
        dispatch(setShowBlur(status));
      } else {
        console.error("Failed to fetch email verification status.");
      }
    } catch (error) {
      console.error("Error fetching email verification status:", error);
    }
  };

  // Track whether it's the profile page
  const [path, setPath] = useState(false);

  useEffect(() => {
    setPath(location.pathname === "/dashboard/profile");
  }, [location.pathname]);


  // console.log("Path:", path);
  // console.log("Verified Status:", verifiedStatus);

  return (
    <div className="p-2 w-full h-screen bg-agro_greenlight/60 relative">
      <div className="flex flex-row bg-white w-full h-full rounded-lg relative">
        {/* Sidebar */}
        <div className={`sm:w-[240px] h-full fixed bg-white rounded-lg lg:relative lg:block ${showsidebar ? 'mob2:block mob2:absolute mob2:top-0 mob2:left-0 mob2:w-full z-50' : 'mob2:hidden z-50'}`}>
          <Sidebar setShowSidebar={setShowSidebar} />
        </div>

        <div className="w-full h-full flex-col bg-agro_greengray/20 rounded-lg overflow-y-auto no-scrollbar">
          <div className='fixed lg:w-[calc(100vw-255px)] lg:pr-0 mob2:w-full mob2:pr-4 z-20'>
            {/* Header and Main Content */}
            <Header showsidebar={showsidebar} setShowSidebar={setShowSidebar} />
          </div>

          
          {path === false && verifiedStatus === false && <MessageAlert />}

          {/* Outlet Section */}
          <div className={`px-4 transition-all duration-300 mt-24  ${
              path === false && verifiedStatus === false ? "blur-sm pointer-events-none" :""}`}>
              <Outlet />  
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
