import React from 'react';
import cover from '../../Assests/contactcoverimg.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function Contactus() {
    const validate = Yup.object({
        first_name: Yup.string().required("*Required"),
        last_name: Yup.string().required("*Required"),
        email: Yup.string().required("*Email is Required"),
        contact: Yup.string().required("*Required"),
        message: Yup.string().required("*Required"),
    });

    const handleContactDetails = () => {
        // Handle form submission logic here
    };

    return (
        <div className="flex justify-center items-center min-h-[80vh] ">
            <div className="grid grid-cols-2 max-w-[80vw] h-full border shadow-md rounded-md">
                {/* Left Image Section */}
                <div className="bg-black/70 rounded-l-md">
                    <img src={cover} alt="Contact Cover" className="object-cover h-full w-full" />
                </div>
                {/* Form Section */}
                <div className="py-8 px-12 flex flex-col bg-white justify-evenly">
                    <h1 className="text-2xl mb-6">Contact us</h1>
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            email: "",
                            contact: "",
                            message: "",
                        }}
                        validationSchema={validate}
                        onSubmit={handleContactDetails}
                    >
                        <Form className="flex flex-col gap-12">
                            <div className="flex flex-row w-full gap-8">
                                <div className="flex flex-col w-full tracking-widest h-22">
                                    <label>First Name</label>
                                    <Field
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        placeholder="Enter your first name"
                                        className="border-b w-full py-2 tracking-wide text-[12px] focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="first_name"
                                        component="div"
                                        className="text-red-500 text-[9px] font-bold italic mt-1"
                                    />
                                </div>
                                <div className="flex flex-col w-full tracking-widest h-22">
                                    <label>Last Name</label>
                                    <Field
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        placeholder="Enter your last name"
                                        className="border-b w-full py-2 tracking-wide text-[12px] focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="last_name"
                                        component="div"
                                        className="text-red-500 text-[9px] font-bold italic mt-1"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row w-full gap-8">
                                <div className="flex flex-col w-full tracking-widest h-22">
                                    <label>Email</label>
                                    <Field
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        className="border-b w-full py-2 tracking-wide text-[12px] focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="text-red-500 text-[9px] font-bold italic mt-1"
                                    />
                                </div>
                                <div className="flex flex-col w-full tracking-widest h-22">
                                    <label>Phone Number</label>
                                    <Field
                                        type="text"
                                        id="contact"
                                        name="contact"
                                        placeholder="Enter your phone number"
                                        className="border-b w-full py-2 tracking-wide text-[12px] focus:outline-none"
                                    />
                                    <ErrorMessage
                                        name="contact"
                                        component="div"
                                        className="text-red-500 text-[9px] font-bold italic mt-1"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full tracking-widest h-22">
                                <label>Message</label>
                                <Field
                                    as="textarea"
                                    id="message"
                                    name="message"
                                    placeholder="Enter your message"
                                    className="border-b w-full py-2 tracking-wide text-[12px] focus:outline-none"
                                />
                                <ErrorMessage
                                    name="message"
                                    component="div"
                                    className="text-red-500 text-[9px] font-bold italic mt-1"
                                />
                            </div>
                            <button className="text-center text-white text-sm rounded-md hover:bg-agro_darkgreen/80 bg-agro_darkgreen w-24 p-2">
                                Submit
                            </button>
                        </Form>
                    </Formik>
                    <div className="flex flex-col tracking-wide mt-4">
                        <p className="text-sm text-black/50">EMAIL US</p>
                        <span className="text-sm">useremail@gmail.com</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contactus;
