import React, { useEffect, useState } from 'react';
import CustomAxios from '../../../../API/CustomAxios';
import edit from '../../../../Assests/newEdit.png';
import Alert from '../../../../Components/Alert';
import { API_BASE_SMARTAJAP,API_BASE_SMARTSCAP } from '../../../../API/APIUrls';

function ItemsOrderedPerOder({ orderItems, orderID, onStatusChange }) {
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the row being edited
  const [selectedStatus, setSelectedStatus] = useState({}); // Track the selected status per item
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });

  useEffect(()=>{
  },[orderItems])

  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };

  // Handling the update of the ordered items
  const handleOrderEdit = async (item) => {
        // console.log("item", item)
    try {
      const orderupdatedResponse = await CustomAxios({
        method: "PUT",
        baseURL: API_BASE_SMARTSCAP,
        url: "updateOrderStatusBySupplier",
        params: {
          orderID: orderID,
          orderItemID: orderItems.orderItemId,
          itemDetailID: item.itemDetailId,
        },
        data: {
          ItemStatus: selectedStatus[item.itemDetailId] || item.ItemStatus,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (orderupdatedResponse.status === 200) {
        // console.log("Item updated:", orderupdatedResponse.data);
        setAlertProps({ severity: "success", message: "Order Updated Successfully!", show: true });
        updateStock(item);
        onStatusChange();
        setEditingIndex(null);
      }
    } catch (error) {
        setAlertProps({ severity: "error", message: "An Error Occured", show: true });
        // console.log(error);
    }
  };


//updating the stock
  const updateStock = async(item)=>{
  
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Output: '2024-11-22'
    console.log(orderItems.productId)
    console.log(item.productDetailsId)
    console.log(item.quantity)
    console.log(formattedDate)

    // const data=JSON.stringify({})
    try{
      const response = await CustomAxios({
        method: "POST",
        baseURL: API_BASE_SMARTAJAP,
        url: "UpdateStock",
        data: {
          productID:orderItems.productId,
          productDetailsID:item.productDetailsId,
          quantitySold:item.quantity,
          saleDate:formattedDate,
        },
        headers: {
          "Content-Type": "application/json",
        },
        transformRequest: [(data) => JSON.stringify(data)], 
      })

      if(response.status === 200){
        console.log("my ne stock", response.data);
      }
    }catch(error){
      console.log(error);
    }
  }

  
  // Handle the edit icon click
  const handleEdit = (index) => {
    setEditingIndex(editingIndex === index ? null : index);
  };

  // Handle dropdown selection change
  const handleStatusChange = (itemId, status) => {
    setSelectedStatus((prevStatus) => ({ ...prevStatus, [itemId]: status }));
  };

  return (
    <div className='bg-white shadow-md rounded-md p-4 flex flex-col'>
      <h1>ITEMS ORDERED</h1>

      <div className='flex flex-col rounded-lg mt-6'>
        <div className='flex flex-col'>
          <div className='overflow-x-auto rounded-lg'>
            <table className='min-w-full rounded-lg'>
              <thead className='bg-agro_lightgreen text-black/50 text-left'>
                <tr>
                  <th className='px-6 py-3 text-xs'>No</th>
                  <th className='px-6 py-3 text-xs'>ItemID</th>
                  <th className='px-6 py-3 text-xs'>Item Type</th>
                  <th className='px-6 py-3 text-xs'>Quantity</th>
                  <th className='px-6 py-3 text-xs'>Item Status</th>
                  <th className='px-6 py-3 text-xs'>Unit Price</th>
                  <th className='px-6 py-3 text-xs'>Action</th>
                </tr>
              </thead>

              <tbody>
                {orderItems?.itemDetails?.map((item, index) => (
                    <tr key={item.itemDetailId} className='text-xs font-normal border-b border-agro_lightgreen'>
                      <td className='px-6 py-3'>{index + 1}</td>
                      <td className='px-6 py-3'>{item.itemDetailId}</td>
                      <td className='px-6 py-3'>{item.itemType}</td>
                      <td className='px-6 py-3'>{item.quantity}</td>
                      <td className='px-6 py-3'>
                        <select
                          className={`bg-agro_lightgreen rounded-md px-2 p-1 ${editingIndex === index ? "" : "cursor-not-allowed opacity-50"}`}
                          disabled={editingIndex !== index}
                          value={selectedStatus[item.itemDetailId] || item.ItemStatus}
                          onChange={(e) => handleStatusChange(item.itemDetailId, e.target.value)}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Accepted">Accepted</option>
                        </select>
                      </td>
                      <td className='px-6 py-3'>{item.unitPrice}</td>
                      <td className='px-6 py-3 w-full flex flex-row gap-2'>
                        <button onClick={() => handleEdit(index)}>
                          <img src={edit} className='w-3 h-3' alt="Edit" />
                        </button>
                        {editingIndex === index && (
                          <button className='text-[12px] font-semibold' onClick={() => handleOrderEdit(item)}>Save</button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    {/* Calling the Alert component */}
    <Alert
        message={alertProps.message}
        severity={alertProps.severity}
        show={alertProps.show}
        onClose={hideAlert}
      />
    </div>
  );
}

export default ItemsOrderedPerOder;




