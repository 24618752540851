import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import edit from '../../Assests/edit.png';
import deleteimg from '../../Assests/delete.png';
import CustomAxios from '../../API/CustomAxios';
import { useSelector } from 'react-redux';
import Alert from '../../Components/Alert';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls'


function Seeds() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [seeds, setSeeds] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [updatedSeedNumber, setUpdatedSeedNumber] = useState('');
  const [updatedTimePeriod, setUpdatedTimePeriod] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSeedNumber, setSelectedSeedNumber] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });


  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
  

  const validate = Yup.object({
    seedNumber: Yup.string().required('Seed Number is required'),
    TimePeriod: Yup.number().required('Time period is required').positive().integer(),
  });


  useEffect(() => {
    getSeeds();
  }, []);

  const getSeeds = async () => {
    try {
      const response = await CustomAxios({
        method: 'GET',
        baseURL: API_BASE_NORMALSCAP,
        url: 'admin/seeds',
        headers: {
          'Content-Type': 'application/json',
          "token": token,
        },
      });
      if (response.status === 200) {
        setSeeds(response.data.seeds);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle adding new seed
  const handleAddSeed = async (values) => {
    try {
      const response = await CustomAxios({
        method: 'POST',
        baseURL: API_BASE_NORMALSCAP,
        url: 'admin/seeds',
        data: values,
        headers: {
          'Content-Type': 'application/json',
          "token": token,
        },
      });
      if (response.status === 201) {
        getSeeds(); 
      }else{
        setAlertProps({severity: "error",message: response.response.data.error,show: true,});
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      setAlertProps({ severity: "error", message: errorMessage, show: true });
    }
  };


  // Handle updating seed
  const handleSeedUpdate = async () => {
    try {
      const response = await CustomAxios({
        method: 'PUT',
        baseURL: API_BASE_NORMALSCAP,
        url: `admin/seeds/${updatedSeedNumber}`,
        data: { 
          // seedNumber: updatedSeedNumber,
          TimePeriod: parseFloat(updatedTimePeriod),
          isActivated: true,
        },
        headers: {
          'Content-Type': 'application/json',
          "token": token,
        },
      });

      if (response.status === 200) {
        getSeeds();
        setEditingIndex(null);
        setUpdatedSeedNumber('');
        setUpdatedTimePeriod('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle deleting seed
  const handleDeleteSeed = async () => {
    try {
      const response = await CustomAxios({
        method: 'PUT',
        baseURL: API_BASE_NORMALSCAP,
        url: `admin/seeds/${selectedSeedNumber}`,
        data: {
          isActivated: false,
        },
        headers: {
          'Content-Type': 'application/json',
          "token": token,
        },
      });

      if (response.status === 200) {
        setSeeds(seeds.filter((seed) => seed.seedNumber !== selectedSeedNumber));
        setDeleteModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when search term changes
  };

  const filteredSeeds = seeds.filter((seed) =>
    seed.seedNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedSeeds = filteredSeeds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <div className="w-auto flex md:flex-row mt-6 justify-between md:items-center md:gap-2 mob2:flex-col mob2:items-start mob2:gap-3">
        <Formik
          initialValues={{
            seedNumber: "",
            TimePeriod: "",
            isActivated: true,
          }}
          validationSchema={validate}
          onSubmit={handleAddSeed}
        >
          {(formik) => (
            <Form className="flex mob:flex-row mob2:flex-col gap-4 ">
              <div className='flex flex-row gap-4'>
                  <Field
                    type="text"
                    id="seedNumber"
                    name="seedNumber"
                    placeholder="Seed Number"
                    className="text-xs p-3 lg:w-48 md:w-40 mob2:w-full bg-white rounded-md focus:outline-none shadow-md"
                  />
                  <Field
                    type="number"
                    id="TimePeriod"
                    name="TimePeriod"
                    placeholder="Time period"
                    className="text-xs p-3 lg:w-48 md:w-40 mob2:w-full bg-white focus:outline-none rounded-md shadow-md"
                  />     
                </div>
              
                <button type="submit" className="bg-agro_darkgreen text-white text-xs sm:px-6 py-3 mob2:px-4 rounded-md">
                Add Seed
              </button>
            </Form>
          )}
        </Formik>

        <input 
          className="bg-white mob:w-64 mob2:w-full rounded-md text-xs p-3 focus:outline-none" 
          placeholder="Search by Seed Number..." 
          value={searchTerm} 
          onChange={handleSearchChange}
        />
      </div>

      <div className="flex flex-col rounded-lg mt-2">
        <TablePagination
          component="div"
          rowsPerPageOptions={[2, 10, 25, 50]}
          page={page}
          count={filteredSeeds.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        
        <div className="overflow-x-auto rounded-lg bg-agro_lightgreen/70">
          <table className="min-w-full rounded-lg text-left">
            <thead>
              <tr>
                <th className="px-6 py-3 text-xs">No</th>
                <th className="px-6 py-3 text-xs">Seed Number</th>
                <th className="px-6 py-3 text-xs">Time Period</th>
                <th className="px-6 py-3 text-xs">Update</th>
                <th className="px-6 py-3 text-xs">Delete</th>
              </tr>
            </thead>
            <tbody className="bg-white text-left">
              {paginatedSeeds.map((seed, index) => (
                <tr key={index} className="text-xs font-normal border border-b-agro_lightgreen/50">
                  <td className="px-6 py-3">{page * rowsPerPage + index + 1}</td>
                  <td className="px-6 py-3">{seed.seedNumber}
                    {/* <input
                      value={editingIndex === index ? updatedSeedNumber : seed.seedNumber}
                      onChange={(e) => setUpdatedSeedNumber(e.target.value)}
                      disabled={editingIndex !== index}
                      className={`${editingIndex === index ? "bg-agro_lightgreen" : "bg-transparent"} focus:outline-none p-2 rounded-md`}
                    /> */}
                  </td>
                  <td className="px-6 py-3">
                    <input
                      type="number"
                      value={editingIndex === index ? updatedTimePeriod : seed.timePeriod}
                      onChange={(e) => setUpdatedTimePeriod(e.target.value)}
                      disabled={editingIndex !== index}
                      className={`${editingIndex === index ? "bg-agro_lightgreen" : "bg-transparent"} focus:outline-none p-2 rounded-md`}
                    />
                  </td>
                  <td className="px-6 py-3">
                    {editingIndex === index ? (
                      <div className="flex flex-row gap-2 font-semibold">
                        <button onClick={handleSeedUpdate} className="hover:text-agro_lightgreen underline-offset-2 underline">
                          Save
                        </button>
                        <button onClick={() => { setEditingIndex(null); setUpdatedSeedNumber(''); setUpdatedTimePeriod(''); }} className="hover:text-agro_lightgreen underline-offset-2 underline">
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button onClick={() => { setEditingIndex(index); setUpdatedSeedNumber(seed.seedNumber); setUpdatedTimePeriod(seed.TimePeriod); }}>
                        <img src={edit} className="w-5 h-5" alt="Edit" />
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-3">
                    <button onClick={() => { setSelectedSeedNumber(seed.seedNumber); setDeleteModal(true); }}>
                      <img src={deleteimg} className="w-5 h-5" alt="Delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h1 className='text-center font-semibold mb-4'>Confirm Delete</h1>
            <h3 className="text-sm  mb-4">Are you sure you want to delete this seed? {selectedSeedNumber}</h3>
            <div className="flex gap-4 flex-row justify-end text-sm font-semibold ">
              <button
                onClick={handleDeleteSeed}
                className="bg-agro_greenlight text-white py-2 px-4 rounded-md"
              >
                Confirm
              </button>
              <button
                onClick={() => setDeleteModal(false)}
                className="bg-red-500 py-2 px-4 rounded-md text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Calling the Alert component */}
      <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
    </>
  );
}

export default Seeds;
