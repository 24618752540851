import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import CustomAxios from "../../../API/CustomAxios";
import Alert from "../../../Components/Alert";
import GridLoader from 'react-spinners/GridLoader';


function CreateProduct() {
    const storeAddress = useSelector((state) => state.auth.storeAddress);
    const userId = useSelector((state) => state.auth.userID);
    const brand = useSelector((state) => state.auth.brandname);
    const [model, setModel] = useState(false);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
    const [imagePreview, setImagePreview] = useState("");

    // Hide alert
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };

    const validate = Yup.object({
        productName: Yup.string().required("*Required"),
        productType: Yup.string().required("*Required"),
        productDetails: Yup.array().of(
            Yup.object().shape({
                productVolume: Yup.string().required("*Required"),
                productPrice: Yup.number().required("*Required"),
                productStock: Yup.number().required("*Required"),
                // productImage: Yup.string().url("Invalid URL").required("*Required"),
                productImage: Yup.mixed().required("*Required")
            })
        )
    });

    const handleProductCreation = async (values, { resetForm }) => {
        console.log("new", values);
        const formData = new FormData();
    
        const jsonData = {
            supplierID: userId,
            supplierAddress: storeAddress,
            brandName: brand,
            productName: values.productName,
            productType: values.productType,
            productDetails: values.productDetails.map((detail) => ({
                productVolume: detail.productVolume,
                productPrice: parseFloat(detail.productPrice),
                productStock: detail.productStock,
            })),
        }
    
        // Correct JSON.stringify usage
        formData.append("jsonData", JSON.stringify(jsonData));
    
        // Correct file appending for product images
        values.productDetails.forEach((detail) => {
            if (detail.productImage) {
                formData.append("productImage", detail.productImage); // Append image file directly
            }
        });
    
        try {
            setModel(true);
            const response = await CustomAxios({
                method: "POST",
                baseURL: API_BASE_SMARTAJAP,
                url: "createProduct",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    
            if (response.status === 201) {
                setAlertProps({ message: "Product created successfully!", severity: "success", show: true });   
                setTimeout(() => {
                    setModel(false);
                    resetForm();
                  }, 2000);                
            }
        } catch (error) {
            setModel(false)
            setAlertProps({ message: "Failed to create product.", severity: "error", show: true });
            console.error("Error creating product:", error);
        }
    };
    

        // Handle file change
        const handleFileChange = (e, index, setFieldValue) => {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Set the file object in Formik state
                    setFieldValue(`productDetails[${index}].productImage`, file);
                    // Set the preview URL in Formik state
                    setFieldValue(`productDetails[${index}].imagePreview`, reader.result);
                };
                reader.readAsDataURL(file);
            }
        };
        

    return (
        <div className="bg-white rounded-md shadow-lg flex flex-col p-4 mt-4">  
         {model && (
            <div className="fixed inset-0 flex items-center justify-center z-30 bg-gray-400 bg-opacity-70 ">
                <GridLoader color="#0c3e26" size={22} height={150} margin={5} speedMultiplier={1} width={30} className="z-50" />
              {/* <div className="absolute inset-0 bg-zinc-200 opacity-70"></div> */}
            </div>
          )}
            <Formik
                initialValues={{
                    productName: "",
                    productType: "",
                    productDetails: [
                        {
                            productVolume: "",
                            productPrice: "",
                            productStock: "",
                            productImage: ""
                        }
                    ]
                }}
                validationSchema={validate}
                onSubmit={handleProductCreation}
            >
                {formik => (
                    <Form>
                        {/* Product Info Fields */}
                        <div className="flex flex-row justify-between p-2 gap-12">
                            {/* Product Name */}
                            <div className="flex flex-col w-full">
                                <label className="text-xs">Product Name</label>
                                <Field
                                    type="text"
                                    name="productName"
                                    className="bg-gray-100 w-full mt-1 p-2 text-[12px] rounded-md focus:outline-none"
                                />
                                <ErrorMessage name="productName" component="div" className="text-red-500 text-[9px] italic mt-1" />
                            </div>

                            {/* Product Type */}
                            <div className="flex flex-col w-full">
                                <label className="text-xs">Product Type</label>
                                <Field
                                    as="select"
                                    name="productType"
                                    className="border w-full p-2 mt-1 text-[12px] rounded-lg focus:outline-none"
                                >
                                    <option value="">Select Type</option>
                                    <option value="fertilizer">Fertilizer</option>
                                    <option value="pesticide">Pesticide</option>
                                    <option value="seeds">Seeds</option>
                                </Field>
                                <ErrorMessage name="productType" component="div" className="text-red-500 text-[9px] italic mt-1" />
                            </div>
                        </div>

                {/* Product Details Array */}
                <FieldArray name="productDetails">
                    {({ push, remove, form }) => (
                        <>
                            {form.values.productDetails.map((item, index) => (
                                <div key={index} className="flex flex-row gap-4 mt-4 border p-3 rounded-md">
                                    {/* Product Image URL */}
                                    <div className="w-1/4 flex flex-col">
                                        <label className="text-sm mb-1">Product Image</label>
                                        <input
                                            type="file"
                                            id={`productDetails[${index}].productImage`}
                                            name={`productDetails[${index}].productImage`}
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e, index, form.setFieldValue)}
                                            className="border w-full p-3 text-[12px] rounded-lg"
                                        />
                                        {form.values.productDetails[index].imagePreview && (
                                            <img
                                                src={form.values.productDetails[index].imagePreview}
                                                alt="Preview"
                                                className="mt-2 w-24 h-24 object-cover"
                                            />
                                        )}
                                        <ErrorMessage name={`productDetails[${index}].productImage`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                    </div>

                                    {/* Other Product Detail Fields */}
                                    <div className="w-3/4 grid grid-cols-2 gap-3">
                                        <div className="flex flex-col">
                                            <label className="text-xs">Product Volume</label>
                                            <Field
                                                type="text"
                                                name={`productDetails[${index}].productVolume`}
                                                className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                            />
                                            <ErrorMessage name={`productDetails[${index}].productVolume`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                        </div>

                                        <div className="flex flex-col">
                                            <label className="text-xs">Product Price</label>
                                            <Field
                                                type="text"
                                                name={`productDetails[${index}].productPrice`}
                                                className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                            />
                                            <ErrorMessage name={`productDetails[${index}].productPrice`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                        </div>

                                        <div className="flex flex-col">
                                            <label className="text-xs">Product Stock</label>
                                            <Field
                                                type="text"
                                                name={`productDetails[${index}].productStock`}
                                                className="bg-gray-100 w-full p-3 mt-2 text-[12px] rounded-md"
                                            />
                                            <ErrorMessage name={`productDetails[${index}].productStock`} component="div" className="text-red-500 text-[9px] italic mt-1" />
                                        </div>
                                    </div>

                                    {/* Remove Button (hidden for the first item) */}
                                    {index > 0 && (
                                        <button type="button" onClick={() => remove(index)} className="text-red-500 text-sm font-bold ml-2">
                                            Remove
                                        </button>
                                    )}
                                </div>
                            ))}

                            {/* Add More Items Button */}
                            <div className="flex items-end justify-end">
                                <button
                                    type="button"
                                    onClick={() => push({ productVolume: "", productPrice: "", productStock: "", productImage: "" })}
                                    className="mt-4 bg-agro_darkgreen text-sm text-white p-2 rounded-md"
                                >
                                    Add More Items
                                </button>
                            </div>
                        </>
                    )}
                </FieldArray>


                        {/* Submit Button */}
                        <div className="flex justify-center mt-8">
                            <button type="submit" className="bg-agro_greenlight hover:bg-agro_greenlight/80 text-sm text-white px-8 py-2 rounded-md">
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

            {alertProps.show && (
                <Alert message={alertProps.message} severity={alertProps.severity} onClose={hideAlert} />
            )}
        </div>
    );
}

export default CreateProduct;
