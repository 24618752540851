import React, { useState, useEffect, useRef } from "react";
import noprofile from "../Assests/sidebar/noprofile.png";
import { useDispatch, useSelector } from "react-redux";
import CustomAxios from "../API/CustomAxios";
import menu from "../Assests/sidebar/headerMenu.png";
import { setUserImg } from "../Pages/ReduxManagement/authSlice";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {API_BASE_NORMALSCAP} from '../API/APIUrls';
import { handleLogout } from "../Pages/ReduxManagement/logoutSlice";

function Header({ setShowSidebar, showsidebar }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const username = useSelector((state) => state.signup.username);
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  const userId = useSelector((state) => state.auth.userID);
  const profile = useSelector((state) => state.auth.userImg);
  const headerContent = useSelector((state) => state.headercontent.headerContent);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  useEffect(() => {
    getUserImg();
  }, [setUserImg]);

  const onLogout=()=>{
    handleLogout();
    navigate("/");
  }
  // Fetch the profile image
  const getUserImg = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_NORMALSCAP,
        url: `user/profileimage/${userId}`,
        headers: {
          "Content-Type": "application/json",
          "token": token,
        },
        responseType: "arraybuffer",
      });

      if (response.status === 200) {
        const imageBlob = new Blob([response.data], { type: "image/png" });
        const imageUrl = URL.createObjectURL(imageBlob);
        dispatch(setUserImg(imageUrl)); 
      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className="w-full bg-white rounded-tr-md flex flex-row py-3 px-4  justify-between shadow-md z-20">
      <div className="flex flex-row gap-4 mob:gap-2">
        <button className={`${showsidebar ? "mob2:hidden" : "sm:block lg:hidden"} flex items-center justify-center`}
          onClick={() => setShowSidebar(!showsidebar)}
        >
          <img src={menu} alt="menu icon" className="w-5 h-5" />
        </button>
        <h1 className="flex text-start font-semibold text-2xl mt-2 text-gray-500 tracking-wider lg:ml-0">
          {headerContent}
        </h1>
      </div>

      <div className="relative flex flex-row items-center gap-3">
        <img
          src={profile || noprofile}
          className="w-12 h-12 object-cover border-2 bg-agro_lightgreen rounded-full border-white"
          alt="profile"
        />
        <h3 className="text-xs font-semibold text-gray-500 truncate mob2:hidden mob:block">
          {username}
        </h3>
        <RiArrowDropDownLine
          className="text-2xl cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />

        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div className="absolute top-14 right-0 bg-white border text-xs border-gray-300 shadow-md rounded-md w-40 z-20"
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <ul className="flex flex-col">
              <li className="px-6 py-2 hover:bg-agro_lightgreen hover:w-full hover:mx-0 border-b-gray-300 border-b  cursor-pointer"
                onClick={() => navigate("/dashboard/profile")}
              >
                Profile
              </li>
              <li className="px-6 py-2 hover:bg-agro_lightgreen hover:w-full cursor-pointer"
                onClick={()=>onLogout()}
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
