import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import CustomAxios from '../../../API/CustomAxios';
import { useSelector } from 'react-redux';
import {API_BASE_SMARTAJAP} from '../../../API/APIUrls';

function SupplierSales() {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState([]);
  const userId = useSelector((state) => state.auth.userID);

  useEffect(() => {
    generateYears();
    fetchProductNames();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      setSelectedProduct(products[0]); // Set first product as default
    }
  }, [products]);

  useEffect(() => {
    if (years.length > 0) {
      setSelectedYear(years[0]); // Set the first year as default
    }
  }, [years]);

  useEffect(() => {
    if (selectedProduct && selectedYear) {
      getSalesData();
    }
  }, [selectedProduct, selectedYear]);

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const generatedYears = [];
    for (let year = 2024; year <= currentYear; year++) {
      generatedYears.push(year);
    }
    setYears(generatedYears);
  };

  const fetchProductNames = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: "GetProductTypesBySupplier",
        params: {
          supplierID: userId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setProducts(response.data.productTypes || []);
      }
    } catch (error) {
      console.error("Error fetching product names:", error);
    }
  };

  const getSalesData = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: "GetAnnualSalesChartBySupplierAndProductType",
        params: {
          productType: selectedProduct,
          year: selectedYear,
          supplierID: userId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { dates, quantities } = response.data.chartData;
        const monthData = Array.from({ length: 12 }, (_, index) => ({
          name: new Date(0, index).toLocaleString("default", { month: "short" }),
          quantity: 0,
        }));

        dates.forEach((date, index) => {
          const monthIndex = new Date(date).getMonth();
          monthData[monthIndex].quantity += quantities[index];
        });

        setData(monthData);
      }
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setData(defaultdata);
    }
  };

  const defaultdata = Array.from({ length: 12 }, (_, index) => ({
    name: new Date(0, index).toLocaleString("default", { month: "short" }),
    quantity: 0,
  }));

  const handleFilterChange = (filterType, value) => {
    if (filterType === "product") setSelectedProduct(value);
    if (filterType === "year") setSelectedYear(value);
  };

  return (
    <div className="border bg-white border-none shadow-md rounded-md p-4 flex flex-col gap-3 w-full">
      <div className="flex flex-row items-center gap-4">
        <h1 className="">SALES</h1>
        {/* Product Filter */}
        <select
          value={selectedProduct}
          onChange={(e) => handleFilterChange("product", e.target.value)}
          className="border focus:outline-none  p-2 text-xs rounded-md text-black"
        >
          <option value="">Select Product</option>
          {products.map((product, index) => (
            <option key={index} value={product}>
              {product}
            </option>
          ))}
        </select>
        {/* Year Filter */}
        <select
          value={selectedYear}
          onChange={(e) => handleFilterChange("year", e.target.value)}
          className="border focus:outline-none text-xs p-2 rounded-md"
        >
          <option value="">Select Year</option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {/* Line Chart */}
      <div className="relative">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={data.length ? data : defaultdata}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" tick={{ fontSize: 12 }} />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip wrapperStyle={{ fontSize: "12px" }} />
            <Line type="monotone" dataKey="quantity" stroke="#008922" />
          </LineChart>
        </ResponsiveContainer>
        <div
          className="absolute top-0 left-0 w-full h-full bg-green-100 opacity-50 pointer-events-none"
          style={{ zIndex: -1 }}
        ></div>
      </div>
    </div>
  );
}

export default SupplierSales;
