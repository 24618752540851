import React, { useEffect, useState } from "react";
import CustomAxios from "../../../API/CustomAxios";
import { useSelector } from "react-redux";
import { API_BASE_SMARTSCAP } from "../../../API/APIUrls";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

// Custom icon for Leaflet markers
const customIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  shadowSize: [41, 41],
});

function MapCard() {
  const userId = useSelector((state) => state.auth.userID); // Get user ID from Redux store
  const [locations, setLocations] = useState([]); // Store locations from API
  const [selectedLocation, setSelectedLocation] = useState(null); // Store selected location's lat/lng

  const defaultCenter = {
    lat: 7.8731, // Default latitude (Sri Lanka)
    lng: 80.7718, // Default longitude (Sri Lanka)
  };

  useEffect(() => {
    getLocations(); // Fetch locations when the component mounts
  }, []);

  // Fetch all lands by farmer
  const getLocations = async () => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTSCAP,
        url: "getAllLandsByFarmer",
        params: {
          farmerID: userId, // Pass farmer ID as a parameter
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setLocations(response.data); // Store the locations from the response
      }
    } catch (error) {
      console.error("Error fetching land data:", error);
    }
  };

  // Fetch coordinates from OpenCage API
  const getLatLngFromAddress = async (address) => {
    const API_KEY = "f1d675ef612f4c6aa4f4b9a2af842767"; // Replace with your OpenCage API key
    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          address
        )}&key=${API_KEY}`
      );
      const data = await response.json();
      console.log("darra", data)
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry;
        return { lat, lng };
      } else {
        console.error("No results found for the address:", address);
        return null;
      }
    } catch (error) {
      console.error("Error fetching coordinates from OpenCage API:", error);
      return null;
    }
  };

  // Handle location change in the dropdown
  const handleLocationChange = async (event) => {
    const locationName = event.target.value;
    const selectedLand = locations.find((land) => land.Location === locationName);

    if (selectedLand) {
      // If location does not have lat/lng, fetch from OpenCage API
      const latLng =
        selectedLand.Latitude && selectedLand.Longitude
          ? { lat: selectedLand.Latitude, lng: selectedLand.Longitude }
          : await getLatLngFromAddress(selectedLand.Location);

      if (latLng) {
        setSelectedLocation({
          lat: latLng.lat,
          lng: latLng.lng,
          name: selectedLand.Location,
        });
      }
    }
  };

  return (
    <div className="w-full h-full bg-white rounded-md shadow-md p-6">
      <h2 className="font-bold text-gray-800 text-lg mb-4">Select a Location on the Map</h2>

      {/* Dropdown for selecting land location */}
      <select
        className="border rounded-md p-2 w-full focus:outline-none text-sm text-black"
        onChange={handleLocationChange}
      >
        <option value="">Select a Location</option>
        {locations.map((land) => (
          <option key={land.LandID} value={land.Location} className="text-xs">
            {land.Location} (Land Size: {land.LandSize} acres)
          </option>
        ))}
      </select>

      {/* Map Section */}
      <div className="mt-4 w-full h-96 bg-red-100 relative">
        <MapContainer
          center={selectedLocation || defaultCenter}
          zoom={selectedLocation ? 15 : 10}
          style={{ height: "68vh", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Show marker for the selected location */}
          {selectedLocation ? (
            <Marker position={[selectedLocation.lat, selectedLocation.lng]} icon={customIcon}>
              <Popup>{selectedLocation.name}</Popup>
            </Marker>
          ) : (
            // Add a marker for the default center
            <Marker position={[defaultCenter.lat, defaultCenter.lng]} icon={customIcon}>
              <Popup>Default Location</Popup>
            </Marker>
          )}
      </MapContainer>
      </div>
    </div>
  );
}

export default MapCard;




// import React, { useEffect, useState } from "react";
// import CustomAxios from "../../../API/CustomAxios";
// import { useSelector } from "react-redux";
// import { API_BASE_SMARTSCAP, GOOGLE_MAPS_API_KEY } from "../../../API/APIUrls";
// import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

// function MapCard() {
//   const userId = useSelector((state) => state.auth.userID); // Get user ID from Redux store
//   const [locations, setLocations] = useState([]); // Store locations from API
//   const [selectedLocation, setSelectedLocation] = useState(null); // Store selected location's lat/lng

//   // Google Maps API loader
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: GOOGLE_MAPS_API_KEY,
//   });

//   // Map container style
//   const containerStyle = {
//     width: "100%",
//     height: "68vh",
//   };

//   const defaultCenter = {
//     lat: 7.8731, // Default latitude (Sri Lanka)
//     lng: 80.7718, // Default longitude (Sri Lanka)
//   };

//   useEffect(() => {
//     getLocations(); // Fetch locations when the component mounts
//   }, []);

//   // Fetch all lands by farmer
//   const getLocations = async () => {
//     try {
//       const response = await CustomAxios({
//         method: "GET",
//         baseURL: API_BASE_SMARTSCAP,
//         url: "getAllLandsByFarmer",
//         params: {
//           farmerID: userId, // Pass farmer ID as a parameter
//         },
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       if (response.status === 200) {
//         setLocations(response.data); // Store the locations from the response
//       }
//     } catch (error) {
//       console.error("Error fetching land data:", error);
//     }
//   };

//   // Handle location change in the dropdown
//   const handleLocationChange = async (event) => {
//     const locationName = event.target.value;
//     const selectedLand = locations.find((land) => land.Location === locationName);
  
//     if (selectedLand) {
//       // If location has lat/lng, update the map
//       if (selectedLand.Latitude && selectedLand.Longitude) {
//         setSelectedLocation({
//           lat: selectedLand.Latitude,
//           lng: selectedLand.Longitude,
//           name: selectedLand.Location,
//         });
//       } else {
//         // If no lat/lng, use Geocoding API to fetch coordinates
//         const latLng = await getLatLngFromGoogleAPI(locationName);
//         if (latLng) {
//           setSelectedLocation({
//             lat: latLng.lat,
//             lng: latLng.lng,
//             name: locationName,
//           });
//         }
//       }
//     }
//   };

//   // Fetch coordinates from Google Geocoding API
//   const getLatLngFromGoogleAPI = async (address) => {
//     try {
//       const response = await fetch(
//         `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
//           address
//         )}&key=${GOOGLE_MAPS_API_KEY}`
//       );
//       const data = await response.json();
//       if (data.status === "OK" && data.results.length > 0) {
//         const { lat, lng } = data.results[0].geometry.location;
//         return { lat, lng };
//       } else {
//         console.error("No results found for the address:", address);
//         return null;
//       }
//     } catch (error) {
//       console.error("Error fetching coordinates from Geocoding API:", error);
//       return null;
//     }
//   };

//   return (
//     <div className="w-full h-full bg-white rounded-md shadow-md p-6">
//       <h2 className="font-bold text-gray-800 text-lg mb-4 ">Select a Location on the Map</h2>
      
//       {/* Dropdown for selecting land location */}
//       <select
//         className="border rounded-md p-2 w-full focus:outline-none text-sm text-black"
//         onChange={handleLocationChange}
//         // value={selectedLocation ? selectedLocation.name : ""}
//       >
//         <option value="">Select a Location</option>
//         {locations.map((land) => (
//           <option key={land.LandID} value={land.Location} className="text-xs">
//             {land.Location} (Land Size: {land.LandSize} acres)
//           </option>
//         ))}
//       </select>

//       {/* Map Section */}
//       <div className="mt-4 w-full h-96 relative">
//         {isLoaded && (
//           <GoogleMap
//             mapContainerStyle={containerStyle}
//             center={selectedLocation || defaultCenter} // Default center if no location is selected
//             zoom={selectedLocation ? 15 : 10} // Zoom in if a location is selected
//           >
//             {/* Show marker at the default center initially */}
//             <MarkerF
//               position={selectedLocation || defaultCenter}
//               title={selectedLocation ? selectedLocation.name : "Default Location"}
//             />
//             {/* Show selected location's marker */}
//             {selectedLocation && (
//               <MarkerF
//                 position={{
//                   lat: selectedLocation.lat,
//                   lng: selectedLocation.lng,
//                 }}
//                 title={selectedLocation.name}
//               />
//             )}
//           </GoogleMap>
//         )}
//       </div>
//     </div>
//   );
// }

// export default MapCard;
