import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import SignupKey from './SignupKey';
import { showSignupKey, hideSignupKey, setSignupUsername } from '../ReduxManagement/signupSlice';
import CustomAxios from '../../API/CustomAxios';
import Alert from '../../Components/Alert';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import showpw from '../../Assests/showpw.png';
import hidepw from '../../Assests/hidepw.png';
import * as Yup from 'yup';
import GridLoader from 'react-spinners/GridLoader';
import EmailVerification from '../../Components/EmailVerification';



function Signup() {
  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_SMARTAJAP = process.env.REACT_APP_API_SMARTAJAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const API_BASE_NORMALAJAP = process.env.REACT_APP_API_NORMALAJAP;

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [showBrandNameField, setShowBrandNameField] = useState(false);
  const showKeyModel = useSelector((state) => state.signup.showSignupKey);
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
  const [img, setImg] = useState(hidepw);
  const [type, setType] = useState("password");
  const [confirmtype, setConfirmtype] = useState("password");
  const [confirmImg, setConfirmImg] = useState(hidepw);
  const refreshToken = useSelector((state) => state.auth.REFRESHTOKEN);
  const [showEmailVerifyModel, setShowEmailVerifyModel] = useState(false);
  const [model, setModel] = useState(false);
  const [formData, setFormData] = useState({
    recipient_name: '',
    address: '',
    uid: '',
    email: '',
    role:"",
  });
  

  useEffect(()=>{
  },[formData])


  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
 

  //handling the sign up
  const handleRegister = (values)=>{
       handleSendingEmail(values );
      // if(values.role === "farmer" ){
      //   handleFarmerRegistration(values) 
      // }else {  
      //   handleSupplierRegistration(values)
      // }
  }


  //handle sending email verification mail
  const handleSendingEmail = async(newValues)=>{

    setFormData({
      role:newValues.role,
      recipient_name: newValues.name,
      username: newValues.username,
      email: newValues.email,
    })

    console.log("new val", newValues)
    // setShowEmailVerifyModel(true)
    try{
      setModel(true);
      const response =await CustomAxios({
        method:"PUT",
        baseURL:API_BASE_NORMALSCAP,
        url:"user/sendverifyemail",
        data:{
          role:newValues.role,
          recipient_name: newValues.name,
          username: newValues.username,
          email: newValues.email,
          state:"signup"
        } ,
        headers:{
          "Content-Type":"application/json",
        },
      })
      if(response.status === 200){
          if(newValues.role === "farmer" ){
            handleFarmerRegistration(newValues) 
          }else {  
            handleSupplierRegistration(newValues)
          }
      
      }else{
        setModel(false);
        setAlertProps({severity: "error",message: "Error Occured in Sending Verification Email",show: true,});
      }
    }catch(error){
      setModel(false);
      setAlertProps({severity: "error",message: "Error Occured in Sending Verification Email",show: true,});
      console.log(error);
    }
  }
    
    //handle supplier registration
    const handleSupplierRegistration = async(values)=>{
      if(!values.brandName.length){
        setAlertProps({severity: "warning",message: "Brand Name field is required",show: true,}); 
        return;
      }   
      const {confirm_password, ...newValues} = values;
      const signupValues = {Supplier:newValues};

      try{
        const signupResponse = await CustomAxios({
          method:"POST",
          baseURL:API_BASE_NORMALSCAP,
          url:"supplier/signup",
          data: signupValues,
          headers:{
            "Content-Type":"application/json"
          },
        })
        if(signupResponse.status === 200){
          // console.log(signupResponse);
          // console.log(signupResponse.data.user.username);
          setModel(false);
          setShowEmailVerifyModel(true)
          const data = signupResponse.data.supplier;
          setAlertProps({severity: "success",message:"Successfully Registered!",show: true,}); 
          // setFormData({
          //   role:data.role,
          //   recipient_name: data.name,
          //   uid: data.uid,
          //   email: data.email,
          // })
          // handlesendingEmail()
          // dispatch(showSignupKey());
          // dispatch(setSignupUsername(signupResponse.data.user.username))
        }else{
          setModel(false);
          setAlertProps({severity: "error",message: signupResponse.response.data.error,show: true,});
        }
      }catch(error){
        setModel(false);
        const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
        setAlertProps({ severity: "error", message: errorMessage, show: true });
      }
    }


    //hanlde farmer registartion
    const handleFarmerRegistration = async(values)=>{
          const {confirm_password,brandname, ...newValues} = values;
          const signupValues = {user:newValues};
          try{
            const signupResponse = await CustomAxios({
              method:"POST",
              baseURL:API_BASE_NORMALSCAP,
              url:"user/signup",
              data: signupValues,
              headers:{
                "Content-Type":"application/json"
              },
            })
            if(signupResponse.status === 200){
              setModel(false);
              setShowEmailVerifyModel(true)
              // console.log(signupResponse);
              // console.log(signupResponse.data.user.username);
              console.log(signupResponse.data.message)
              setAlertProps({severity: "success",message: "Successfully Registered!",show: true,}); 
              const data = signupResponse.data.user;
              // setFormData({
              //   role:data.role,
              //   recipient_name: data.name,
              //   uid: data.uid,
              //   email: data.email,
              // })
              console.log("heel",formData)
              // handlesendingEmail(formData)
              // dispatch(showSignupKey());
              // dispatch(setSignupUsername(signupResponse.data.user.username))
            }else{
              setModel(false)
              setAlertProps({severity: "error",message: signupResponse.response.data.error,show: true,});
            }
          }catch(error){
            setModel(false);
            const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
            setAlertProps({ severity: "error", message: errorMessage, show: true });
          }
      }



    // validation for signup
    const validate = Yup.object({
        username:Yup.string()
        .min(8, "Must be more than 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches( /^[a-z0-9@_]+$/,"Must include at least one number and can only contain lowercase letters, @, and _")
        .required("*Required"),
        email: Yup.string()
        .email("Invalid email")
        .required("*Required"),
        name: Yup.string()
        .min(3, "Must be more than 3 characters")
        .max(100, "Must be 100 characters or less")
        .required("*Required"),
        address: Yup.string()
        .required("*Required"),
        role:Yup.string()
        .required("*Required"),
        // brandname: Yup.string()
        // .required("*Required"),
        country: Yup.string()
        .required("*Country is required"),
        contactno: Yup.string()
        .required("*Phone number is required")
        .matches(
          /^\+?[0-9]{9,15}$/,  // Regex for validating phone numbers with country code
          "Phone number must be between 9 and 15 digits"
        )
        .required("*Contact number is required"),
        password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
        .required("*Password is Required"),
        confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "*Passwords must match")
        .required("*Confirm password is required"),
    });
  

    //handle password toggle
    const handlePwToggle = () => {
      if (type === "password") {
          setImg(showpw); 
          setType("text"); 
      } else {
          setImg(hidepw); 
          setType("password");
      }
    };


    //handle password toggle
    const handleConfirmPwToggle = () => {
      if (confirmtype === "password") {
          setConfirmImg(showpw); 
          setConfirmtype("text"); 
      } else {
          setConfirmImg(hidepw); 
          setConfirmtype("password");
      }
    };



    return (
     <>
      <Formik
        initialValues={{
          username:"",
          name:'',
          address:"",
          brandName:"",
          contactno:"",
          country:"",
          password:"",
          confirm_password:'',
          email:'',
          role:"",
          status:"activate",
          isVerified:false,
        }}
        validationSchema={validate}
        onSubmit={handleRegister}
      >
       {(formik)=>{
        const handlePhoneChange = (phone, countryData)=>{
          formik.setFieldValue("contactno", phone); 
          formik.setFieldValue("country", countryData.name); 
        }
        return(
        <Form className='flex flex-col xl:gap-[12px] mt-4 mob2:gap-4 w-full mob2:justify-evenly h-full sm:px-12 mob2:px-4 md:px-0 '>
          
          {model && (
            <div className="fixed inset-0 flex items-center justify-center z-30 bg-gray-400 bg-opacity-70 ">
                <GridLoader color="#0c3e26" size={22} height={150} margin={5} speedMultiplier={1} width={30} className="z-50" />
              {/* <div className="absolute inset-0 bg-zinc-200 opacity-70"></div> */}
            </div>
          )}
          {/* Field for username */}
          <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="username"
              name="username"
              placeholder="UserName"
              onChange={formik.handleChange}
              value={formik.values.username}
              className="border w-full focus:outline-none xl:p-3 mob2:p-3  text-[12px] text-black rounded-lg"
            />
            <ErrorMessage 
              name="username" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic " 
            />      
          </div>


          {/* Field for name */}
          <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
            />
            <ErrorMessage 
              name="name" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic " 
            />      
          </div>


          {/* Field for address */}
           <div className='flex flex-col h-12'>
            <Field
              type="text"
              id="address"
              name="address"
              placeholder="Address"
              onChange={formik.handleChange}
              value={formik.values.address}
              className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
            />
            <ErrorMessage 
              name="address" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>

          {/* Field for Email*/}
          <div className='flex flex-col h-12'>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
            />
            <ErrorMessage 
              name="email" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>
  

           {/* Field for contact number */}
           

          <div className="flex flex-col h-12">
              <PhoneInput
                country={"lk"}
                enableSearch={true}
                // value={formik.values.contactno}
                // onChange={(phone) => formik.setFieldValue("contactno", phone)}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: "100%", 
                  height:"23px",
                  paddingLeft: "80px", // Ensure enough space for flag and country code
                  borderRadius: "0.375rem", 
                  border: "1px solid #d1d5db", 
                  fontSize: "12px", 
                  color: "#00000",
                }}
                
                buttonStyle={{
                  border: "none", // Removes default border
                  background: "transparent", // Ensures consistency with form
                }}
                containerClass="phone-input-container"
                inputClass="phone-input"
              />
              <ErrorMessage
                name="contactno"
                component="div"
                className="text-red-500 text-[9px] font-medium italic"
              />
          </div>


            {/* Field for role */}
          <div className='flex flex-col h-12'>
            <Field
              as="select"
              id="role"
              name="role"
              onChange={(e)=>{
                formik.handleChange(e)
                e.target.value === "supplier" ? setShowBrandNameField(true) : setShowBrandNameField(false);
              }}
              value={formik.values.role}
              
              className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
            >
                <option value=""> Select User </option>
                <option value="farmer">Farmer</option>
                <option value="supplier">Supplier</option>
            </Field>
            {/* <ErrorMessage 
              name="role" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />       */}
          </div>


          {formik.values.role === "supplier" && (
            <div className='flex flex-col h-12'>
              <Field
                type="text"
                id="brandName"
                name="brandName"
                placeholder="Brand Name"
                onChange={formik.handleChange}
                value={formik.values.brandName}
                className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
              />
              <ErrorMessage 
                name="brandName" 
                component="div" 
                className="text-red-500 text-[9px] font-medium italic" 
              />
            </div>
          )}


          {/* Field for password */}
          <div className='flex flex-col h-12 relative'>
            <Field
              type={type}
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
            />
              <button onClick={(e) => {e.preventDefault(); handlePwToggle();}}>
                  <img src={img} className='absolute w-[20px] h-[20px] bottom-5 right-4 opacity-40'/>
              </button>
            <ErrorMessage 
              name="password" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>
  
  
        {/* Field for Confirm password */}
          <div className='flex flex-col h-12 relative'>
            <Field
              type={confirmtype}
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
              className="border w-full focus:outline-none xl:p-3  mob2:p-3  text-[12px] text-black rounded-lg"
            />
            <button onClick={(e) =>{e.preventDefault(); handleConfirmPwToggle();}}>
                <img src={confirmImg} className='absolute w-[20px] h-[20px] bottom-5 right-4 opacity-40'/>
            </button>
            <ErrorMessage 
              name="confirm_password" 
              component="div" 
              className="text-red-500 text-[9px] font-medium italic" 
            />      
          </div>
  
        <div className='flex flex-col items-center gap-2 mob2:mt-2 xl:mt-4 lg:mt-2 justify-center'>
            <button
             type="submit"
              className='bg-agro_darkgreen font-semibold w-full text-[13px] text-white p-2  rounded-lg'
            >
              Sign Up
            </button>
            <h3 className='font-light text-black/50 text-[12px] mt-4 text-center '>Already have an account?
              <button 
                onClick={()=>navigate("/")}
                className='text-agro_darkgreen font-semibold ml-1 text-[13px]'
              > 
                Login to the Account
              </button> 
            </h3>
        </div>
        </Form>
      ) }}
      </Formik>  
      
      {/* shows the email verification model */}
      {showEmailVerifyModel && <EmailVerification onClose={()=>{setShowEmailVerifyModel(false); navigate("/")}}/>}

      {/* Calling the Alert component */}
        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />

    </>
    );
  }
  

export default Signup