import React from 'react';
import { useNavigate } from 'react-router-dom';

function MessageAlert() {
  const navigate = useNavigate();

  return (
    <div className="fixed sm:right-8 top-1/2 transform -translate-y-1/2  bg-white shadow-md border-l-4 border-red-500 p-4 rounded-lg sm:w-80 z-20 mob:w-72 mob2:right-0">
      <h3 className="text-red-500 font-bold text-lg mb-2">Email Is Not Verified</h3>
      <p className="text-gray-600 text-sm mb-4 text-justify">
        Your email is not verified. Please verify it to access all features.
      </p>
      <button
        onClick={() => navigate('/dashboard/profile')}
        className="bg-red-500 text-white py-2 text-xs  px-4 rounded hover:bg-red-600 transition"
      >
        Go to Profile
      </button>
    </div>
  );
}

export default MessageAlert;


