import React, { useState } from 'react';
import Alert from '../../Components/Alert';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomAxios from '../../API/CustomAxios';
import Model from '../../Components/Model';


function AddLandInfo({ selectedLand, setSelectedLand, getLands }) {
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const [model, setModel] = useState(false);
  const [alertProps, setAlertProps] = useState({ message: '', severity: 'success', show: false });
  const [userId, setUserID] = useState(useSelector((state) => state.auth.userID));

  const validate = Yup.object({
    location: Yup.string().required('*Required'),
    landsize: Yup.number().required('*Required'),
    cultivated_landsize: Yup.number().required('*Required'),
  });

  // hide alerts 
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };

  // Main handler function
  const handleLandSave = async (values) => {
    if (selectedLand) {
      await updateLand(values);
    } else {
      await createLand(values);
    }
  };

  // Function to handle land creation
  const createLand = async (values) => {
    const data = {
      farmerid: userId,
      location: values.location,
      landsize: values.landsize,
      cultivated_landsize: values.cultivated_landsize,
    };

    try {
      setModel(true); 
      const response = await CustomAxios({
        method: 'POST',
        baseURL: API_BASE_SMARTSCAP,
        url: 'createLand',
        data: data,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setModel(false); 
        setAlertProps({ severity: 'success', message: 'Land added successfully!', show: true });
        getLands();
      }
    } catch (error) {
        setModel(false); 
        setAlertProps({ severity: 'error', message: 'An Error Occurred', show: true });
    }
  };

  // Function to handle land updating
  const updateLand = async (values) => {
    const data = {
      landid: selectedLand.LandID,
      farmerid: userId,
      location: values.location,
      landsize: values.landsize,
      cultivated_landsize:values.cultivated_landsize,
    };

    try {
      setModel(true); 
      const response = await CustomAxios({
        method: 'PUT',
        baseURL: API_BASE_SMARTSCAP,
        url: `updateLandByID`,
        data: data,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setModel(false); 
        getLands();
        setAlertProps({ severity: 'success', message: 'Land updated successfully!', show: true });
        setSelectedLand(null);
      }
    } catch (error) {
      setModel(false); 
      setAlertProps({ severity: 'error', message: 'An Error Occurred', show: true });
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full h-full">
      <Model showModel={model}/>
      <h2 className="text-lg font-semibold text-center mb-6">Enter Your Land Information</h2>
      <Formik
        initialValues={{
          location: selectedLand?.Location || '',
          landsize: parseInt(selectedLand?.LandSize) || '',
          cultivated_landsize: parseInt(selectedLand?.CultivatedSize) || '',
        }}
        validationSchema={validate}
        onSubmit={handleLandSave}
        enableReinitialize
      >
        {(formik) => (
          <Form className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <label className="text-sm text-black/70">Land Address</label>
              <Field
                type="text"
                id="location"
                name="location"
                onChange={formik.handleChange}
                value={formik.values.location}
                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
              />
              <ErrorMessage
                name="location"
                component="div"
                className="text-red-500 text-[9px] font-medium italic mt-1"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-sm text-black/70">Own Land Hectares</label>
              <Field
                type="number"
                id="landsize"
                name="landsize"
                onChange={formik.handleChange}
                value={formik.values.landsize}
                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
              />
              <ErrorMessage
                name="landsize"
                component="div"
                className="text-red-500 text-[9px] font-medium italic mt-1"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-sm text-black/70">Cultivating Land Hectares</label>
              <Field
                type="number"
                id="cultivated_landsize"
                name="cultivated_landsize"
                onChange={formik.handleChange}
                value={formik.values.cultivated_landsize}
                className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
              />
              <ErrorMessage
                name="cultivated_landsize"
                component="div"
                className="text-red-500 text-[9px] font-medium italic mt-1"
              />
            </div>

            <div className="flex items-center justify-center">
              <button className="bg-agro_darkgreen text-white text-xs py-2 rounded-md w-full text-center">
                {selectedLand ? 'Update' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Alert message={alertProps.message} severity={alertProps.severity} show={alertProps.show} onClose={hideAlert} />
    </div>
  );
}

export default AddLandInfo;
