import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import CustomAxios from '../../../API/CustomAxios';
import { API_BASE_SMARTSCAP, API_BASE_NORMALSCAP } from '../../../API/APIUrls';

const ReminderCard = () => {
  const [reminders, setReminders] = useState([]);
  const [timePeriod, setTimePeriod] = useState(null);
  const [cultivationDetails, setCultivationDetails] = useState(null);
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  const userId = useSelector((state) => state.auth.userID);

  const defaultReminders = [
    { id: 1, message: 'Reminder about fertilizer application', icon: faBell },
    { id: 2, message: 'Reminder about pesticide application', icon: faBell },
    { id: 3, message: 'Reminder about seed planting', icon: faBell },
  ];

  // Fetch latest cultivation details
  useEffect(() => {
    const fetchCultivationInfo = async () => {
      try {
        const response = await CustomAxios({
          method: 'GET',
          baseURL: API_BASE_SMARTSCAP,
          url: 'getcultivationByFarmer',
          params: { 
            farmerID: userId 
          },
          headers: { 
            'Content-Type': 'application/json', 
            token
          },
        });

        if (response.status === 200) {
          const sortedCultivations = response.data.Cultivation.sort(
            (a, b) => new Date(b.cultivationDate) - new Date(a.cultivationDate)
          );
          const latestCultivation = sortedCultivations[0];
          setCultivationDetails(latestCultivation);
        }
      } catch (error) {
        console.error('Error fetching cultivation details:', error);
      }
    };

    fetchCultivationInfo();
  }, [userId, token]);


  // Fetch time period for seed type when cultivation details are available
  useEffect(() => {
    if (cultivationDetails?.seed_type) {
      const fetchSeedTimePeriod = async () => {
        try {
          const response = await CustomAxios({
            method: 'GET',
            baseURL: API_BASE_NORMALSCAP,
            url: `user/seeds/${cultivationDetails.seed_type}`,
            headers: { 
              'Content-Type': 'application/json', 
              token 
            },
          });

          if (response.status === 200) {
            setTimePeriod(response.data.timePeriod);
          }
        } catch (error) {
          console.error('Error fetching seed time period:', error);
        }
      };

      fetchSeedTimePeriod();
    }
  }, [cultivationDetails, token]);


  // Fetch reminders when cultivation details and time period are available
  useEffect(() => {
    if (cultivationDetails && timePeriod) {
      const fetchReminders = async () => {
        try {
          const response = await CustomAxios({
            method: 'GET',
            baseURL: API_BASE_SMARTSCAP,
            url: 'getNotifications',
            params: {
              uid: userId,
              landid: cultivationDetails.landid,
              startdate: cultivationDetails.start_date,
              timeperiod: timePeriod,
              cultivationid: cultivationDetails.cultivationid,
            },
            headers: { 
              'Content-Type': 'application/json', 
              token 
            },
          });
          if (response.status === 200) {
            setReminders(response.data.notifications);
          }
        } catch (error) {
          console.error('Error fetching reminders:', error);
        }
      };
      fetchReminders();
    }
  }, [cultivationDetails, timePeriod, userId, token]);


  return (
    <div className="rounded-lg overflow-hidden shadow-lg bg-white p-6 h-full">
      <h2 className="text-xl font-bold mb-4">Reminders</h2>
      <ul>
        {(reminders.length === 0 ? defaultReminders : reminders).map((reminder, index) => (
          <li
            key={reminder.id || index}
            className="flex justify-between items-center mb-4 p-2 border-b border-gray-200"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={reminder.icon || faBell} className="text-yellow-500 mr-2" />
              <div className="flex flex-row gap-x-2">
                <span className="text-sm">{reminder.message}</span>
                {reminder.date && <span className="text-xs">{reminder.date}</span>}
              </div>
            </div>
          </li>
        ))}
      </ul>
      {reminders.length === 0 && (
        <div className="text-xs text-gray-500 mt-2">Add cultivation details to get reminders.</div>
      )}
    </div>
  );
};

export default ReminderCard;
