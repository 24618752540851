import React, {useState,useEffect} from'react';
import { useSelector } from 'react-redux';
import CustomAxios from '../../API/CustomAxios';
import { API_BASE_NORMALSCAP } from '../../API/APIUrls';
import PhoneInput from 'react-phone-input-2';
import Alert from '../../Components/Alert';
import edit from "../../Assests/newEdit.png";
import GridLoader from 'react-spinners/GridLoader';
import EmailVerification from '../../Components/EmailVerification';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Model from '../../Components/Model';


function ProfileDetails(){
    const navigate = useNavigate();
    const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));
    const username= useSelector((state) => state.signup.username); 
    const token = useSelector((state) => state.auth.ACCESSTOKEN);
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const [showEmailVerifyModel, setShowEmailVerifyModel] = useState(false);
    const [model, setModel] = useState(false);
    const [EditDetails, setEditDetails] = useState(false);
    const [verifiedStatus, setVerifiedStatus] = useState(useSelector((state) =>state.availability.showBlur))
    const [profileDetails, setProfileDetails] =useState({ profilePhoto: null,username: '',name: '',address: '',contactno: '',country:"",nic: '',email: '',brandName:"",})

    const validate = Yup.object({
        username: Yup.string().required('*Required'),
        name: Yup.string().required('*Required'),
        address: Yup.string().required('*Required'),
        contactno: Yup.string().required('*Required'),
        country: Yup.string().required('*Required'),
        nic: Yup.string().required('*Required'),
        email: Yup.string().required('*Required').email('*Invalid email'),
    });

    //get the user profile data 
    useEffect(() => {
        userRole === "supplier" ? fetchSupplierProfileData() : fetchProfileData();
    }, []); 

    //handle which function to be called to update the profile
    const handleprofileUpdate =(values)=>{
        userRole === "supplier" ? handleSupplierUpdate(values) : userRole === "farmer" ? handleFarmerUpdate(values) : handleAdminUpdate(values);
    }

    //fetch profile details of farmer
    const fetchProfileData = async () => {
        try {
          const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/${userId}`,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
          })
          if(response.status === 200){
            const data = response.data.user;
            setProfileDetails({username: data.username,name:data.name,address: data.address,contactno: data.contactno,country: data.country,nic: data.uid,email: data.email,})
          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      };
  
  
    //fetch supplier profile details
    const fetchSupplierProfileData = async()=>{
        try{
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_NORMALSCAP,
                url: `supplier/${userId}`,
                headers: {
                  "Content-Type": "application/json",
                  "token": token,
                },
            })
            if(response.status === 200){
                const data = response.data.supplier
                setProfileDetails({username: data.username,name:data.name,address: data.address,contactno: data.contactno,country:data.country,nic: data.uid,email: data.email,brandName:data.brandName,})
              }
            }catch(error){
              console.log(error);
            }
        }


    //handle the supplier profile details update 
    const handleSupplierUpdate = async (values) => {
        const { email, ...otherValues } = values;
        try {
          const isEmailChanged = email !== profileDetails.email;//checks whether the email is changed   
          const profileUpdateResponse = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_NORMALSCAP,
            url: `supplier/${userId}`,
            data: otherValues,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
          });
      
          if (profileUpdateResponse.status === 200) {
            // console.log("Profile updated successfully");
            if(isEmailChanged){setModel(true)
              handleEmailVerification(email);
            }else{
              setAlertProps({severity: "success",message: "Profile updated successfully.",show: true,});
              fetchSupplierProfileData();
            }
          }
        } catch (error) {
            // console.error("Profile update failed:", error);
            setAlertProps({severity: "error",message: "Error occurred while updating profile.",show: true,});
        }
    };
      
    //handle profile update for farmer
    const handleFarmerUpdate = async(values)=>{
        const { profilePhoto, nic, ...newValues } = values;
        const { email, ...otherValues } = newValues;
        try{
        setModel(true); 
        const isEmailChanged = email !== profileDetails.email;  // Check if the email has changed
        const response = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/${userId}`,
            data: otherValues,
            headers: {
            "Content-Type": "application/json",
            "token": token
            }
        })
        if(response.status === 200){
            // console.log("updated supplier details",response.data);
            if(isEmailChanged){
                handleEmailVerification(email);
            }
            if(isEmailChanged === false){  
                setAlertProps({severity: "success",message: "Profile updated successfully.",show: true,});
                fetchProfileData();
                setModel(false); 
            }    
        }
        }catch(error){
            setModel(false); 
            setAlertProps({severity: "error",message: "Error occurred while updating profile.",show: true,});
        }
    }
  

    // handle profile update for admin
    const handleAdminUpdate = async(values)=>{
        const { email,nic,profilePhoto, ...otherValues } = values;
        try{
            setModel(true); 
            const isEmailChanged = email !== profileDetails.email;
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_NORMALSCAP,
                url: `admin/${userId}`,
                data:otherValues,
                headers: {
                "Content-Type": "application/json",
                "token": token,
                },
            })
            if(response.status === 200){
                // console.log("updated supplier details",response.data);
                if(isEmailChanged){
                // setModel(true)
                handleEmailVerification(email);
                }
                if(isEmailChanged === false){  
                setAlertProps({severity: "success",message: "Profile updated successfully.",show: true,});
                fetchProfileData();
                setModel(false); 
                }  
            }
            }catch(error){
                setModel(false); 
                setAlertProps({severity: "error",message: "Error occurred while updating profile.",show: true,});
                // console.log(error);
            }
    }


    const handleEmailVerification = async (email) => {
        // const updatingEmail = useSelector((state) => state.auth.updatingEmail);
        try {
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_NORMALSCAP,
                url: "user/sendverifyemail",
                data: {
                role: userRole,
                recipient_name: profileDetails.name,
                username: username,
                email: email,
                state:"profileupdate"
                },
                headers: {
                "Content-Type": "application/json",
                "token":token
                },
            });
            if (response.status === 200) {
                setModel(false);
                setShowEmailVerifyModel(true);
            }
        } catch (error) {
            setModel(false);
            console.error("Email verification failed:", error); 
        }
    };

    
    //handle EmailVerification after signup
    const handleEmailVerificationAfterSignup = async()=>{
        setModel(true);
        try{
          const response =await CustomAxios({
            method:"PUT",
            baseURL:API_BASE_NORMALSCAP,
            url:"user/sendverifyemail",
            data:{
              role:userRole,
              recipient_name: profileDetails.name,
              username: username,
              email: profileDetails.email,
              state:"signup"
            } ,
            headers:{
              "Content-Type":"application/json",
            },
          })
          if(response.status === 200){
              setModel(false)
              setShowEmailVerifyModel(true)
          }else{
              setModel(false);
              setAlertProps({severity: "error",message: "Error Occured in Sending Verification Email",show: true,});
          }
        }catch(error){
           setModel(false);
           setAlertProps({severity: "error",message: "Error Occured in Sending Verification Email",show: true,});
        }
    }


    //hide alerts
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };


return(
<>
    <Model showModel={model}/>
<div className='flex relative flex-col h-full bg-white rounded-md'>
<button className='absolute right-4 top-4 flex flex-row gap-2 hover:underline hover:underline-offset-2 items-center text-xs' onClick={()=>setEditDetails(!EditDetails)}><img src={edit} className='w-3 h-3'/>Edit</button>
<Formik
    initialValues={profileDetails}
    validationSchema={validate}
    onSubmit={handleprofileUpdate}
    enableReinitialize // This allows Formik to update with the fetched data
>
{(formik)=>(
    <Form className={` ${EditDetails ? "" :"cursor cursor-not-allowed pointer-events-none "} flex flex-col xl:gap-4  sm:gap-6  mob2:gap-4 justify-evenly  w-full h-full  p-3 items-center py-6 mob2:mt-8`}>
        {/* the field for username */}
        <div className='flex sm:flex-row mob2:flex-col sm:gap-4 mob2:gap-1 lg:w-3/4 mob2:w-full md:px-2 sm:items-center mob2:px-6'>
            <label className='text-sm font-semibold lg:w-1/5 md:w-2/5 sm:w-1/5 mob2:w-full'>Username</label>
            <div className='flex flex-col sm:w-4/5 mob2:w-full'>
                <Field type="text" id="username" name="username" value={formik.values.username} onChange={formik.handleChange}
                    className={` ${EditDetails? "bg-agro_lightgreen/40":""} w-full p-3 text-[12px] text-black/60 rounded-lg    focus:outline-none`}
                />
                <ErrorMessage name="username" component="div" className="text-red-500 text-[9px] font-medium italic mt-1"/>
            </div>
        </div>


        <div className='flex sm:flex-row mob2:flex-col sm:gap-4  mob2:gap-1 lg:w-3/4 mob2:w-full md:px-2 sm:items-center mob2:px-6'>
            <label className='text-sm font-semibold lg:w-1/5 md:w-2/5 sm:w-1/5 mob2:w-full'>Name</label>
            <div className='flex flex-col sm:w-4/5 mob2:w-full'>
                <Field type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange}
                    className={` ${EditDetails? "bg-agro_lightgreen/40":""} w-full p-3 text-[12px] text-black/60 rounded-lg focus:outline-none`}
                />
                <ErrorMessage name="name" component="div" className="text-red-500 text-[9px] font-medium italic mt-1"/>
            </div>
        </div>

        <div className={` ${userRole === "supplier" ? "" :"hidden"} flex sm:flex-row mob2:flex-col sm:gap-4  mob2:gap-1 lg:w-3/4 mob2:w-full md:px-2 sm:items-center mob2:px-6`}>
            <label className='text-sm font-semibold lg:w-1/5 md:w-2/5 sm:w-1/5 mob2:w-full'>BrandName</label>
            <div className='flex flex-col sm:w-4/5 mob2:w-full'>
                <Field type="text" id="brandName" name="brandName" value={formik.values.brandName} onChange={formik.handleChange}
                     className={` ${EditDetails? "bg-agro_lightgreen/40":""} w-full p-3 text-[12px] text-black/60 rounded-lg    focus:outline-none`}
                />
                <ErrorMessage name="brandName" component="div" className="text-red-500 text-[9px] font-medium italic mt-1"/>
            </div>
        </div>

        <div className='flex sm:flex-row mob2:flex-col sm:gap-4  mob2:gap-1 lg:w-3/4 mob2:w-full md:px-2 sm:items-center mob2:px-6'>
            <label className='text-sm font-semibold lg:w-1/5 md:w-2/5 sm:w-1/5 mob2:w-full'>Address</label>
            <div className='flex flex-col sm:w-4/5 mob2:w-full'>
                <Field type="text" id="address" name="address" value={formik.values.address} onChange={formik.handleChange}
                     className={` ${EditDetails? "bg-agro_lightgreen/40":""} w-full p-3 text-[12px] text-black/60 rounded-lg    focus:outline-none`}
                />
                <ErrorMessage name="address" component="div" className="text-red-500 text-[9px] font-medium italic mt-1"/>
            </div>
        </div>

        <div className='flex sm:flex-row mob2:flex-col sm:gap-4  mob2:gap-1 lg:w-3/4 mob2:w-full md:px-2 sm:items-center mob2:px-6'>
            <label className='text-sm font-semibold lg:w-1/5 md:w-2/5 sm:w-1/5 mob2:w-full'>Contact No</label>
            <div className='flex flex-col sm:w-4/5 mob2:w-full'>
                <PhoneInput
                    country={profileDetails.country || "us"} // Default or fetched country code
                    value={formik.values.contactno}
                    enableSearch={true}
                    onChange={(phone, countryData) => {
                        formik.setFieldValue("contactno", phone); // Update contact number
                        formik.setFieldValue("country", countryData.name); // Update country name
                    }}
                    inputStyle={{
                        width: "100%",
                        height: "23px",
                        paddingLeft: "80px", // Ensure enough space for flag and country code
                        borderRadius: "0.375rem",
                        border: "none",
                        fontSize: "12px",
                        color: "#00000",
                        backgroundColor: EditDetails ? "rgba(229, 247, 186, 0.4)": "transparent"
                    }}
                    buttonStyle={{
                        border: "none",
                        background: "transparent",
                    }}
                    containerClass="phone-input-container"
                    inputClass="phone-input"
                    />
                <ErrorMessage name="contactno" component="div" className="text-red-500 text-[9px] font-medium italic mt-1"/>
            </div>
        </div>

        
        <div className='flex sm:flex-row mob2:flex-col sm:gap-4  mob2:gap-1 lg:w-3/4 mob2:w-full md:px-2 sm:items-center mob2:px-6'>
            <label className='text-sm font-semibold lg:w-1/5 md:w-2/5 sm:w-1/5 mob2:w-full'>Email</label>
            <div className='flex flex-col sm:w-4/5 mob2:w-full'>
                <Field type="email" id="email" name="email" value={formik.values.email} onChange={formik.handleChange}
                       className={` ${EditDetails? "bg-agro_lightgreen/40":""} w-full p-3 text-[12px] text-black/60 rounded-lg    focus:outline-none`}
                />
                <ErrorMessage name="email" component="div" className="text-red-500 text-[9px] font-medium italic mt-1"/>
            </div>
        </div>

        <div className='flex items-center flex-row justify-center'>
            <button type="submit" className={`${EditDetails ? " bg-agro_greenlight hover:bg-agro_greenlight/80" :" bg-agro_greenlight hover:bg-agro_greenlight/80 cursor-not-allowed"} p-2 font-semibold text-white text-sm w-56 flex items-center justify-center rounded-md`}>Update</button>
        </div>
              
        <div className={` ${verifiedStatus ? "hidden" : " pointer-events-auto"} flex flex-col items-end justify-end w-full pr-4 mt-2 gap-2`}>
            <button className='bg-agro_darkgreen text-xs text-white p-2 rounded-md flex justify-end' onClick={(e)=>{e.preventDefault(); handleEmailVerificationAfterSignup(); setModel(true)}}> Verify Email</button>
            <span className='text-xs font-bold text-red-500 animate animate-pulse'>Email is not Verified Please Verifiy Your email</span>
        </div>
    </Form>
)}
</Formik>
</div>

{/* when the email verify model is true */}
    {showEmailVerifyModel && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/50 z-50 pointer-events-auto">
           <EmailVerification onClose={() => {navigate("/"); setShowEmailVerifyModel(false);}}/>
        </div>
    )}

    <Alert message={alertProps.message} severity={alertProps.severity}  show={alertProps.show} onClose={hideAlert}/>
</>
)
}

export default ProfileDetails;