import React, { useEffect, useState } from 'react';
import { FaCartPlus } from "react-icons/fa"; 
import { useSelector } from 'react-redux';
import Seeds from '../Assests/FarmerDashboard/seeds.jpg';

function ProductItemCard({ ItemDetails, productAddress, product, onClick }) {
  const userRole = useSelector((state) => state.auth.userRole);
  const [productDetails, setProductDetails] = useState(product || {});
  const [imgPrev, setImgPrev] = useState('');


  useEffect(() => {
    if (product) {
      setProductDetails(product);
    }
  }, [product]);


  useEffect(() => {
    if (ItemDetails?.productImage) {
      const base64String = ItemDetails.productImage; 
      setImgPrev(`data:image/png;base64,${base64String}`); 
    } 
  }, [ItemDetails.productImage,imgPrev]); 



  return (
    <div className="bg-white w-auto p-4 rounded-lg shadow-md text-center relative cursor-pointer">
      <img 
        src={imgPrev ||Seeds} 
        onError={(e) => { e.target.src = Seeds }} 
        className="w-full h-32 object-cover rounded mb-3" 
        alt="Product" 
      />
      <h3 className="text-lg font-semibold ">
        {productDetails?.productName || "No Product"}
      </h3>
      <p className="text-gray-600 text-sm mb-1">{productDetails?.productType || "No Type"}</p>
      <p className="text-gray-600 text-xs">{productAddress || "Unknown Address"}</p>

      <div className={` ${userRole === "supplier" || "admin" ? "justify-center" : "justify-between"} flex flex-row  mt-2 items-center`}>
        <div className={`flex flex-col text-center gap-1 text-sm `}>
          <p className="text-gray-600">Volume: {ItemDetails?.productVolume || "N/A"}</p>
          <p className="text-gray-800 font-semibold">Price: $ {ItemDetails?.productPrice || "N/A"}</p>
        </div>

  
          <button className={`${userRole === "supplier" ? "hidden" : userRole === "admin" ? "hidden" :""} flex flex-grow absolute right-4 bg-green-600 text-white p-2 rounded-full shadow-lg hover:bg-green-700`}
            onClick={onClick}
          >
            <FaCartPlus className="text-lg" />
          </button>

      </div>
    </div>
  );
}

export default ProductItemCard;
