
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function PasswordReset() {
    const navigate = useNavigate();
    const { role, uid, resetToken } = useParams();

    useEffect(() => {
        // Redirect to the login page with state
        navigate("/", {
            state: { role, uid, resetToken, showForgotPassword: true },
        });
    }, [navigate, role, uid, resetToken]);

    return null;
}

export default PasswordReset;

// import React, { useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import CustomAxios from "../../API/CustomAxios";


// function PasswordReset() {
//     const navigate = useNavigate();

//     const API_BASE = process.env.REACT_APP_API_BASE;
//     const {role} = useParams();
//     const {uid} = useParams();
//     const {token} = useParams();

//     useEffect(()=>{
//         navigate("/", {
//             state: { role, uid, token, showForgotPassword: true },
//         });
//     },[navigate, role, uid, token])

//     // const getVerifyPasswordReset =async()=>{
        
//     //     try{
//     //         const response = await CustomAxios({
//     //             method: "GET",
//     //             baseURL: API_BASE,
//     //             url: `user/verify/forgetpassword`,
//     //             data:{

//     //             },
//     //             headers: { 
//     //                 "Content-Type": "application/json"
//     //             },

//     //         })
//     //         if(response.status === 200){
//     //             console.log("succeful password verification")
//     //             navigate("/", { state: { emailVerified: true } });
//     //         }

//     //     }catch(error){
//     //         console.log(error)
//     //     }
//     // }
//     // getVerifyPasswordReset();

//   return (
//    <>
//    </>
//   )
// }

// export default PasswordReset