import React,{useEffect, useState} from 'react'
import Alert from '../../../Components/Alert';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import CustomAxios from '../../../API/CustomAxios';
import Model from '../../../Components/Model';


function AddCultivation({selectedCultivate, setSelectedcultivate, getCultivations}) {
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const token = useSelector((state) => state.auth.ACCESSTOKEN);
    const [model, setModel] = useState(false);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
    const [Seeds, setSeeds] = useState([]);
    const [lands, setLands] = useState([]);


    const validate = Yup.object({
        landid:Yup.string()
        .required("*Required"),
        seed_type:Yup.string()
        .required("*Required"),
        start_date:Yup.string()
        .required("*Required"),
        season:Yup.string()
        .required("*Required"),
        cultivated_landsize:Yup.number()
        .required("*Required")
      });


    //hide alerts
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };


    useEffect(()=>{
       if (selectedCultivate)getLandName();
        getSeedTypes();
        getLands();
    },[])
      

    const getLandName =()=>{

    }


    const getLands =async()=>{
        try{
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_SMARTSCAP,
                url: "getAllLandsByFarmer",
                params:{
                    farmerID:userId
                },
                headers: {
                "Content-Type": "application/json",
                },
            })
            if(response.status === 200){
                setLands(response.data);
            }
        }catch(error){
            setAlertProps({ severity: "error", message: "An Error Occurred", show: true });
        }
    }

    //fetch the types of seed
    const getSeedTypes = async()=>{
        try{
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_NORMALSCAP,
                url: "user/seeds",
                headers: {
                "Content-Type": "application/json",
                token,
                },
            })
            if(response.status === 200){
                setSeeds(response.data.seeds);
            }
        }catch(error){
            setAlertProps({ severity: "error", message: "An Error Occurred", show: true });
        }
    }


    // Main handler function
    const handleCreateCultivate = async (values) => {
        if (selectedCultivate) {
        await updateCultivation(values);
        } else {
        await CreateCultivation(values);
        }
    };


    // Function to create cultivation
    const CreateCultivation = async (values) => {
        const data = {
            farmerid: userId,
            landid: values.landid,
            seed_type: values.seed_type,
            start_date: values.start_date,
            season:values.season,
            cultivated_landsize: values.cultivated_landsize,
        };
        try {
            setModel(true); 
        const response = await CustomAxios({
            method: "POST",
            baseURL: API_BASE_SMARTSCAP,
            url: "createCultivation",
            data:data,
            headers: {
            "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            setModel(false); 
            getCultivations();
            setAlertProps({severity: "success",message: "Cultivation Was Added Successfully!",show: true,});
        }
        } catch (error) {
            setModel(false); 
            setAlertProps({ severity: "error", message: "An Error Occurred", show: true });
        }
    };

  
    // Function to handle land updating
    const updateCultivation = async (values) => {
        // console.log("lan",values.cultivated_landsize)
        const data = {
            farmerid: userId,
            cultivationid:selectedCultivate.cultivationid,
            seedtype: values.seed_type,
            season: values.season,
            startdate: values.start_date,
            cultivated_landsize:values.cultivated_landsize,
            landid:values.landid
        };
        console.log(data);

        try {
            setModel(true); 
        const response = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_SMARTSCAP,
            url: `updateCultivation`,
            data:data,
            headers: {
            "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            setModel(false); 
            setAlertProps({severity: "success",message: "Cultivation updated Successfully!",show: true,});
            setSelectedcultivate(null); 
            getCultivations();
        }
        } catch (error) {
            setModel(false); 
            setAlertProps({ severity: "error", message: "An Error Occurred", show: true });
        }
    };
  
  return (
<div className="bg-white p-6 rounded-lg shadow-md w-full h-full">
    <Model showModel={model}/>
    <h2 className="text-lg font-semibold  text-center mb-6">
      Enter Culitvation Information
    </h2>
    <Formik
        initialValues={{
            landid:selectedCultivate?.landid ||"",
            seed_type:selectedCultivate?.seed_type ||"",
            start_date:selectedCultivate?.start_date||"",
            season:selectedCultivate?.season||"",
            cultivated_landsize:selectedCultivate?.cultivated_landsize ||"",
        }}
        validationSchema={validate}
        onSubmit={handleCreateCultivate}
        enableReinitialize
    >
    {(formik)=>(
        <Form className="flex flex-col gap-4">
        <div className='flex flex-col gap-1'>
                <label className='text-sm text-black/70'>Land</label>
                <Field
                    as="select"
                    id="landid"
                    name="landid"
                    onChange={(e) => {
                        const selectedLandID = e.target.value; // Get the selected land ID
                        formik.setFieldValue("landid", selectedLandID); // Update the landid field
                        const selectedLand = lands.find((land) => land.LandID === selectedLandID); // Find the selected land object
                        if (selectedLand) {
                        formik.setFieldValue("cultivated_landsize", selectedLand.CultivatedSize); // Update cultivated land size
                        }
                    }}
                    value={formik.values.landid}
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    >
                    <option value="" label="Select Land">
                        Select Land
                    </option>
                    {lands.map((land) => (
                        <option key={land.LandID} value={land.LandID}>
                        {land.Location}
                        </option>
                    ))}
                </Field>

            </div>

            <div className='flex flex-col gap-1'>
                <label className='text-sm text-black/70'>Seed Type</label>
                <Field
                    as="select"
                    id="seed_type"
                    name="seed_type"
                    onChange={formik.handleChange}
                    value={formik.values.seed_type}
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    >
                    <option value="" label="Select Seed type">
                        Select Seed type
                    </option>
                    {Seeds.map((seed) => (
                        <option key={seed.seedId} value={seed.seedNumber}> 
                        {seed.seedNumber}
                        </option>
                    ))}
                </Field>

            </div>


            <div className='flex flex-col gap-1'>
                <label className='text-sm text-black/70'>Cultivation Start Date</label>
                <Field
                  type="date"
                  id="start_date"
                  name="start_date"
                  onChange={formik.handleChange}
                  value={formik.values.start_date}
                  className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                />
            </div>

            <div className='flex flex-col gap-1'>
                <label className='text-sm text-black/70'>Season Type</label>
                <Field
                    as="select"
                    id="season"
                    name="season"
                    onChange={formik.handleChange}
                    value={formik.values.season}
                    className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                    >
                    
                    <option value="" label="Select Season">Select Season</option>
                    <option value="Yala">Yala</option>
                    <option value="Maha">Maha</option>
                </Field>
            </div>

            
            <div className='flex flex-col gap-1'>
                <label className='text-sm text-black/70'>Cultivated land Size(acres)</label>
                <Field
                  type="number"
                  id="cultivated_landsize"
                  name="cultivated_landsize"
                  onChange={formik.handleChange}
                  value={formik.values.cultivated_landsize}
                  className="border w-full focus:outline-none p-3 text-[12px] text-black/50 rounded-lg"
                />
            </div>

            <div className='flex items-center justify-center'>
                <button
                    type="submit"
                    className='bg-agro_darkgreen text-white  text-xs py-2 rounded-md w-full text-center'
                >
                   {selectedCultivate ? 'Update' : 'Submit'}
                </button>  
            </div>

        </Form>
    )}
    </Formik>

    <Alert
      message={alertProps.message}
      severity={alertProps.severity}
      show={alertProps.show}
      onClose={hideAlert}
   />
</div>
  )
}

export default AddCultivation