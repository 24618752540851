import React, { useState } from 'react';
import CustomAxios from '../../../API/CustomAxios';
import HarvestList from './HarvestList'; // Import HarvestList component directly
import Model from '../../../Components/Model';
import {API_BASE_SMARTSCAP} from '../../../API/APIUrls';


function HarvestForm({ cultivate, closeForm, refreshCultivations }) {
  const [yieldAmount, setYieldAmount] = useState('');
  const [harvestDate, setHarvestDate] = useState('');
  const [model, setModel] = useState(false);
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
  const { cultivationid, farmerid, landid} = cultivate;  // Extract farmerid from the props

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!yieldAmount || !harvestDate) {
      setAlertProps({ message: 'Please fill in all fields.', severity: 'error', show: true });
      return;
    }

    try {
      setModel(true); 
      const response = await CustomAxios({
        method: 'POST',
        baseURL: API_BASE_SMARTSCAP,
        url: 'createHarvasting',
        data: {
          farmerid,
          landid,
          cultivationid,
          yield: parseFloat(yieldAmount),
          harvesting_date: harvestDate,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setModel(false); 
        setAlertProps({ message: 'Harvest added successfully!', severity: 'success', show: true });
        refreshCultivations();
        console.log("response", response.data);
        closeForm();
      }
    } catch (error) {
      setModel(false); 
      closeForm();

    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <Model showModel={model}/>
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold mb-4">Add Harvest</h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <label>
            Yield:
            <input
              type="number"
              value={yieldAmount}
              onChange={(e) => setYieldAmount(e.target.value)}
              className="p-2 border rounded-md w-full"
              required
            />
          </label>
          <label>
            Harvesting Date:
            <input
              type="date"
              value={harvestDate}
              onChange={(e) => setHarvestDate(e.target.value)}
              className="p-2 border rounded-md w-full"
              required
            />
          </label>
          <div className="flex justify-between gap-4">
            <button type="submit" className="bg-green-500 py-2 px-4 rounded-md text-white">Submit</button>
            <button type="button" onClick={closeForm} className="bg-red-500 py-2 px-4 rounded-md text-white">Cancel</button>
          </div>
        </form>

        {alertProps.show && (
          <div className={`alert ${alertProps.severity}`}>
            {alertProps.message}
          </div>
        )}
      </div>
    </div>
  );
}

export default HarvestForm;
