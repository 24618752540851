// store.js
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import signupReducer from './signupSlice';
import authReducer from './authSlice';
import cartReducer from './cartSlice';
import headerContentReducer from './headercontentSlice';
import availabilityReducer from './availabilitySlice';
import logoutReducer from './logoutSlice';
import cultivationReducer from './cultivationSlice'; 

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  signup: signupReducer,
  auth: authReducer,
  cart: cartReducer,
  headercontent: headerContentReducer,
  availability: availabilityReducer,
  logout: logoutReducer,
  cultivation: cultivationReducer,  
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
