import axios from "axios"; 
import { store } from "../Pages/ReduxManagement/store";
import { API_BASE_NORMALSCAP } from "./APIUrls";
import { setAccessToken } from "../Pages/ReduxManagement/authSlice";
import { handleLogout } from "../Pages/ReduxManagement/logoutSlice";

const onLogout =()=>{
  store.dispatch(handleLogout());
  window.location.replace("/");
}
const CustomAxios = axios.create({});
CustomAxios.interceptors.request.use(
  (req) => {
    const accessToken = store.getState().auth.ACCESSTOKEN;
    if (accessToken) {
      req.headers["token"] = accessToken;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

CustomAxios.interceptors.response.use(
  (res) => {
    return res; // Return the response object unchanged
  },
  async (err) => {
    const originalReq = err.config; // Get the original request
    const status = err.response ? err.response.status : null; // Get the status code

    if (status === 419 || status === 401) {
      const refreshToken = store.getState().auth.REFRESHTOKEN;

      return axios({
        method: "POST",
        baseURL: API_BASE_NORMALSCAP,
        url: "user/token/renew",
        data: {
          refresh_token: refreshToken,
        },
      })
        .then((response) => {
          store.dispatch(setAccessToken(response.data.token.access_token));
          return CustomAxios(originalReq);
        })
        .catch((error) => {
          const errorStatus = error.response ? error.response.status : null;
          const errorMessage = error.response?.data?.details;
          console.log("erromes", errorMessage);
          console.log("erromes", errorStatus);

          // Check if the status is 500 and the error message matches
          if (errorStatus === 500 && errorMessage === "error verifying token: Token is expired") {
            // store.dispatch(handleLogout());
            onLogout();
            return Promise.reject(
              new Error("User has been logged out due to token expiry.")
            );
          }

          return Promise.reject(error); // Handle other errors
        });
    } else if (status === 403) {
      alert("Forbidden access");
    } else if (status === 404) {
      return Promise.reject(err);
    } else if (status === 500) {
      return Promise.reject(err);
    }
  }
);

export default CustomAxios;
