import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSignupKey: false,
  username:'',
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    showSignupKey: (state) => {
      state.showSignupKey = true;
    },
    hideSignupKey: (state) => {
      state.showSignupKey = false;
    },
    setSignupUsername:(state, action)=>{
      state.username = action.payload;
    },
    clearSignup:(state)=>{
      state.showSignupKey = initialState.showSignupKey;
      state.username = initialState.username;
    },
  },
});

export const { showSignupKey, hideSignupKey, setSignupUsername, clearSignup } = signupSlice.actions;

export default signupSlice.reducer;
