import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import deleteimg from '../../Assests/delete.png';
import edit from '../../Assests/edit.png';
import correct from "../../Assests/correct.png";
import { GoDotFill } from "react-icons/go";
import CustomAxios from '../../API/CustomAxios';
import { useSelector } from 'react-redux';
import Alert from '../../Components/Alert';
import {API_BASE_SMARTAJAP,API_BASE_NORMALSCAP} from '../../API/APIUrls';


function FarmersUserList() {
    const token = useSelector((state)=>state.auth.ACCESSTOKEN);
    const [displayList, setDisplayList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedStatus, setEditedStatus] = useState(null); 
    const [selectedUser, setSelectedUser] = useState("");
    const [showdDeleteModel, setshowdDeleteModel] = useState(false);
    const [selectedStatus, setSlectedStatus] = useState("both")
    const [alertProps, setAlertProps] = useState({ message: '', severity: 'success', show: false });


    useEffect(() => {
        getFarmerUserList();
    }, [selectedStatus]);


    // Hide alerts
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };


    //fetch farmer user list
    const getFarmerUserList = async()=>{
        try{
            const response = await CustomAxios({
                method:"GET",
                baseURL:API_BASE_NORMALSCAP,
                url:`allfarmers/${selectedStatus}`,
                headers:{
                  "Content-Type":"application/json",
                  "token": token,
                },
            })

            if(response.status === 200){
                setDisplayList(response.data.users || []);
            }
        }catch(error){
            console.log(error);
            setDisplayList([]);
        }
    }

    // Handle status change
    const handleStatusChange = (index, newStatus) => {
        setEditedStatus(newStatus); 
    };

    // Handle farmer update by calling the endpoint
    const handleFarmerUpdate = async () => {
        try {
            const response = await CustomAxios({
                method:"PUT",
                baseURL:API_BASE_SMARTAJAP,
                url:"api2/v2/suppliers",
                data: editedStatus,
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                const updatedList = displayList.map((user, id) => id === editingIndex ? {...user, status:editedStatus} : user);
                setDisplayList(updatedList);
                setEditingIndex(null);
            }
        } catch (error) {
            console.error("Error updating supplier:", error);
        }
        setEditingIndex(null);
    };

    // Show the delete model
    const handleShowDeleteModel = (Supplieruser) => {
        setshowdDeleteModel(true);
        setSelectedUser(Supplieruser);
    };


    //handle the supplier delete
    const handledeleteFarmer = async () => {
        try {
            const response = await CustomAxios({
                method: "PUT",
                baseURL: API_BASE_NORMALSCAP,
                url: `user/updateActivationStatus/${selectedUser.uid}`,
                data: {
                    status: "deactivate",
                },
                headers: {
                    "Content-Type": "application/json",
                    token: token,
                },
            });
    
            if (response.status === 200) {
                setAlertProps({severity: "success",message: "Farmer was Deleted Successfully!",show: true,});
                getFarmerUserList();
                setshowdDeleteModel(false);
            } else {
                setshowdDeleteModel(false);
                setAlertProps({severity: "error",message: response.data.error || "An error occurred",show: true,});
            }
        } catch (error) {
            setshowdDeleteModel(false);
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            setAlertProps({severity: "error",message: errorMessage,show: true,});
        }
    };
    


    //handle filter users
    const handleFIlterUsers = (event)=>{
        setSlectedStatus(event.target.value);
        setPage(0);
    }
    
    //handle the page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //handle the rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
    <>
    <div className='flex flex-col rounded-lg mt-4'>
        <div className='flex flex-col'>
            <div className='flex flex-row justify-between items-center mb-2'>
                <select className='rounded-md p-2 text-sm focus:outline-none' onChange={handleFIlterUsers}>
                    <option value="both" className='text-xs'>Filter By</option>
                    <option value="activate" className='text-xs'>Active</option>
                    <option value='deactivate' className='text-xs'>Deactive</option>
                </select>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[2, 10, 25, 50]}
                    page={page}
                    count={displayList.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

            
        <div className='overflow-x-auto rounded-lg bg-white'>
            <table className='min-w-full rounded-lg'>
                <thead className='bg-agro_lightgreen/60 text-black/70 text-left'>
                    <tr> 
                        <th className='px-6 py-3 text-xs'>No</th>
                        <th className='px-6 py-3 text-xs'>Name</th>
                        <th className='px-6 py-3 text-xs'>Username</th>
                        <th className='px-6 py-3 text-xs'>Email</th>
                        <th className='px-6 py-3 text-xs'>Status</th>
                        <th className='px-6 py-3 text-xs'>Verification</th>
                        <th className='px-6 py-3 text-xs'>Phone No</th>
                        <th className={`px-6 py-3 text-xs ${selectedStatus === "both" ? 'hidden' : selectedStatus === "deactivate" ? "hidden" : ""}`}>Action</th>
                    </tr>
                </thead>
                
                <tbody>
                {displayList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) =>
                    <tr key={index} className='text-xs font-normal border border-b-agro_lightgreen/50'>
                        <td className='px-6 py-3'>{page * rowsPerPage + index + 1}</td>
                        <td className='px-6 py-3'>{user.name}</td>
                        <td className='px-6 py-3'>{user.username}</td>
                        <td className='px-6 py-3'>{user.email}</td>
                        <td className='px-6 py-3'>{user.status}
                            {/* <select
                                value={editingIndex === index ? editedStatus:user.status}
                                disabled={editingIndex !== index}
                                onChange={(e) => handleStatusChange(index, e.target.value)}
                                className={`${editingIndex === index ? "bg-agro_lightgreen/60" : "bg-agro_lightgreen/10"} p-1 rounded-md`}
                            >
                                <option value="activate">activate</option>
                                <option value="deactive">deactivate</option>
                            </select> */}
                        </td>
                        <td className='px-6 py-3'>{user.isVerified ? "Verified": "Not Verified"}
                            {/* <select
                                value={user.verify}
                                onChange={(event) => handleselectVerification(event, page * rowsPerPage + index)}
                                className={`${editRowIndex === page * rowsPerPage + index ? "bg-white p-1 rounded-md" : "bg-agro_lightgreen/10 p-1"}`}
                            >
                                <option value="verify">verify</option>
                                <option value="notverify">Notverify</option>
                            </select> */}
                        </td>                    
                        <td className='px-6 py-3'>{user.contactno}</td>
                        <td className={`px-6 py-3 flex flex-row ${selectedStatus === "both" ? 'hidden' : selectedStatus === "deactivate" ? "hidden" : ""}`}>
                            {/* <button 
                                className={`${editingIndex === index ? "hidden" : "block"}`} 
                                onClick={() => {setEditingIndex(index); setEditedStatus(user.status);}}
                            >
                               <img src={edit} className='w-5 h-5' alt="Edit" />
                            </button> */}
                            {/* allowing to click to update once edit icon is clicked */}
                            {/* {editingIndex === index &&
                                <button onClick={handleFarmerUpdate}>
                                    <img src={correct} className="w-5 h-5" alt="Confirm" />
                                </button>
                            } */}
                            {/* shows the delete model */}
                            <button onClick={() => handleShowDeleteModel(user)} className={`${selectedStatus === "both" ? 'hidden' : selectedStatus === "deactivate" ? "hidden" : ""}`}>
                                <img src={deleteimg} className='w-5 h-5' alt="Delete" />
                            </button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        </div>
    </div>


     {/* show the delete model */}
     {showdDeleteModel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4">
                <h1 className='font-semibold text-agro_darkgreen text-lg'>Confirm Delete</h1>
                <h1 className='text-sm mb-4 text-center'>Are you sure you want to delete <span className='font-semibold text-md'>{selectedUser.name}</span>?</h1>
                <div className='flex flex-row w-full justify-between gap-2 text-white text-sm font-semibold'>
                    <button
                        className='bg-agro_greenlight py-2 w-full rounded-md px-4'
                        onClick={handledeleteFarmer}
                    >
                        Delete
                    </button>
                    <button
                        className='bg-red-500 w-full py-2 rounded-md px-4'
                        onClick={() => setshowdDeleteModel(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )}

    <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
        />  
        </>
    );
}

export default FarmersUserList;
