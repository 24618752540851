// import React, { useEffect, useState } from 'react';
// import CustomAxios from '../../../API/CustomAxios';
// import { useSelector } from 'react-redux'; // Import useSelector to access the store

// function HarvestList() {
//   const [harvests, setHarvests] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;

//   const farmerId = useSelector((state) => state.auth.userID);

//   useEffect(() => {
//     if (farmerId) {
//       const fetchHarvestData = async () => {
//         try {
//           const response = await CustomAxios({
//             method: 'GET',
//             baseURL: API_BASE_SMARTSCAP,
//             url: `getharvestingsByFarmer?farmerID=${farmerId}`,
//           });

//           if (response.status === 200) {
//             setHarvests(response.data.Harvesting);
//           }
//         } catch (err) {
//           setError('Failed to fetch harvest data.');
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchHarvestData();
//     }
//   }, [farmerId]); // Trigger the effect whenever farmerId changes

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div className="bg-white p-6 rounded-lg shadow-lg w-full mt-4 overflow-hidden">
//     <table className="min-w-full table-auto border-separate border border-agro_greenlight overflow-hidden">
//       <thead className="bg-agro_greenlight">
//         <tr>
//           <th className="px-6 py-3 text-left text-sm font-semibold text-white">Harvest ID</th>
//           <th className="px-6 py-3 text-left text-sm font-semibold text-white">Cultivation ID</th>
//           <th className="px-6 py-3 text-left text-sm font-semibold text-white">Yield</th>
//           <th className="px-6 py-3 text-left text-sm font-semibold text-white">Harvesting Date</th>
//         </tr>
//       </thead>
//       <tbody className="bg-white">
//         {harvests.map((harvest) => (
//           <tr key={harvest.harvestingid} className="hover:bg-green-50 border-b border-gray-200">
//             <td className="px-6 py-4 text-sm text-gray-700">{harvest.harvestingid}</td>
//             <td className="px-6 py-4 text-sm text-gray-700">{harvest.cultivationid}</td>
//             <td className="px-6 py-4 text-sm text-gray-700">{harvest.yield}</td>
//             <td className="px-6 py-4 text-sm text-gray-700">{harvest.harvesting_date}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>
  
//   );
// }

// export default HarvestList;




import React, { useEffect, useState } from 'react';
import CustomAxios from '../../../API/CustomAxios';
import { useSelector } from 'react-redux';

function HarvestList() {
  const [tableData, setTableData] = useState([]); // State to store enriched table data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = useSelector((state) =>state.auth.userID);
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;

  const farmerId = useSelector((state) => state.auth.userID);

  useEffect(() => {
    const fetchData = async () => {
      if (!farmerId) return;
      try {
        // Fetch harvest data
        const harvestResponse = await CustomAxios({
          method: 'GET',
          baseURL: API_BASE_SMARTSCAP,
          url: `getharvestingsByFarmer?farmerID=${farmerId}`,
        });

        if (harvestResponse.status === 200) {
          const harvestData = harvestResponse.data.Harvesting;

          // Enrich harvest data with land and cultivation details
          const enrichedData = await Promise.all(
            harvestData.map(async (harvest) => {
              const landDetails = await fetchLandDetails(harvest.landid);
              const cultivationDetails = await fetchcultivationDetails(harvest.cultivationid);

              return {
                ...harvest,
                landDetails,
                cultivationDetails,
              };
            })
          );

          setTableData(enrichedData);
        }
      } catch (err) {
        setError('Failed to fetch table data.');
      } finally {
        setLoading(false);
      }
    };

    // Helper function to fetch additional details
  const fetchLandDetails =async(landid)=>{
    try{
      const response = await CustomAxios({
          method:"GET",
          baseURL:API_BASE_SMARTSCAP,
          url:"readLandByID",
          params:{
              id:landid
          },
          headers:{
          "Content-Type":"application/json",
          },
      })
      if(response.status === 200){
         return response.data.Location
      }
    }catch(erorr){
        console.log(error);
    }
  }


  const fetchcultivationDetails =async(cultivationid)=>{
      try{
        const response = await CustomAxios({
            method:"GET",
            baseURL:API_BASE_SMARTSCAP,
            url:"getcultivationByFarmerByCultId",
            params:{
              farmerID:userId,
              cultivationID:cultivationid,
            },
            headers:{
            "Content-Type":"application/json",
            },
        })
        if(response.status === 200){
          return response.data?.cultivations[0]?.cultivated_landsize
        }
      }catch(erorr){
          console.log(error);
      }
  }
    fetchData();
  }, [farmerId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full mt-4 overflow-hidden">
      <table className="min-w-full table-auto border-separate border border-agro_greenlight overflow-hidden">
        <thead className="bg-agro_greenlight">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-semibold text-white">Harvesting Date</th>
            {/* <th className="px-6 py-3 text-left text-sm font-semibold text-white">Harvest ID</th> */}
            <th className="px-6 py-3 text-left text-sm font-semibold text-white">Yield</th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-white">Land Details</th>
            <th className="px-6 py-3 text-left text-sm font-semibold text-white">Cultivation Details</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {tableData.map((data) => (
            <tr key={data.harvestingid} className="hover:bg-green-50 border-b border-gray-200">
              <td className="px-6 py-4 text-sm text-gray-700">{data.harvesting_date}</td>
              {/* <td className="px-6 py-4 text-sm text-gray-700">{data.harvestingid}</td> */}
              <td className="px-6 py-4 text-sm text-gray-700">{data.yield}</td>
              <td className="px-6 py-4 text-sm text-gray-700">
                {data.landDetails}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700">
                  {data.cultivationDetails}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HarvestList;


