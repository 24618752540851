import React,{useState, useEffect} from 'react'
import SupplierDashboard from './Supplier/SupplierDashboard'
import FarmerProfile from './Farmer/FarmerProfile'
import AdminDashboard from './Admin/AdminDashboard'
import FarmerDashboard from './Farmer/FarmerDashboard'
import { useDispatch, useSelector } from 'react-redux'
import CustomAxios from '../../API/CustomAxios'
import { SetBrandName } from '../ReduxManagement/authSlice'
import {API_BASE_NORMALSCAP} from '../../API/APIUrls'


function DashboardContent() {
//  const userRole="supplier";
  const dispatch = useDispatch();
  const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));
  const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  

  useEffect(()=>{
    if(userRole === "supplier"){
      getUserdetails()
    }
  },[])
  

const getUserdetails = async()=>{
  try{
    const response = await CustomAxios({
      method: "GET",
      baseURL: API_BASE_NORMALSCAP,
      url: `supplier/${userId}`,
      headers: {
        "Content-Type": "application/json",
        "token": token,
      },
    })
    if(response.status === 200){
      const data =response.data.supplier.brandName;
      dispatch(SetBrandName(data))
    }
  }catch(error){
    console.log(error);
  }
}
  return (
    <div className='flex flex-col' >
        {userRole  === "farmer" && (
            <FarmerDashboard />
        )}

        {userRole === "supplier" && (
            <SupplierDashboard />
        )}

        {userRole === "admin" && (
            <AdminDashboard />
        )}
    </div>
  )
}

export default DashboardContent