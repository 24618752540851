// redux/cultivationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cultivationData: null, // Store cultivation details
};

const cultivationSlice = createSlice({
  name: 'cultivation',
  initialState,
  reducers: {
    setCultivationData: (state, action) => {
      state.cultivationData = action.payload;
    },
    updateCultivationData: (state, action) => {
      state.cultivationData = { ...state.cultivationData, ...action.payload };
    },
  },
});

export const { setCultivationData, updateCultivationData } = cultivationSlice.actions;
export default cultivationSlice.reducer;
