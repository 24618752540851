import React, { useState } from 'react';
import logo from '../../Assests/sidebarlogo.png';
import Button from '../../Components/Button';
import { Link, useNavigate } from 'react-router-dom';
import dashboardImg from '../../Assests/sidebar/dashboard.png';
import hoverDashboardImg from '../../Assests/sidebar/hoverdashboard.png';
import stockManage from '../../Assests/sidebar/stockManage.png';
import hoverStockManage from '../../Assests/sidebar/hoverStockmanage.png';
import pastOrders from '../../Assests/sidebar/pastOrders.png';
import hoverpastOrders from '../../Assests/sidebar/hoverpastOrders.png';
import profile from '../../Assests/sidebar/profile.png';
import hoverprofile from '../../Assests/sidebar/hoverprofile.png';
import products from "../../Assests/sidebar/products.png";
import hoverproducts from '../../Assests/sidebar/hoverproducts.png';
import cultivate from '../../Assests/sidebar/cultivate.png';
import myorders from '../../Assests/sidebar/myorders.png';
import hovermyorders from '../../Assests/sidebar/hovermyorders.png';
import hovercultivate from '../../Assests/sidebar/hovercultivate.png';
import userlist from '../../Assests/sidebar/userList.png';
import hoveruserlist from '../../Assests/sidebar/hoveruserlist.png';
import menu from '../../Assests/sidebar/menu.png';
import cart from '../../Assests/sidebar/cart.png';
import hovercart from "../../Assests/sidebar/hovercart.png";
import seeds from "../../Assests/sidebar/seed.png";
import hoverSeeds from '../../Assests/sidebar/hoverseed.png';
import logout from '../../Assests/sidebar/logout.png';
import hoverlogout from '../../Assests/sidebar/hoverlogout.png';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderContent } from '../ReduxManagement/headercontentSlice';
import { handleLogout } from '../ReduxManagement/logoutSlice';


function Sidebar({setShowSidebar }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLandOpen, setIsLandOpen] =useState(false);
  const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));
  // const userRole = "farmer";


  const handleSidebarToggle = (content) => {
    setShowSidebar(false);
    dispatch(setHeaderContent(content))
   // Update header content on click
  };

  return (
    <div className='flex flex-col  sm:w-[240px] bg-agro_darkgreen rounded-l-lg h-full mob2:w-full '>
      <div className='flex flex-row w-full gap-3 items-center bg-agro_darkgreen rounded-tl-lg  p-2  mob2:justify-center sm:justify-start'>
        <div className='flex sm:w-full mob2:h-18 mob2:w-2/3 mob2:justify-center flex-row sm:justify-start pt-2 pr-8 pl-2 cursor-pointer' onClick={()=>navigate("/dashboard")}>
          <img src={logo} className='sm:w-full sm:h-18 mob2:h-18 mob2:w-full '/>
          {/* <h1 className='font-bold lg:text-[18px] text-[17px] text-center tracking-wide text-agro_darkyellow'>HARVEST HUB</h1> */}
        </div>
        <button 
          className='lg:hidden'
          onClick={()=>setShowSidebar(false)}
        >
          <img src={menu} className='w-5 h-5 mob2:w-8 mob2:h-6'/>
        </button>     
      </div>
    
    <div className='flex flex-col justify-between h-full mx-4 w-full sm:items-start mob2:items-center'>
      <div className='flex flex-col gap-4 mt-8'>

      {/* navigate to the dashboard */}
      <Link to="/dashboard" onClick={() => handleSidebarToggle("Dashboard")}>
          <Button
            btnName="DASHBOARD"
            btnImg={dashboardImg}
            hoverImg={hoverDashboardImg}
          />
        </Link>


        {/* navigate to view all productst */}
        <Link to="/dashboard/products" onClick={() => handleSidebarToggle("Marketplace")}>
          <Button
            btnName="MARKETPLACE"
            btnImg={hoverproducts}
            hoverImg ={products}
          />
        </Link>


      {/* when the user is a supplier */}
      {userRole === "supplier" && (
        <>
        {/* navigate to stock management */}
        <Link to="/dashboard/stockManage" onClick={() => handleSidebarToggle("Stock Management")}>
          <Button
            btnName="MANAGE STOCK"
            btnImg={stockManage}
            hoverImg={hoverStockManage}
          />
        </Link>

        {/* navigate to view past orders for supplier */}
        <Link to="/dashboard/pastOrders" onClick={() =>{ handleSidebarToggle("Past Orders"); }}>
          <Button
            btnName="PAST ORDERS"
            btnImg={hoverpastOrders}
            hoverImg={pastOrders}
          />
        </Link>

        {/* navigate to view past orders for supplier */}
        {/* <Link to="/dashboard/createProduct" onClick={() => handleSidebarToggle("Product Creation")}>
          <Button
            btnName="CREATE PRODUCT"
            btnImg={productCreation}
            hoverImg={hoverProductCreation}
          />
        </Link> */}

        {/* navigate to supplier profile */}
        <Link to ="/dashboard/profile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="PROFILE"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link>

        {/* <Link to ="/dashboard/newprofile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="PROFILE"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link> */}
{/*         
        <Link to ="/dashboard/profile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="prof"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link>         */}
        </>
        )}


      {/* When the user is a farmer */}
      {userRole === "farmer" && (
        <>
        {/* navigate to farmer dashboard */}
        <Link to="/dashboard/cart" onClick={() => handleSidebarToggle("Cart")}>
          <Button
           btnName="MY CART"
           btnImg={cart}
           hoverImg={hovercart}
            
          />
        </Link>

        <Link to="/dashboard/myorders" onClick={() => handleSidebarToggle("My Orders")}>
          <Button
              btnName="ORDER HISTORY"
              btnImg={myorders}
              hoverImg={hovermyorders}
          />
        </Link>

     {/* handle land/cultivation and harvest*/}
        <div className=''>
            <Link to="/dashboard/land" onMouseEnter={()=>setIsLandOpen(!isLandOpen)} onClick={() => {
              setIsLandOpen(!isLandOpen)
              handleSidebarToggle("Land")
              }}>
              <Button
                btnName="LAND"
                btnImg={hovercultivate}
                hoverImg={cultivate}
              />
            </Link>

            {isLandOpen && (
              <div className='ml-12 flex flex-col'>
                <Link 
                  to="/dashboard/cultivate" 
                  onClick={() => handleSidebarToggle("Cultivation")}
                >
                      <button
                          className='text-white text-start text-xs tracking-wider font-semibold rounded-md w-28 hover:text-agro_darkgreen hover:bg-agro_light/60 px-4 py-2' 
                      >
                        CULTIVATION
                      </button>
                    </Link>

                    {/* Sub-buttons for Harvest */}
                    <Link 
                      to="/dashboard/harvest" 
                      onClick={() => handleSidebarToggle("Harvest")}
                    >
                      <button
                          className='text-white text-start text-xs tracking-wider font-semibold rounded-md w-28 hover:text-agro_darkgreen hover:bg-agro_light/60 px-4 py-2' 
                      >
                        HARVEST
                      </button>
                    </Link>
              </div>
            )}
        </div>



        {/* navigate to supplier profile */}
        {/* <Link to ="/dashboard/newprofile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="PROFILE"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link> */}

      <Link to ="/dashboard/profile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="PROFILE"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link> 
      
        </>
        )}


        {/* when the user is an admin */}
        {userRole === "admin" && (
        <>
        
        {/* handle seeds */}
        <Link to ="/dashboard/seeds" onClick={() => handleSidebarToggle("Seeds")}>
          <Button
            btnName="SEEDS"
            btnImg={seeds}
            hoverImg={hoverSeeds}
          />
        </Link> 


        {/* handle users */}
        <Link to ="/dashboard/createuser" onClick={() => handleSidebarToggle("Users")}>
          <Button
            btnName="CREATE USER"
            btnImg={hoveruserlist}
            hoverImg={userlist}
          />
        </Link> 


        <Link to ="/dashboard/profile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="PROFILE"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link>

        {/* <Link to ="/dashboard/newprofile" onClick={() => handleSidebarToggle("Profile")}>
          <Button
            btnName="PROFILE"
            btnImg={hoverprofile}
            hoverImg={profile}
          />
        </Link>          */}
        </>
        )}
      </div>


      {/* Username display section */}
      {/* <div className='flex max-w-full flex-row items-center p-2 relative sm:justify-start mob2:justify-center '>
        <div className='flex flex-row items-center gap-3 p-2'>
          <img src={noprofile} className='w-8 h-8 object-cover border-2 rounded-full border-white' alt='profile'/>
          <h3 className='text-xs font-semibold text-white truncate'>{username}</h3>
        </div>
        <button
          onClick={() => setLogoutClick(!logoutClick)}
          className='text-white font-bold'
        >
          :
        </button>3

        {logoutClick && (
          <button 
            className='absolute bg-agro_lightgreen/60 right-2 top-[-12px] px-3 rounded-md'
            onClick={handleLogout}
          >
            <h3 className='text-sm text-agro_green font-semibold py-1'>Logout</h3>
          </button>
        )}
      </div> */}

        <div className="mb-4">
          {/* <Link to ="/dashboard/contact" onClick={() => handleSidebarToggle("Contact")}>
            <Button
              btnName="CONTACT US"
              btnImg={contactus}
              hoverImg={hoverContactus}
            />
          </Link> */}

          <Link to ="/" onClick={() => { dispatch(handleLogout()); navigate("/"); handleSidebarToggle("")}}>
              <Button
                btnName="LOGOUT"
                btnImg={logout}
                hoverImg={hoverlogout}
              />
           </Link>
        </div>
              

    </div>
  </div>
  )
}

export default Sidebar