import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showBlur: false,
};

export const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setShowBlur: (state,action) => {
      state.showBlur = action.payload;
    },
    setHideBlur: (state,action) => {
      state.showBlur = action.payload;
    },
    clearAvailability:(state)=>{
      state.showBlur = initialState.showBlur;
    }
  },
});

export const { setShowBlur, setHideBlur,clearAvailability } = availabilitySlice.actions;

export default availabilitySlice.reducer;
