import React from 'react';
import homeimg from '../Assests/homeimg.jpg';
import logo from '../Assests/homeLogo.png';

function WelcomeCard({ comp }) {
  return (
    <div className="h-screen w-full bg-agro_darkgreen flex justify-center items-center">
      <div className="relative bg-white grid md:grid-cols-2 mob2:grid-cols-1 sm:rounded-lg  sm:h-[90vh] mob2:h-full lg:w-[950px] md:w-[800px] sm:w-[580px] mob2:w-full">
        {/* Left Image Section */}
        <div className="w-full col-span-1 md:w-full h-[90vh] py-3 pl-3 mob2:hidden md:block">
          <img src={homeimg} className="w-full h-full object-cover rounded-lg" alt="Welcome"/>
        </div>

        {/* Right Content Section */}
        <div className="w-full col-span-1 flex flex-col justify-between p-6 h-full overflow-y-auto no-scrollbar">
          {/* Logo Section */}
          <div className="w-full flex flex-row gap-3 mob2:justify-center">
            <img src={logo} className="w-auto h-20  " alt="Logo" />
            {/* <h1 className="mob:text-3xl font-bold text-agro_darkgreen mob2:text-center mob2:text-2xl">HARVEST HUB</h1> */}
          </div>

          {/* Injected Component (Form) */}
          <div className="flex-grow flex justify-center items-center h-full">{comp}</div> 
        </div>
      </div>
    </div>
  );
}

export default WelcomeCard;
