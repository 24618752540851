import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import CustomAxios from '../../../API/CustomAxios';
import { useSelector } from 'react-redux';
import { API_BASE_SMARTSCAP } from '../../../API/APIUrls';

function CurrentOrderList() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pendingOrderItems, setPendingOrderItems] = useState([]); // Store only pending items
    const [selectedDate, setSelectedDate] = useState('');
    const userId = useSelector((state) => state.auth.userID);

    useEffect(() => {
        getCurrentOrders();
    }, []);

    const getCurrentOrders = async () => {
        try {
            const response = await CustomAxios({
                method: 'GET',
                baseURL: API_BASE_SMARTSCAP,
                url: 'getOrdersBySupplier',
                params: {
                    supplierId: userId,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                // Filter and map pending order items
                const pendingItems = response.data.orders.flatMap((order) =>
                    order.order_items.filter((item) => item.orderStatus === 'Pending').map((item) => ({
                        orderId: order.orderid,
                        startDate: order.order_startdate,
                        endDate: order.order_enddate,
                        farmerId: order.farmerid,
                        orderItemId: item.orderItemId,
                        productName: item.productName,
                        supplierTotalPrice: item.supplierTotalPrice,
                    }))
                );

                setPendingOrderItems(pendingItems);
            }
        } catch (error) {
            console.error('Error fetching current orders:', error);
        }
    };

    // Extract unique dates for the select dropdown
    const uniqueDates = [...new Set(pendingOrderItems.map((item) => item.startDate))];

    // Handle date selection
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        setPage(0);
    };

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Filter by selected date
    const filteredOrderItems = selectedDate
        ? pendingOrderItems.filter((item) => item.startDate === selectedDate)
        : pendingOrderItems;

    return (
        <div className='flex flex-col bg-white shadow-md border-none border-agro_green/20 rounded-md border p-4'>
            <h1>CURRENT ORDERS</h1>

            <div className='flex flex-col rounded-lg'>
                <div className='flex sm:flex-row justify-between my-3 sm:items-center mob2:flex-col mob2:items-end'>
                    {/* Dropdown for selecting date */}
                    <div className='border rounded-md w-auto h-full'>
                        <select
                            value={selectedDate}
                            onChange={handleDateChange}
                            className='p-2 flex rounded-lg focus:outline-none flex-col items-center justify-center w-full text-[12px]'
                        >
                            <option value=''>Sort By Date</option>
                            {uniqueDates.map((date, index) => (
                                <option key={index} value={date}>
                                    {date}
                                </option>
                            ))}
                        </select>
                    </div>

                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[2, 10, 25, 50]}
                        page={page}
                        count={filteredOrderItems.length}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '@media (max-width: 640px)': {
                                '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                    fontSize: '10px',
                                },
                                fontSize: '10px',
                            },
                            fontSize: '14px',
                        }}
                    />
                </div>

                {/* Table Content */}
                <div className='overflow-x-auto rounded-lg'>
                    <table className='min-w-full rounded-lg'>
                        <thead className='bg-agro_lightgreen text-black/70 text-left'>
                            <tr>
                                <th className='px-6 py-3 text-xs'>No</th>
                                {/* <th className='px-6 py-3 text-xs'>Order ID</th> */}
                                <th className='px-6 py-3 text-xs'>Order Item ID</th>
                                <th className='px-6 py-3 text-xs'>Start Date</th>
                                <th className='px-6 py-3 text-xs'>End Date</th>
                                <th className='px-6 py-3 text-xs'>Farmer ID</th>
                                <th className='px-6 py-3 text-xs'>Product Name</th>
                                <th className='px-6 py-3 text-xs'>Total Price</th>
                                <th className='px-6 py-3 text-xs'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredOrderItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                <tr key={index} className='text-xs font-normal border border-b-agro_green/20'>
                                    <td className='px-6 py-3'>{page * rowsPerPage + index + 1}</td>
                                    {/* <td className='px-6 py-3'>{item.orderId}</td> */}
                                    <td className='px-6 py-3'>{item.orderItemId}</td>
                                    <td className='px-6 py-3'>{item.startDate}</td>
                                    <td className='px-6 py-3'>{item.endDate}</td>
                                    <td className='px-6 py-3'>{item.farmerId}</td>
                                    <td className='px-6 py-3'>{item.productName}</td>
                                    <td className='px-6 py-3'>{item.supplierTotalPrice}</td>
                                    <td className='px-6 py-3'>
                                        <button
                                            className='px-4 py-2 rounded-md text-black/60 font-semibold bg-agro_lightgreen hover:bg-agro_lightgreen/60'
                                            onClick={() => navigate(`/dashboard/orderDetails/${item.orderId}`, { state: { orderItemId: item.orderItemId } })}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CurrentOrderList;
