import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomAxios from "../../API/CustomAxios";

function UserEmailVerification() {
    const navigate = useNavigate();
    const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
    const { role, uid, token } = useParams();
    const API_BASE = process.env.REACT_APP_API_BASE;

    useEffect(() => {
        const getVerificationStatus = async () => {
            try {
                const response = await CustomAxios({
                    method: "GET",
                    baseURL: API_BASE_NORMALSCAP,
                    url: `user/verify-email/${role}/${uid}/${token}`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    console.log("Successful verification");
                    navigate("/", { state: { emailVerified: true } });
                }
            } catch (error) {
                console.error("Verification failed", error);
                navigate("/", { state: { emailVerified: false } });
            }
        };

        getVerificationStatus();
    }, [API_BASE_NORMALSCAP, role, uid, token, navigate]);

    return <div>Verifying your email, please wait...</div>;
}

export default UserEmailVerification;
