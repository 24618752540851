import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import CustomAxios from '../../../API/CustomAxios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import seedimg from '../../../Assests/FarmerDashboard/seeds.jpg';
import edit from "../../../Assests/edit.png";
import deleteimg from '../../../Assests/delete.png';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import EditProductItem from '../../Products/Supplier/EditProductItem';
import {API_BASE_SMARTAJAP} from '../../../API/APIUrls';


function ManageStock() {
    const brand = useSelector((state) => state.auth.brandname);
    const navigate =useNavigate();
    const [searchTerm, setSearchTerm]= useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [displayedOrders, setDisplayedOrders] = useState([]);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedItemDetial,setSelectedItemDetails] = useState("");
    const [userid,setuserid] = useState(useSelector((state) => state.auth.userID));


    const validate=Yup.object({
        productID: Yup.string().required("*Required"),
        productName: Yup.string().required("*Required"),
        productType: Yup.string().required("*Required"),
        productPrice: Yup.string().required("*Required"),
        productStock:Yup.string().required("*Required"),
        productVolume:Yup.string().required("*Required"),
        // image: Yup.mixed().required("*Required"),
    })

    // Set initial orders display to show all orders
    useEffect(() => {
        getStockManagementData();
    }, []);


  //get the data of the items to stock management
    const getStockManagementData = async()=>{
        try{
            const response = await CustomAxios({
                method:"GET",
                baseURL:API_BASE_SMARTAJAP,
                url:`GetProductsByBrandName`,
                params:{
                    brandName:brand
                },
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                setDisplayedOrders(response.data.products);
            }
        }catch(error){
            console.log(error);
        }
    }


    //handle the edit model
    const handleEditModel =(item,detail)=>{
        setEditModel(true);
        setSelectedItem(item);
        setSelectedItemDetails(detail);
    }



    const handleDeleteModel = (item,detail)=>{
        setDeleteModel(true);
        setSelectedItem(item);
        setSelectedItemDetails(detail)
    }


    const handleStockDelete = async()=>{
        try{
            const response = await CustomAxios({
                method:"DELETE",
                baseURL:API_BASE_SMARTAJAP,
                url:"DeleteProductDetail",
                params:{
                    productDetailsID:selectedItemDetial.productDetailsID
                },
                headers:{
                  "Content-Type":"application/json"
                },
            })
            if(response.status === 200){
                // const updatedStock = displayedOrders.filter((item) => item !== selectedItemDetial.productDetailsID)
                // setDisplayedOrders(updatedStock);
                getStockManagementData();
                setDeleteModel(false);
            }
        }catch(error){
            console.log(error);
        }
    }


    //handle the page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    

    //handle the rows for a page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }


    const handleSearch = () => {
        if (searchTerm) {
            // Filter orders based on search term
            const filteredOrders = displayedOrders.filter(order =>
                order.productDetails.some(detail => detail.productDetailsID.includes(searchTerm))
            );
            setDisplayedOrders(filteredOrders);
            setPage(0);
        } else {
            getStockManagementData();
        }
    };
    

    
  return (
    <>
    {/* <div className='flex flex-col items-center justify-center mt-2'>
        <h1 className='flex text-center justify-center font-semibold text-2xl text-gray-500 tracking-wider'> Stock Management</h1>
    </div> */}

    {/* The Table starts here */}
    <div className='flex flex-col rounded-lg mt-6 '>
    <div className='flex sm:flex-row w-full justify-between  mob2:flex-col '>
        {/* <h1 className='font-semibold text-xl text-black/50'>Stocks</h1> */}
        <div className='flex flex-row gap-3 mob2:justify-center mob:justify-end mob2:w-full mob:w-auto mob2:mt-2 sm:mt-0'>
            <input
                className='rounded-md p-3 mob:w-48 mob2:w-full text-xs bg-white focus:outline-none'
                placeholder="Search by Item Id"
                value={searchTerm}
                onChange={(e)=> setSearchTerm(e.target.value)}
            />
            <button
                className='px-6 rounded-md text-white bg-agro_darkgreen text-sm font-semibold'
                onClick={handleSearch}
            >
                Search
            </button>
        </div>
    </div>

    <div className='flex flex-col mt-2'>
        <TablePagination 
            component="div"
            rowsPerPageOptions={[2,10,25,50]}
            page={page}
            count={displayedOrders.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
                '@media (max-width: 640px)': {
                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                        fontSize: '10px', 
                    },
                    fontSize: '10px',   
                },
                fontSize: '14px', 
            }}
        />
        <div className='overflow-x-auto rounded-lg bg-white'>
            <table className='min-w-full rounded-lg'>
                <thead className='bg-agro_lightgreen/60 text-black/70 text-left'>
                    <tr>
                        <th className='px-6 py-3 text-xs'>No</th>
                        <th className='px-6 py-3 text-xs'>Product Image</th>
                        <th className='px-6 py-3 text-xs'>Product ID</th>
                        <th className='px-6 py-3 text-xs'>Product Name</th>
                        <th className='px-6 py-3 text-xs'>Volume</th>
                        <th className='px-6 py-3 text-xs'>Stock</th>
                        <th className='px-6 py-3 text-xs'>Price</th>
                        <th className='px-6 py-3 text-xs'>Update</th>
                        <th className='px-6 py-3 text-xs'>Delete</th>
                    </tr>
                </thead>

                <tbody>
                    {displayedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                       item.productDetails.map((detail, detailIndex) => (
                        <tr key={detail.productDetailsID} className=' text-xs font-normal border border-b-agro_green/20'>
                            <td className='px-6 py-3'>{page * rowsPerPage + index + 1}</td>
                            <td className='px-6 py-3'>
                            <img 
                                src={detail.productImage ? `data:image/png;base64,${detail.productImage}` : seedimg} 
                                className='w-8 h-8' 
                                alt={detail.productName} 
                            />
                            </td>
                            <td className='px-6 py-3'>{detail.productDetailsID}</td>
                            <td className='px-6 py-3'>{item.productName}</td>
                            <td className='px-6 py-3'>{detail.productVolume}</td>
                            <td className='px-6 py-3'>{detail.productStock}</td>
                            <td className='px-6 py-3'>{detail.productPrice}</td>
                            <td className='px-6 py-3'>
                                <button 
                                    className='rounded-md text-black/60 font-semibold '
                                    onClick={()=>handleEditModel(item,detail)}
                                >
                                   <img src={edit} className='w-5 h-5'/>
                                </button>
                            </td>
                            <td className='px-6 py-3'>
                                <button 
                                    className=' rounded-md text-black/60 font-semibold '
                                    onClick={()=>handleDeleteModel(item, detail)}
                                >
                                   <img src={deleteimg} className='w-5 h-5' />
                                </button>
                            </td>
                        </tr>
                       )
                    )
                    ))}
                </tbody>
            </table>
        </div>
    </div>
    </div>

{/* when the update is clicked */}
{editModel && (<EditProductItem productDetails={selectedItem} itemDetails={selectedItemDetial} onClose={()=>setEditModel(false)} showUpdatedItems={getStockManagementData}/>)}


{/* show the delete model */}
{deleteModel &&(
       <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
       <div className='bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4'>
         <h1 className='text-sm text-center'>
           Are you sure you want to delete{' '}
           <span className='font-semibold text-md'>{selectedItem.productName}</span>?
         </h1>
         <div className='flex flex-row justify-between gap-12 text-white text-sm font-semibold'>
           <button className='bg-agro_greenlight py-2 rounded-md px-4' onClick={() => handleStockDelete()}>
             Delete
           </button>
           <button
             className='bg-red-500 py-2 rounded-md px-4'
             onClick={() => setDeleteModel(false)}
           >
             Cancel
           </button>
         </div>
       </div>
     </div>
)}
    </>
  )
}

export default ManageStock