import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { incrementQuantity, decrementQuantity, removeFromCart } from "../ReduxManagement/cartSlice";
import { FaTrash } from "react-icons/fa";
import CustomAxios from "../../API/CustomAxios";
import Alert from "../../Components/Alert";
import { clearCart } from "../ReduxManagement/cartSlice";
import { API_BASE_SMARTAJAP, API_BASE_SMARTSCAP } from "../../API/APIUrls";


const CartTable = () => {
  const dispatch = useDispatch();
  const [userCartItems, setUserCartItems] = useState([]);
  // const cartItems = useSelector((state) => state.cart.items);
  const farmerID = useSelector((state) => state.auth.userID); 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stockData, setStockData] = useState({});
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });


  useEffect(()=>{
    getCartItems();
  },[])


  const getCartItems=async()=>{
    try{
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTSCAP,
        url: `getCartByFarmer`,
        params:{
          farmerID:farmerID
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(response.status === 200){
        setUserCartItems(response.data);
        fetchStockForItems(response.data);
      }
    }catch(error){
       setAlertProps({ severity: "error", message:" Error Occured while loading Cart Items", show: true });
    }
  }


  const fetchStockForItems = async(cartItems)=>{
    try {
      const stockData = {};
      for (const item of cartItems) {
        const response = await CustomAxios({
          method: "GET",
          baseURL: API_BASE_SMARTAJAP,
          url: `ReadProductByDetailsID`,
          params: { 
            productDetailsID: item.productDetailsId 
          },
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          stockData[item.productDetailsId] = response.data.products.productStock;
        }
      }
        setStockData(stockData);
    } catch (error) {
      console.log(error);
    }
  };




  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
 

  const handleIncrement = async (cartId, currentQuantity,productDetailsId) => {
    const stock = stockData[productDetailsId] || 0;
    if (currentQuantity < stock) {
      const updatedQuantity = currentQuantity + 1;
      // Optimistically update the UI
      setUserCartItems((prevItems) =>prevItems.map((item) =>item.cartid === cartId ? { ...item, quantity: updatedQuantity } : item));
      try {
        await CustomAxios({
          method: "PUT",
          baseURL: API_BASE_SMARTSCAP,
          url: `updateCart`,
          data: { 
            cartid: cartId, 
            farmerid: farmerID, 
            quantity: updatedQuantity 
          },
          headers: { 
            "Content-Type": "application/json" 
          },
        });
      } catch (error) {
        // Roll back state if API fails
        setUserCartItems((prevItems) => prevItems.map((item) => item.cartid === cartId ? { ...item, quantity: currentQuantity } : item));
      }
    } else {
        setAlertProps({severity: "warning",message: "Cannot increase quantity beyond available stock.",show: true,});
    }
  };


  const handleDecrement = async (cartId, currentQuantity) => {
    if (currentQuantity <= 1) return; // Prevent decrementing below 1
    const updatedQuantity = currentQuantity - 1;
    // Optimistically update the state
    setUserCartItems((prevItems) =>prevItems.map((item) =>item.cartid === cartId ? { ...item, quantity: updatedQuantity } : item));
    try {
      await CustomAxios({
        method: "PUT",
        baseURL: API_BASE_SMARTSCAP,
        url: `updateCart`,
        data: { 
          cartid: cartId, 
          farmerid: farmerID, 
          quantity: updatedQuantity
        },
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      // Roll back state if API fails
      setUserCartItems((prevItems) =>prevItems.map((item) => item.cartid === cartId ? { ...item, quantity: currentQuantity } : item ));
    }
  };

  

  const handleRemove = async(cartId) => {
    try{
      const response = await CustomAxios({
        method: "DELETE",
        baseURL: API_BASE_SMARTSCAP,
        url: `deleteCart`,
        params:{
          id:cartId
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(response.status === 200){
        getCartItems()
        // setUserCartItems((prevItems) =>prevItems.map((item) =>item.cartid !== cartId));
      }
    }catch(error){

    }
    // dispatch(removeFromCart(productId));
  };

  // const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  // const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0).toFixed(2);

  const totalItems = Array.isArray(userCartItems)? userCartItems.reduce((total, item) => total + item.quantity, 0): 0;
  const totalPrice = Array.isArray(userCartItems)? userCartItems.reduce((total, item) => total + parseFloat(item.unitPrice) * item.quantity, 0).toFixed(2): "0.00";


  // Prepare the order data
  const prepareOrderData = () => {
    return {
      farmerid: farmerID,
      order_startdate: new Date().toISOString().split("T")[0], // today date
      order_enddate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split("T")[0], // 7 days from today
      total_price: parseFloat(totalPrice),
      order_items: userCartItems.map((item) => ({
        brandName:item.brandName,
        productName: item.productName,
        productId:item.productId,
        supplierID: item.supplierID,
        supplierLocation: item.supplierLocation,
        orderStatus: "Pending",
        supplierTotalPrice: item.unitPrice * item.quantity,
        itemDetails: [
          {
            productDetailsId:item.productDetailsId,
            itemType: item.productType,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            itemStatus: "Pending"
          }
        ]
      }))
    };
  };


  const clearCartItems = async () => {
    try{
      const response = await CustomAxios({
        method: 'DELETE',
        baseURL: API_BASE_SMARTSCAP,
        url: `deleteAllCartByFarmer`,
        params:{
          id:farmerID
        },
        headers: {
            'Content-Type': 'application/json',
        },
      })
      if(response.status === 200){
        getCartItems();
      }else{
        setAlertProps({ severity: "error", message: "An Error Occured", show: true });
      }
    }catch(error){
        setAlertProps({ severity: "error", message: "An Error Occured", show: true });
    }
  };


  // Place order function
  const placeOrder = async () => {
    const orderData = prepareOrderData();
    // console.log("orderData",orderData);
    setIsLoading(true);
    setError(null);
    setOrderSuccess(false);

    try{
      console.log("orderd datassss", orderData);
      const response = await CustomAxios({
        method: 'POST',
        baseURL: API_BASE_SMARTSCAP,
        url: `createOrder`,
        data:orderData,
        headers: {
            'Content-Type': 'application/json',
        },
      })
      if(response.status === 200){
        clearCartItems();
        setAlertProps({ severity: "success", message: "OrderPlaced Successfully", show: true });
        setOrderSuccess(true);
      }else{
        setAlertProps({ severity: "error", message: "An Error Occured", show: true });
      }
    }catch(error){
        setAlertProps({ severity: "error", message: "An Error Occured", show: true });
    }

    // try {
    //   const response = await fetch("https://scapione.bethel.network/createOrder", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(orderData),
    //   });
    //   console.log(orderData);
    //   const data = await response.json();
    //   if (response.ok) {
    //     setOrderSuccess(true);
    //   } else {
    //     setError(data.message || "An error occurred while placing the order.");
    //   }
    // } catch (err) {
    //   setError("Failed to place order. Please try again.");
    // } finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <div className="p-6">
      <table className="min-w-full bg-white border-collapse shadow-lg">
        <thead className="bg-light_green">
          <tr>
            <th className="py-3 px-4 text-left">Product Name</th>
            <th className="py-3 px-4 text-left">Product Type</th>
            <th className="py-3 px-4 text-left">Volume</th>
            <th className="py-3 px-4 text-left">Price</th>
            <th className="py-3 px-4 text-left">Quantity</th>
            <th className="py-3 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {userCartItems && userCartItems.map((item) => (
            <tr key={item.cartid}>
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{item.productType}</td>
              <td className="py-2 px-4">{item.volume}</td>
              <td className="py-2 px-4">${item.unitPrice}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => handleDecrement(item.cartid, item.quantity,item.productDetailsId)}
                  className="px-2 py-1 bg-gray-300 text-white rounded"
                >
                  -
                </button>
                <span className="mx-2">{item.quantity}</span>
                <button
                  onClick={() => handleIncrement(item.cartid, item.quantity,item.productDetailsId)}
                  className="px-2 py-1 bg-gray-400 text-white rounded"
                >
                  +
                </button>
              </td>
              <td className="py-2 px-4">
                <button
                  onClick={() => handleRemove(item.cartid)}
                  className="text-red-600 hover:text-red-800"
                >
                  <FaTrash className="text-xl" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 flex justify-between items-center p-2 bg-light_green/50 rounded-lg">
        <div className="text-xl">Total Items: {totalItems}</div>
        <div className="text-xl">Total: ${totalPrice}</div>
      </div>

      {/* Place Order Button */}
      <div className="mt-4 flex justify-end flex-col items-end">
        <button
          onClick={placeOrder}
          className={`w-1/4 py-2 px-4 text-white rounded-lg ${
          isLoading ? "bg-green-400" : "bg-green-600 hover:bg-green-700"
          }`}
          disabled={isLoading || userCartItems.length === 0}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>

        {/* Show success or error message */}
        {orderSuccess && (
          <div className="mt-4 text-green-600">Order placed successfully!</div>
        )}
        {error && <div className="mt-4 text-red-600">{error}</div>}
      </div>

        {/* Calling the Alert component */}
        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
    </div>
  );
};

export default CartTable;
