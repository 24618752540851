import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomAxios from "../../API/CustomAxios";
import { useSelector } from "react-redux";


function UserProfileEmailVerification() {
  const navigate = useNavigate();
  const { role, username, token, email } = useParams();
  const API_BASE = process.env.REACT_APP_API_BASE;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const refreshToken = useSelector((state) => state.auth.REFRESHTOKEN);
  const [newEmail,setEmail] = useState(useSelector((state) => state.auth.updatingEmail));
  // const refreshToken = localStorage.getItem("refreshToken");



  useEffect(()=>{

    const updateEmail = async ()=>{
        try {
            const emailUpdateResponse = await CustomAxios({
              method: "PUT",
              baseURL: API_BASE_NORMALSCAP,
              url: `user/update-email/${role}/${username}/${token}/${email}`,
              headers: {
                "Content-Type": "application/json",
                // token:refreshToken,
              },
            });
      
            if (emailUpdateResponse.status === 200) {
              navigate("/", { state: { emailVerified: true } });
              console.log("Email updated successfully");
            } else {
              console.error("Failed to update email");
            }
          } catch (error) {
            navigate("/", { state: { emailVerified: false } });
            console.error("Error occurred while updating email", error);
          }
        };
    
    updateEmail();
  },[API_BASE_NORMALSCAP, role, username, token, navigate])
 

  return <div>Verifying your email, please wait...</div>;
}

export default UserProfileEmailVerification;
