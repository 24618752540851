import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faShower, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import CustomAxios from '../../../API/CustomAxios';

const iconMap = {
  "seeds": { icon: faSeedling, color: 'text-agro_green' },
  "fertilizer": { icon: faLeaf, color: 'text-light_green' },
  "pesticide": { icon: faShower, color: 'text-agro_yellow' },
};

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const farmerID = useSelector((state) => state.auth.userID); 
  const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
  const API_BASE_NORMALSCAP = process.env.REACT_APP_API_NORMALSCAP;
  const token = useSelector((state) => state.auth.ACCESSTOKEN);

  // Fetch data from the API
  useEffect(() => {
    // if (!farmerID) {
    //   console.error("Farmer ID is missing from the Redux store.");
    //   setLoading(false);
    //   return;
    // }
    const fetchOrders = async ()=>{
      try{
        const response = await CustomAxios({
          method: 'GET',
          baseURL: API_BASE_SMARTSCAP,
          url: `getOrdersByFarmer`,
          params:{
            farmerId:farmerID
          },
          headers: {
              'Content-Type': 'application/json',
          },
        })

        if(response.status === 200){
          console.log("get my orders",response.data)
          const orderData = response.data.orders;
          const flattenedOrders = orderData.flatMap(order =>
            order.order_items.flatMap(item =>
              item.itemDetails.map(itemDetail => ({
                OrderID: order.orderid,
                productName: item.productName,
                itemType: itemDetail.itemType,
                quantity: itemDetail.quantity,
                unitPrice: itemDetail.unitPrice,
                supplierName: item.brandName, // Supplier name
                // supplierName: getSupplier(item.supplierID) ,
                orderDate: order.order_startdate, // Using OrderStartDate for date grouping
                totalPrice: order.total_price,
              }))
            )
          );
            setOrders(flattenedOrders);
            // setLoading(false);
        }
      }catch(error){
        console.log(error);
      }
    }
    fetchOrders();
  }, [farmerID]); // The effect depends on farmerID


  const getSupplier =async(supplier)=>{
    try{
      const response = await CustomAxios({
        method: 'GET',
        baseURL: API_BASE_NORMALSCAP,
        url: `supplier/${supplier}`,
        headers: {
            'Content-Type': 'application/json',
            "token": token
        },
      })
      if(response.status === 200){
        console.log("my", response.data)
        const data = response.data.supplier.brandName;
        return data
      }
    }catch(error){
      console.log(error);
    }
  }

  // Group orders by month
  const groupedOrders = orders.reduce((acc, order) => {
    const orderDate = new Date(order.orderDate);
    const month = orderDate.toLocaleString('default', { month: 'long' }) + ' ' + orderDate.getFullYear();
    (acc[month] = acc[month] || []).push(order);
    return acc;
  }, {});


  return (
    <div className="min-h-screen flex flex-col items-center">
      <h1 className="text-3xl font-semiBold mb-6 text-agro_darkgreenZ">View Your Past Orders</h1>
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6">
        {Object.entries(groupedOrders).map(([month, orders]) => (
          <div key={month} className="mb-8">
            <h2 className="text-2xl font-semibold text-agro_gree mb-2">{month}</h2>
            <ul className="mt-2">
              {orders.map(order => (
                <li key={order.OrderID} className={`flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col items-center justify-between p-4 border-b ${iconMap[order.itemType]?.color || ''}`}>
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={iconMap[order.itemType]?.icon || faLeaf} className="mr-3" />
                    <span className="font-medium">{order.productName} ({order.itemType})</span>
                  </div>
                  <span className="text-gray-600">{order.orderDate} ({order.quantity} x {order.unitPrice} each)</span>
                  <span className="text-gray-600">Supplier: {order.supplierName}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div> 
  );
};

export default OrderHistory;
