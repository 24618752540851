import React from 'react'
import WeatherWidget from './WeatherWidget'
// import MapCard from './MapcardComponent'
import MapCard from './MapCard';

import ReminderCard from './ReminderCard';
function FarmerDashboard() {
  return (
    <div className="flex flex-col items-center h-[85vh] w-full">
      <div className="grid xl:grid-cols-2 gap-4 w-full h-full mob2:grid-cols-1">
        <div className="flex flex-col h-full gap-4 justify-between">
          <WeatherWidget />
          <ReminderCard />
        </div>
        <div className="h-full">
          {/* <MapCard /> */}
          <MapCard />
        </div>
      </div>
    </div>
  );
}



export default FarmerDashboard
