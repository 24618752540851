import React, { useState } from 'react';
import AddLandInfo from './AddLandInfo';
import UserLandDetails from './UserLandDetails';

function LandInfo() {
  const [updateselectedLand, setUpdateSelectedLand] = useState(null);
  const [AllgetLands, setGetAllLands] = useState(() => () => {});

 
  return (
    <div className=" flex xl:h-[calc(100vh-150px)]  w-full items-center justify-center overflow-hidden">
      <div className="  grid xl:grid-cols-2 xl:gap-4 mob2:gap-8 w-full h-full  sm:p-4 mob2:grid-cols-1">
        {/* Add Land Information Section */}
        <div className=" relative col-span-1 sm:bg-gray-100 items-center justify-center sm:p-4 flex flex-col mob2:mt-4 sm:mt-0 h-full sm:rounded-md sm:shadow-md overflow-hidden">
          <h1 className="text-2xl xl:absolute top-4 font-bold text-center tracking-wider mb-4">
            Add Land Information
          </h1>
          <div className="flex flex-col items-center justify-center w-full  overflow-y-auto">
            <AddLandInfo
              selectedLand={updateselectedLand}
              setSelectedLand={setUpdateSelectedLand}
              getLands={AllgetLands}
            />
          </div>
        </div>

        {/* UserLandDetails Section */}
        <div className="col-span-1  sm:bg-agro_greenlight sm:p-4 flex flex-col h-full rounded-md shadow-md overflow-hidden">
          <div className="h-full overflow-y-auto no-scrollbar">
            <UserLandDetails
              setGetAllLands={setGetAllLands}
              setSelectedLand={setUpdateSelectedLand}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandInfo;
