import React,{useState} from 'react'
import AddCultivation from './AddCultivation'
import CultivationDetails from './CultivationDetails'

function Cultivate() {
    const [updatingCultivation, setUpdatingCultivation] = useState(null);
    const [AllgetCultivations, setGetAllCultivations] = useState(() => () => {});

  return (
    <div className='flex flex-row justify-center py-4 h-[90vh]'>
    <div className="grid  xl:grid-cols-2 mob2:grid-cols-1 sm:gap-4 mob2:gap-6 w-full p-4">
      <div className="col-span-1 sm:bg-gray-100 sm:p-4 flex flex-col relative items-center justify-center h-full rounded-md sm:shadow-md">
        <h1 className='flex xl:absolute  text-2xl  font-bold  tracking-wider xl:top-4 mob2:mb-4 text-center'>Add Your Cultivation Information</h1>
        <div className='flex flex-col items-center justify-center w-full'>
            {/* <AddLandInfo selectedLand={updateselectedLand} setSelectedLand={setUpdateSelectedLand}/> */}
            <AddCultivation  selectedCultivate={updatingCultivation} setSelectedcultivate={setUpdatingCultivation} getCultivations={AllgetCultivations}/>
        </div>
      </div>
      <div className="col-span-1 bg-agro_greenlight p-4 rounded-md shadow-md">
            {/* <UserLandDetails setSelectedLand={setUpdateSelectedLand} /> */}
            <CultivationDetails setSelectedCultivation={setUpdatingCultivation} setGetAllCultivations={setGetAllCultivations} />
      </div>
    </div>
    </div>
  )
}

export default Cultivate