import { BrowserRouter,Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Signup from "./Pages/Signup/Signup";
import HomeSignUp from "./Pages/Home/HomeSignUp";
import Dashboard from "./Pages/Dashboard/Dashboard";
import DashboardContent from "./Pages/Dashboard/DashboardContent";
import ManageStock from "./Pages/Orders/Supplier/ManageStock";
import ViewPastOrders from "./Pages/Orders/Supplier/ViewPastOrders";
import CustomerPastOrderDetails from "./Pages/Orders/Supplier/OrderIdDetails/CustomerPastOrderDetails";
import FarmerProfile from "./Pages/Dashboard/Farmer/FarmerProfile";
import FarmerDashboard from "./Pages/Dashboard/Farmer/FarmerDashboard";
import Cultivation from "./Pages/Cultivation/Cultivation";
import FarmerOrders from "./Pages/Orders/Farmer/FarmerOrders";
import Profile from "./Pages/Profile/Profile";
import Products from "./Pages/Products/Products";
import CreateSupplier from "./Pages/UserManagement/CreateSupplier";
import ProductList from './Pages/Products/ProductList'
import Cart from "./Pages/Products/Cart";
import Seeds from "./Pages/Seeds/Seeds";
import NewProfile from "./Pages/Profile/NewProfile";
import CreateProduct from "./Pages/Products/Supplier/CreateProduct";
import UserEmailVerification from "./Pages/UserEmailVerification/UserEmailVerification";
import PasswordReset from "./Pages/Forgotpassword/PasswordReset";
import LandInfo from "./Pages/Land/LandInfo";
import HarvestList from "./Pages/Land/Harvest/HarvestList";
import Cultivate from "./Pages/Land/Cultivation/Cultivate";
import UserProfileEmailVerifictaion from "./Pages/UserEmailVerification/UserProfileEmailVerifictaion";
import Contactus from "./Pages/ContactUs/Contactus";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import PageNotFound from "./Components/PrivateRoute/PageNotFound";


function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="" element={<Home />}/>
          <Route path="/homesignup" element={<HomeSignUp />}/>
          <Route path="/signup" element={<Signup />}/>
          <Route path="/userEmailVerify/:role/:uid/:token" element={<UserEmailVerification />}/>
          <Route path="/userProfileEmailVerify/:role/:username/:token/:email" element={<UserProfileEmailVerifictaion />}/>
          <Route path="/passwordReset/:role/:uid/:resetToken"  element={<PasswordReset />}/>
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
            <Route index element={<DashboardContent />}/>
            <Route path="stockManage" element={<ManageStock />}/>
            <Route path="pastOrders" element={<ViewPastOrders />}/>
            <Route path="orderDetails" element={<CustomerPastOrderDetails />}/>
            <Route path="orderDetails/:orderID" element={<CustomerPastOrderDetails />}/>
            <Route path="profile" element={<Profile />}/>
            <Route path="myorders" element={<FarmerOrders />}/>
            <Route path= "seeds" element={<Seeds />}/>
            <Route path="products" element={<Products />}/>
            <Route path="createProduct" element={<CreateProduct />}/>
            <Route path="cultivate" element={<Cultivate />}/>
            <Route path="land" element={<LandInfo/>}/>
            <Route path="harvest" element={<HarvestList />}/>
            <Route path="createuser" element={<CreateSupplier />}/>
            <Route path="products/productList" element={<ProductList />}/>
            <Route path="cart" element={<Cart />}/>
            <Route path="contact" element={<Contactus />}/>
            <Route path="newProfile" element={<NewProfile />}/>
          </Route>
          <Route path="/route" element={<PrivateRoute><PrivateRoute/></PrivateRoute>}/>
          <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
