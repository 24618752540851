import React from 'react'

function EmailVerified({onClose}) {
  return (
<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gradient-to-r from-agro_greenlight via-agro_darkgreen to-agro_darkyellow p-1 rounded-lg">
                <div className="bg-white p-6 sm:rounded-lg shadow-lg flex flex-col gap-4 relative overflow-y-auto no-scrollbar">
                    <h3 className="text-xl text-center font-bold text-agro_darkgreen">Your Mail is verified</h3>
                    <p>Mail was verified Successfully</p>
                    <button
                        className="absolute top-2 right-4 font-bold text-lg text-red-500"
                        onClick={onClose}
                    >
                        ✕
                    </button>
                    <div className="flex justify-center">
                        <button className="bg-agro_darkgreen p-2 rounded-md text-white w-24 flex justify-center" onClick={onClose}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
</div>
  )
}

export default EmailVerified