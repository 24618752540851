import React, { useState } from 'react';
import CustomAxios from '../../API/CustomAxios';
import EmailVerification from '../../Components/EmailVerification';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';
import Alert from '../../Components/Alert';
import Model from '../../Components/Model';


function PasswordMailVerify({ onClose }) {
    const [typedEmail, setTypedEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [showMailVerify, setshowMailVerify] = useState(false);
    const [model, setModel] = useState(false);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });

    //hide alerts
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };
 

    const handleEmailInput = (event) => {
        setTypedEmail(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleEmailSubmit = async () => {
        if (!typedEmail || !selectedRole) {
            setAlertProps({severity: "warning",message: "Please fill out the fields",show: true,}); 
            return;
        }

        try {
            setModel(true); 
            const response = await CustomAxios({
                method: 'PUT',
                baseURL: API_BASE_NORMALSCAP,
                url: `sendemail/forgetpassword/${typedEmail}/${selectedRole}`, 
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log("reset pw sended ",response.data);
                setAlertProps({severity: "success",message: "Data Succssfully Sent",show: true,}); 
                setModel(false); 
                setshowMailVerify(true);
            }
        } catch (error) {
            setModel(false); 
            console.error('Error sending email and role:'); 
            setAlertProps({severity: "error",message: "An Error Occured",show: true,}); 
        }
    };

    if(showMailVerify){
       return  <EmailVerification onClose={()=>{setshowMailVerify(false); onClose();}} />
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
             <Model showModel={model}/>
            <div className="bg-gradient-to-r from-agro_greenlight via-agro_darkgreen to-agro_darkyellow p-1 rounded-lg">
                <div className="bg-white p-6 sm:rounded-lg shadow-lg flex flex-col gap-4 relative overflow-y-auto no-scrollbar">
                    <h3 className="text-xl font-bold text-agro_darkgreen">Reset Password</h3>
                    <p>Enter your Email and select your Role to receive a mail for password reset</p>
                    <button
                        className="absolute top-2 right-4 font-bold text-lg text-red-500"
                        onClick={onClose}
                    >
                        ✕
                    </button>
                    <div className="flex flex-row gap-3">
                        <input
                            type="email"
                            placeholder="Enter Your Email"
                            value={typedEmail}
                            onChange={handleEmailInput}
                            className="border focus:outline-none border-agro_darkgreen/50 p-2 text-black w-full rounded-md"
                        />

                        <select
                            value={selectedRole}
                            onChange={handleRoleChange}
                            className="border focus:outline-none border-agro_darkgreen/50 p-2 text-black w-full rounded-md"
                        >
                            <option value="" disabled>Select Your Role</option>
                            <option value="farmer">Farmer</option>
                            <option value="supplier">Supplier</option>
                        </select>
                        
    
                    </div>
                        <button
                            className="bg-agro_darkgreen px-4 py-2 text-white rounded-md"
                            onClick={handleEmailSubmit}
                        >
                            Send
                        </button>
                </div>
            </div>

        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
        </div>
    );
}

export default PasswordMailVerify;
