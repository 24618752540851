import React,{useState, useEffect} from 'react'
import ProfileImage from './ProfileImage';
import showpw from '../../Assests/showpw.png';
import hidepw from '../../Assests/hidepw.png';
import edit from "../../Assests/newEdit.png";
import { useSelector } from 'react-redux';
import Alert from '../../Components/Alert';
import CustomAxios from '../../API/CustomAxios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { API_BASE_NORMALSCAP } from '../../API/APIUrls';


function PasswordChange(){
    const token = useSelector((state) => state.auth.ACCESSTOKEN);
    const [EditPassword, setEditPassword] = useState(false);
    const [confirmtype, setConfirmtype] = useState("password");
    const[confirmImg,setConfirmImg] = useState(hidepw);
    const [type, setType] = useState("password");
    const[img,setImg] = useState(hidepw);
    const [curtype, setCurtype] = useState("password");
    const[curImg,setCurImg] = useState(hidepw);
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const [userRole,setUserRole] = useState(useSelector((state) => state.auth.userRole));//access the uer role form redux
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));



    const passwordValidate = Yup.object({
        currentPassword:Yup.string()
        .required("*Required"),
        newPassword:Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(100, "Must be less than 100 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
        .required("*Password is Required"),
        confirmpassword:Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'), 
      })

      //hide alerts
      const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
      };
  
      //handle the password update
      const handlePasswordUpdate = async(values)=>{
        const {confirmpassword, ...passwordvalues} = values;
        try{
          const response = await CustomAxios({
            method: "PUT",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/resetpassword/${userId}`,
            data:passwordvalues,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
          })
          if(response.status === 200){
            setAlertProps({severity: "success",message: response.data.message,show: true,});
          }else{
            setAlertProps({severity: "error",message: response.response.data.error,show: true,});
          }
        }catch(error){
          // console.log(error);
          const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
            setAlertProps({ severity: "error", message: errorMessage, show: true });
        }
      }

              
    //handle password toggle
    const handleConfirmPwToggle = () => {
      if (confirmtype === "password") {
        setConfirmImg(showpw); 
        setConfirmtype("text"); 
      } else {
        setConfirmImg(hidepw); 
        setConfirmtype("password");
      }
    };
  
  
    //handle current password toggle
    const handleCurPwToggle = () => {
      if (curtype === "password") {
        setCurImg(showpw); 
        setCurtype("text"); 
        } else {
        setCurImg(hidepw); 
        setCurtype("password");
        }
    };

    //handle new password toggle
    const handlePwToggle = () => {
      if (type === "password") {
        setImg(showpw); 
        setType("text"); 
      } else {
        setImg(hidepw); 
        setType("password");
      }
    };

return(
<>
<div className='flex flex-col gap-6 bg-white  rounded-md items-center w-full py-4 h-full'>
    {/* Profile image is handle here */}
    <ProfileImage />

    <div className='flex flex-col gap-6 xl:w-full mob2:w-full lg:w-4/5 px-8 mt-4'>
        <div className="flex flex-row justify-between">
          <h1 className='font-semibold text-sm tracking-widest text-start'>Password Change</h1>
          <button onClick={()=>setEditPassword(!EditPassword)} className='flex flex-row hover:underline hover:underline-offset-2 gap-2 items-center text-xs'><img src={edit} className='w-3 h-3'/>Edit</button>
        </div>

        <Formik
            initialValues={{
                currentPassword:"",
                newPassword:"",
                confirmpassword:"",
            }}
            validationSchema={passwordValidate}
            onSubmit={handlePasswordUpdate}
        >
        {(formik) =>(
            <Form className='flex flex-col w-full gap-2 items-center'>
                <div className='flex flex-col w-full h-12 relative'>
                    <Field
                        type={curtype}
                        id="currentPassword"
                        name="currentPassword"
                        placeholder="Current Password"
                        className={`p-3 text-[12px] w-full text-black/60 rounded-md bg-agro_lightgreen/50 border-none border-gray-400 focus:outline-none ${EditPassword ? "":"cursor-not-allowed pointer-events-none"}`}
                    /> 
                    <button onClick={(e) =>{e.preventDefault(); handleCurPwToggle();}}>
                        <img src={curImg} className='absolute w-[20px] h-[20px] bottom-4 right-4'/>
                    </button> 
                    <ErrorMessage 
                        name="currentPassword" 
                        component="div" 
                        className="text-red-500 text-[9px] font-medium italic " 
                    />                           
                </div>
  
                <div className='flex flex-col h-12 w-full relative'>
                    <Field
                        type={type}
                        id="password"
                        name="newPassword"
                        placeholder="newPassword"
                        className={`p-3 text-[12px] w-full text-black/60 rounded-md bg-agro_lightgreen/50 border-none border-gray-400 focus:outline-none ${EditPassword ? "":"cursor-not-allowed pointer-events-none"}`}
                    />
                    <button onClick={(e) =>{e.preventDefault(); handlePwToggle();}}>
                        <img src={img} className='absolute w-[20px] h-[20px] bottom-4 right-4'/>
                    </button>                            
                    <ErrorMessage 
                        name="newPassword" 
                        component="div" 
                        className="text-red-500 text-[9px] font-medium italic " 
                    />
                </div>

                <div className='flex flex-col h-12 w-full relative'>
                    <Field
                        type={confirmtype}
                        id="confirmpassword"
                        name="confirmpassword"
                        placeholder="Confirm Password"
                        className={`p-3 text-[12px] w-full text-black/60 rounded-md bg-agro_lightgreen/50 border-none border-gray-400 focus:outline-none ${EditPassword ? "":"cursor-not-allowed pointer-events-none"}`}
                    />
                    <button onClick={(e) =>{e.preventDefault(); handleConfirmPwToggle();}}>
                        <img src={confirmImg} className='absolute w-[20px] h-[20px] bottom-4 right-4'/>
                    </button>                           
                    <ErrorMessage 
                        name="confirmpassword" 
                        component="div" 
                        className="text-red-500 text-[9px] font-medium italic " 
                    />
                </div>
                <button type="submit" className={`${EditPassword ? "bg-agro_greenlight hover:bg-agro_greenlight/80" :" bg-agro_greenlight cursor-not-allowed pointer-events-none"}  w-56 mt-4 text-sm font-semibold text-white rounded-md p-2`}>Update</button>
            </Form>
        )}
        </Formik>
    </div>

    <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />
</div>
</>    
)
};

export default PasswordChange;