import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';
import CustomAxios from '../../../API/CustomAxios';
import { useSelector } from 'react-redux';
import {API_BASE_SMARTAJAP} from '../../../API/APIUrls'

function SupplierIncome() {
    const userId = useSelector((state) => state.auth.userID);
    const COLORS = ["#008922", "#007F20", "#00681C", "#004D16", "#009B28", "#00A52B", "#00B02E", "#00BA31", "#00C534", "#00D036", "#00DA39", "#00E53C", "#00F03F", "#00FC42"];
    
    const [data, setData] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');

    // Initial fetching for years and product names
    useEffect(() => {
        generateYears();
        fetchProductNames();
    }, []);

    // Fetch income details when both product and year are selected
    useEffect(() => {
        if (selectedProduct && selectedYear) {
            fetchIncomeDetails();
        } else {
            setData([]); // Reset data if either selection is missing
        }
    }, [selectedProduct, selectedYear]);

    // Generate a list of years
    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const generatedYears = [];
        for (let year = 2024; year <= currentYear; year++) {
            generatedYears.push(year);
        }
        setYears(generatedYears);
        if (generatedYears.length) {
            setSelectedYear(generatedYears[0]); // Default to the first year
        }
    };

    // Fetch product names from API
    const fetchProductNames = async () => {
        try {
            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_SMARTAJAP,
                url: "GetProductTypesBySupplier",
                params: { supplierID: userId },
                headers: { "Content-Type": "application/json" },
            });
            if (response.status === 200) {
                const productTypes = response.data.productTypes || [];
                console.log(productTypes)
                setProducts(productTypes);
                // Default to the first product if available
                if (productTypes.length) {
                    setSelectedProduct(productTypes[0]);
                }
            }
        } catch (error) {
            console.error("Error fetching product names:", error);
        }
    };

    // Fetch income details based on the selected product and year
    const fetchIncomeDetails = async () => {
        try {
            const productToFetch = selectedProduct; // Use selected product
            const yearToFetch = selectedYear; // Use selected year

            const response = await CustomAxios({
                method: "GET",
                baseURL: API_BASE_SMARTAJAP,
                url: "GetAnnualSalesChartBySupplierAndProductType",
                params: {
                    productType: productToFetch,
                    year: yearToFetch,
                    supplierID: userId,
                },
                headers: { "Content-Type": "application/json" },
            });

            console.log("Income Details Response:", response.data); // Log the response for debugging

            if (response.status === 200) {
                const { dates, quantities } = response.data.chartData;
                const monthData = Array.from({ length: 12 }, (_, index) => ({
                    name: new Date(0, index).toLocaleString("default", { month: "short" }),
                    value: 0,
                }));
                console.log(monthData)

                dates.forEach((date, index) => {
                    const monthIndex = new Date(date).getMonth();
                    monthData[monthIndex].value += quantities[index];
                });

                setData(monthData); // Update the state with the new data
            }
        } catch (error) {
            console.error("Error fetching income details:", error);
        }
    };

    const defaultData = [
        { name: "Jan", value: 10 },
        { name: "Feb", value: 10 },
        { name: "Mar", value: 10 },
        { name: "Apr", value: 10 },
        { name: "May", value: 10 },
        { name: "Jun", value: 10 },
        { name: "Jul", value: 10 },
        { name: "Aug", value: 10 },
        { name: "Sep", value: 10 },
        { name: "Oct", value: 10 },
        { name: "Nov", value: 10 },
        { name: "Dec", value: 10 },
    ];

    const handleFilterChange = (filterType, value) => {
        if (filterType === 'product') setSelectedProduct(value);
        if (filterType === 'year') setSelectedYear(value);
    };

    return (
        <div className="border bg-white shadow-md border-none rounded-md p-4 flex flex-col gap-3">
            <div className="flex items-center gap-4">
                <h1 className="">MONTHLY INCOME</h1>
                <select
                    value={selectedProduct}
                    onChange={(e) => handleFilterChange('product', e.target.value)}
                    className="border p-2 focus:outline-none text-xs rounded-md text-black"
                >
                    <option value="">Select Product</option>
                    {products.map((product, index) => (
                        <option key={index} value={product}>
                            {product}
                        </option>
                    ))}
                </select>
                <select
                    value={selectedYear}
                    onChange={(e) => handleFilterChange('year', e.target.value)}
                    className="border focus:outline-none text-xs p-2 rounded-md"
                >
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                        <option key={index} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>

            <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                    <Pie
                        data={data.length ? data : defaultData} // Use fetched data or default data
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        <LabelList
                            dataKey="value"
                            position="outside"
                            offset={20}
                            style={{ fontSize: "15px", fontWeight: "bold", fill: "#333" }}
                        />
                    </Pie>
                    <Tooltip wrapperStyle={{ fontSize: "12px" }} />
                    <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{ fontSize: "12px", lineHeight: "20px" }} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

export default SupplierIncome;