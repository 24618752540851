import React,{useEffect, useState} from 'react';
import profileimg from "../../Assests/profileChange.png";
import { FaCamera } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomAxios from '../../API/CustomAxios';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';
import { setUserImg } from '../ReduxManagement/authSlice';
import Alert from '../../Components/Alert';


function ProfileImage(){
    const dispatch = useDispatch();
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
    const token = useSelector((state) => state.auth.ACCESSTOKEN);
    const [UserImage, setUserImage] = useState();
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });

    useEffect(()=>{
        getPhoto();
    },[])

    //hide alerts
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };
  
    //get the profile photo
    const getPhoto = async()=>{
       try{
          const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/profileimage/${userId}`,
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
            responseType: 'arraybuffer'
          })

          if (response.status === 200) {
            console.log("image", response.data);
            // Convert the binary data to a Blob object
            const imageBlob = new Blob([response.data], { type: 'image/png' }); 
            const imageUrl = URL.createObjectURL(imageBlob);
            setUserImage(imageUrl);
            dispatch(setUserImg(imageUrl));
          }
        }catch(error){
            //console.log(error);
            // setAlertProps({severity: "error",message: "Erorr Occured",show: true,});          
        }
      }


      //handle the photo upload
      const handlePhotoUpload = (e) => {
        const file = e.target.files[0];
        if(file){
          const photoUrl = URL.createObjectURL(file);
          setUserImage(photoUrl);
        }
          handleSubmitPhoto(file);
      };
    

      //handle the photo submit
      const handleSubmitPhoto = async(file) =>{
          const formData = new FormData();
          formData.append("profile_image", file);
          formData.append("uid",userId )
        try{
          const response = await CustomAxios({
            method: "POST",
            baseURL: API_BASE_NORMALSCAP,
            url: `user/profileimage/${userId}`,
            data:formData,
            headers: {
            "Content-Type": "multipart/form-data",
            token: token,
            },
          })
          if(response.status === 200){
            const updatedPhotoUrl = URL.createObjectURL(file);
            setUserImage(updatedPhotoUrl); // Update the state
            dispatch(setUserImg(updatedPhotoUrl));
            // console.log("image", response.data);
            setAlertProps({severity: "success",message: "Image uploaded successfully",show: true,});
            
          }
        }catch(error){
            //console.log(error);
            setAlertProps({severity: "error",message: "Erorr Occured While uploading the photo",show: true,});
        }
      }
      
      

return(
<>
<div className='w-56 h-56 relative'>
    <img src={UserImage || profileimg} alt="Profile" className='object-cover p-2 rounded-full w-full h-full  border border-agro_darkgreen/20'/>
        <label className='absolute bg-agro_greenlight p-2 rounded-full -bottom-4 right-20'>
            <FaCamera className="text-lg text-white" />
            <input type="file" className="hidden" onChange={handlePhotoUpload} />
        </label>
</div>

{/* Calling the Alert component */}
<Alert
    message={alertProps.message}
    severity={alertProps.severity}
    show={alertProps.show}
    onClose={hideAlert}
/>
</>
)
}
export default ProfileImage;