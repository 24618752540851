// Products.js
import React, { useEffect, useState } from "react";
import Fertilizer from "../../Assests/FarmerDashboard/bottleImage.jpg";
import Pesticides from "../../Assests/FarmerDashboard/pesticides.jpg";
import Seeds from "../../Assests/FarmerDashboard/seeds.jpg";
import { FaCartPlus } from "react-icons/fa"; 
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../ReduxManagement/cartSlice";
import CustomAxios from "../../API/CustomAxios";
import AddNewProduct from "./Supplier/AddNewProduct";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import ProductItemCard from "../../Components/ProductItemCard";
import {API_BASE_SMARTAJAP, API_BASE_SMARTSCAP} from '../../API/APIUrls';


const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userID); 
  const [userRole, setUserRole] = useState(useSelector((state) => state.auth.userRole));
  const [selectedType, setSelectedType] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [allProducts, setAllProducts] = useState([]);
  // const [showAddModel, setShowAddModel] = useState(false);
  const [allTypes, setAllTypes] = useState([]);
  const [allCategory, setAllcategory] = useState([]);
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });

  
  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
    
  

  //get all the product types and the product categories
  useEffect(()=>{
    getProductTypes()
    getProductCateogary();
  },[])


  useEffect(() => {
    if (selectedType && selectedSupplier) {
      fetchProductsByTypeAndSupplier(selectedType, selectedSupplier);
    } else if (selectedType) {
      fetchProductsByType(selectedType);
    } else if (selectedSupplier) {
      fetchProductsBySupplier(selectedSupplier);
    } else {
      getAllProducts();
    }
  }, [selectedType, selectedSupplier]);



    //get all products
    const getAllProducts = async () => {
      try {
        const response = await CustomAxios({
          method: "GET",
          baseURL: API_BASE_SMARTAJAP,
          url: "GetAllProducts",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          // console.log(response.data.products);
          setAllProducts(response.data.products);
          console.log(response.data.products[1].productDetails[0].ProductImage)
        }
      } catch (error) {
        console.error("Error fetching all products:", error);
      }
    };

  
    //fetch all the product types
    const getProductTypes = async()=>{
      try{
        const response = await CustomAxios({
          method: "GET",
          baseURL: API_BASE_SMARTAJAP,
          url: "ListProductTypes",
          headers: {
            "Content-Type": "application/json",
          },
        })
        if(response.status===200){
          // console.log("types",response.data.productTypes);
          setAllTypes(response.data.productTypes);
        }
      }catch(error){
        console.log(error)
      }
    }


    //fetch all product brand names
      const getProductCateogary=async()=>{
        try{
          const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_SMARTAJAP,
            url: "ListBrandNames",
            headers: {
              "Content-Type": "application/json",
            },
          })
          if(response.status===200){
            // console.log("brand", response.data.brandNames)
            setAllcategory(response.data.brandNames);
          }
        }catch(error){

        }
      }

    //handle the selected type
    const handleType = (e) => {
      setSelectedType(e.target.value);
    };

    //handle the selected supplier
    const handleSupplier = (e) => {
      setSelectedSupplier(e.target.value);
    };

    //fetch the products the type
    const fetchProductsByType = async (type) => {
        console.log("selected type", type);
        try {
          const response = await CustomAxios({
            method: "GET",
            baseURL: API_BASE_SMARTAJAP,
            url: `GetProductsByProductType`,
            params:{
              productType:type
            },
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.status === 200) {
            setAllProducts(response.data.products);
          }
        } catch (error) {
          console.error("Error fetching products by type:", error);
        }
      };
    

      const handleAddToCart = async(product,detail) => {
       
        const productData = {
          farmerid:userId,
          productId: product.productID,
          productDetailsId: detail.productDetailsID,
          productName: product.productName,
          productType: product.productType,
          volume: detail.productVolume,
          unitPrice: detail.productPrice,
          supplierID:product.supplierID,
          supplierLocation:product.supplierAddress,
          quantity: 1,
          brandName:product.brandName,
        };
        try{
          const response = await CustomAxios({
            method: "POST",
            baseURL: API_BASE_SMARTSCAP,
            url: `createCart`,
            data:productData,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if(response.status === 200){
            setAlertProps({ severity: "success", message: product.productName + " Was added to cart successfully!", show: true });
          }
        }catch(error){
           setAlertProps({ severity: "error", message:" Error Occured while adding the product to the cart.", show: true });
        }
      };



  const fetchProductsBySupplier = async (supplier) => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: `GetProductsByBrandName`,
        params:{
          brandName:supplier
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setAllProducts(response.data.products);
      }
    } catch (error) {
      console.error("Error fetching products by supplier:", error);
    }
  };

  const fetchProductsByTypeAndSupplier = async (type, supplier) => {
    try {
      const response = await CustomAxios({
        method: "GET",
        baseURL: API_BASE_SMARTAJAP,
        url: `GetProductsByTypeAndBrand`,
        params: {
          productType: type,
          brandName: supplier,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setAllProducts(response.data.products);

      }
    } catch (error) {
      console.error("Error fetching products by type and supplier:", error);
    }
  };



  // const handleAddProductModel = () => {
  //   setShowAddModel(true);
  // };

  // const handleCloseAddModel = () => {
  //   setShowAddModel(false);
  // };



  return (
    <>
      <header className="mt-6 rounded-t-md text-black text-sm relative">
        <div className="max-w-7xl  flex justify-between items-center">
          <div className="flex sm:flex-row gap-3 mob2:flex-col w-full">
            <div className="flex flex-row gap-3 mob2:justify-between sm:justify-normal items-start">
              {/* the dropdown for product types */}
              <select
              value={selectedType}
              onChange={handleType}
              className="p-2 rounded bg-white text-gray-800 focus:outline-none"
            >
              <option value="">Sort By Type</option>
              {allTypes && allTypes.map((type,index) =>(
                   <option key={index} value={type}>{type}</option>
              ))}
            </select>

            {/* the dropdownfor product brandnames */}
            <select
              value={selectedSupplier}
              onChange={handleSupplier}
              className="p-2 rounded bg-white text-gray-800 focus:outline-none"
            >
                <option value="">Sort By Supplier</option>
                {allCategory && allCategory.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </select>
            </div>
          

            <div className="flex mob2:justify-center sm:justify-normal">
                {/* Add product button for supplier */}
                <button 
                    className={`${userRole === "supplier" ? "block" : "hidden"}  bg-agro_darkgreen p-2 w-32 rounded-md text-white`}
                    onClick={()=>navigate("/dashboard/createProduct")}
                  >
                    Add Product
                  </button>
            </div>
 
          </div>
        </div>
      </header>


      <main className="mt-6 grid xl:grid-cols-5 gap-6 md:grid-cols-3 sm:grid-cols-2 mob2:grid-cols-1">

        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {allProducts.map((supplier) => (supplier.products.length >0  && supplier.products.map((product) =>(product.productDetails.map((detail) => (
                <div key={detail.productID} className="bg-white p-4 rounded-lg shadow-md relative">
                <button
                  onClick={() => handleAddToCart(product)}
                  className={`${userRole == "supplier" ? "hidden" : "absolute"} bottom-4 right-4 bg-green-600 text-white p-2 rounded-full shadow-lg hover:bg-green-700`}
                >
                  <FaCartPlus className="text-lg" />
                </button>
                <img src={product.imageUrl || Seeds} onError={(e) => {e.target.src = Seeds}} alt={product.productName} className="w-full h-32 object-cover rounded mb-4" />
  
                <h3 className="text-xl font-semibold mb-2">{product.productName}</h3>
                <p className="text-gray-600">Type: {product.productType}</p>
                <p className="text-gray-600">Volume: {detail.productVolume}</p>
                <p className="text-gray-800 font-semibold">Price: {detail.productPrice}</p>
                <p className="text-gray-800 font-semibold hidden">SupplierID: {product.supplierID}</p>
              </div>
              ))
            ))

          ))}
        </div> */}

          {allProducts.filter((supplier) => supplier.productID && supplier.productDetails?.length > 0) // Ensure valid products
            .map((product) => (
              product.productDetails.map((detail) => (
                <ProductItemCard
                  key={detail.productDetailsID}
                  product={product}
                  productAddress={product.supplierAddress}
                  ItemDetails={detail}
                  onClick={() => handleAddToCart(product,detail)}
                />
              ))
            ))}

      </main>

      {/* Calling the Alert component */}
      <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />

      {/* {showAddModel && (
          <AddNewProduct closeModel={handleCloseAddModel} refreshProducts={getAllProducts} />
        )} */}
    </>
  );
};

export default Products;
