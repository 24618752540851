import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import CustomAxios from '../../API/CustomAxios';
import { setUserRole, setUserID } from '../ReduxManagement/authSlice';
import { useDispatch } from 'react-redux';
import { setSignupUsername } from '../ReduxManagement/signupSlice';
import { setHeaderContent } from '../ReduxManagement/headercontentSlice';
import { setToken } from '../ReduxManagement/authSlice';
import { setAddress } from '../ReduxManagement/authSlice';
import { setAccessToken } from '../ReduxManagement/authSlice';
import Alert from '../../Components/Alert';
import showpw from '../../Assests/showpw.png';
import hidepw from '../../Assests/hidepw.png';
import GridLoader from 'react-spinners/GridLoader';
import PasswordMailVerify from '../Forgotpassword/PasswordMailVerify';
import EmailVerification from '../../Components/EmailVerification';
import ForgotPassword from '../Forgotpassword/ForgotPassword'
import EmailVerified from '../../Components/EmailVerified';
import axios from 'axios';
import Model from '../../Components/Model';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';


function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
  const navigate = useNavigate();
  const [img, setImg] = useState(hidepw);
  const [type, setType] = useState("password");
  const [model, setModel] = useState(false);
  const [showEmailModel, setShowEmailModel] = useState(false);
  const [showMailVerifiedModel, setMailVerifiedModel] = useState(false);
  const [showPasswordResetModel, setShowPasswordResetModel] = useState(false)
 
 
  useEffect(()=>{
  },[showEmailModel])


  useEffect(() => {
    if (location.state?.emailVerified) {
      setMailVerifiedModel(true); // Show Email Verification modal
    }

    if (location.state?.showForgotPassword) {
      setShowPasswordResetModel(true); // Show Password Reset modal
    }
  }, [location.state]);


  //hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };
  


  const handleLoginSubmit = async (values) => {
    try {
      setModel(true); 
      const response = await axios.post(`${API_BASE_NORMALSCAP}user/login`,values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setAlertProps({ message: "Login is successful!", severity: "success", show: true });
        const { username: newusername, role: userRole, uid: userID, address: myaddress } = response.data.user;
        const { refresh_token: refreshToken, access_token: accessToken } = response.data.token;

        dispatch(setUserRole(userRole));
        dispatch(setSignupUsername(newusername));
        dispatch(setToken(refreshToken));
        dispatch(setHeaderContent("Overview"));
        dispatch(setUserID(userID));
        dispatch(setAddress(myaddress));
        dispatch(setAccessToken(accessToken));

        setTimeout(() => {
          setModel(false);
          navigate("/dashboard");
        }, 2000);
        
      } else if (response.status === 401) {
        setModel(false);
        setAlertProps({ severity: "error", message: response.data.error, show: true });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      setAlertProps({ severity: "error", message: errorMessage, show: true });
      setTimeout(() => {
        setModel(false); 
      }, 3000);
    }
  };


  //validation for login
  const validate = Yup.object({
    username:Yup.string()
    // .min(8, "Must be more than 8 characters")
    // .max(100, "Must be less than 100 characters")
    // .matches( /^[a-z0-9@_]+$/,"*Username must include at least one number and can only contain lowercase letters, @, and _")
    .required("*Required"),
    password:Yup.string()
    // .min(8, "Password must be at least 8 characters")
    // .max(100, "Must be less than 100 characters")
    // .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    // .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    // .matches(/[0-9]/, "Password must contain at least one number")
    // .matches(/[@_]/, "Password must contain at least one symbol (@ or _)")
    .required("*Password is Required"),
  })

  //handle password toggle
  const handlePwToggle =()=>{
    if(type === 'password'){
      setImg(showpw);
      setType("text")
    }else{
      setImg(hidepw);
      setType('password')
    }
  }
  return (
    <div className='flex flex-col w-full mt-4 relative h-full items-center justify-evenly'>
      <Model showModel={model}/>
      {/* samll description */}
      <div className='md:mt-4 absolute sm:top-4 mob2:top-8 md:text-center mob2:text-center mob:mt-4 mob2:mt-8'>
        <h1 className='font-semibold md:text-[18px] mob2:text-[20px] '>Welcome to the Harvest Hub</h1>
        <p className='md:text-xs sm:text-[13px] mob2:text-[12px] mt-2 text-black/50 tracking-wider'>A Decentralized Based Supply Chain Management Ecosystem</p>
      </div>

      <Formik
        initialValues={{
           username:"",
           password:""
        }}
        validationSchema={validate}
        onSubmit={handleLoginSubmit}
      >
        {(formik) => (
          
          <Form className='font-normal  sm:mt-8 gap-6 text-[12px] w-full flex flex-col mob2:px-4 sm:px-12 md:px-0'>
            {/* The field for username */}
            <div className='flex flex-col h-20'>
              <label>Username</label>
              <Field 
                type="text"
                id="username"
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                className='border w-full mt-1  focus:outline-none p-3 text-normal text-[12px] text-black/70 rounded-lg'
              />
              <ErrorMessage 
                name="username" 
                component="div" 
                className="text-red-500 text-[9px] font-medium italic mt-1" 
              />
            </div>

            {/* The field for password */}
            <div className='flex flex-col h-20 relative'>
              <label className='relative'>Password</label>
              <Field 
                type={type}
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className='border  w-full mt-1 focus:outline-none p-3 text-normal text-[12px] text-black/70 rounded-lg'
              />
              <button  onClick={(e) => {e.preventDefault(); handlePwToggle();}}>
                  <img src={img} className='absolute w-[20px] h-[20px] bottom-6 right-4 opacity-40'/>
              </button>
              
              <ErrorMessage 
                name="password" 
                component="div" 
                className="text-red-500 text-[9px] font-medium italic mt-1" 
              />
            </div>

            <div className='mt-8 flex flex-col items-center w-full  relative'>
              <button type ="submit" className='bg-agro_darkgreen font-semibold  w-full text-white p-2  text-[13px] rounded-lg'>
                LogIn
              </button>
              <h3 className=' flex w-full flex-row items-center justify-center font-light text-black/50 tracking-wider mt-8 md:text-[12px] sm:text-[13px]'>Not Registered yet?
                  <button
                    type="button"
                    onClick={ ()=>navigate("/homesignup")}
                    className='text-agro_darkgreen font-semibold ml-1 md:text-[12px]'
                  > 
                    Create an Account
                  </button> 
              </h3>
              <h3 className='text-[12px] text-black/50 mt-4 tracking-wider flex mob2:flex-row  justify-center gap-2'>Forgot your password 
                <button className=' text-xs font-semibold underline-offset-1 cursor-pointer text-agro_darkgreen' onClick={(e)=>{e.preventDefault();setShowEmailModel(true)}}>
                        Click here...
                </button>
              </h3>
            </div>
          </Form>
        )}
      </Formik>

      {/* Calling the Alert component */}
      <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />

      {/* <div className='flex '>
        <h3 className='text-[12px] text-black/50 mt-4 tracking-wider flex mob2:flex-row  justify-center gap-2'>Forgot your password 
            <button className=' text-xs font-semibold underline-offset-1 cursor-pointer text-agro_darkgreen' onClick={(e)=>{e.preventDefault();setShowEmailModel(true)}}>
                    Click here...
            </button>
        </h3>  
      </div> */}
      {showEmailModel && (<PasswordMailVerify onClose={()=>setShowEmailModel(false)}/>)}
      {showMailVerifiedModel && <EmailVerified onClose={() => setMailVerifiedModel(false)} />}
      {showPasswordResetModel && <ForgotPassword  onClose={() => setShowPasswordResetModel(false)} />}
    </div>
  )
}

export default Login