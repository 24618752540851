import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userRole: null,
    userID: null,
    REFRESHTOKEN:null,
    ACCESSTOKEN:null,
    storeAddress:null,
    brandname:null,
    userImg:null,
    updatingEmail:null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        setUserID: (state, action) =>{
            state.userID = action.payload;
        },
        setToken:(state, action)=>{
            state.REFRESHTOKEN = action.payload;
        },
        setAccessToken:(state,action)=>{
            state.ACCESSTOKEN = action.payload;
        },
        setAddress:(state, action)=>{
            state.storeAddress = action.payload;
        },
        SetBrandName:(state,action)=>{
            state.brandname = action.payload;
        },
        setUserImg:(state, action)=>{
            state.userImg = action.payload;
        },
        setChangingEmail:(state,action)=>{
            state.updatingEmail = action.payload;
        },
        clearAuth:(state)=>{
            state.userID = initialState.userID;
            state.userRole = initialState.userRole;
            state.REFRESHTOKEN = initialState.REFRESHTOKEN;
            state.ACCESSTOKEN = initialState.ACCESSTOKEN;
            state.storeAddress = initialState.storeAddress;
            state.brandname = initialState.brandname;
            state.userImg = initialState.userImg;
        },
    },
});

export const { setUserRole, setUserID, clearAuth, setAddress, setAccessToken,setChangingEmail, setToken,SetBrandName,setUserImg } = authSlice.actions;
export default authSlice.reducer;
