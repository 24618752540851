import React,{useState} from 'react';
import CustomAxios from '../../API/CustomAxios';
import showpw from '../../Assests/showpw.png';
import hidepw from '../../Assests/hidepw.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';


function ForgotPassword({ onClose }) {
    const location = useLocation();
    const { role, uid, resetToken, showForgotPassword } = location.state || {};
    const [img, setImg] = useState(hidepw);
    const [type, setType] = useState("password");
    const [confirmtype, setConfirmtype] = useState("password");
    const [confirmImg, setConfirmImg] = useState(hidepw);


    const handlePasswordReset = async (values) => {
        const {confirm_password, ...newValues} = values;
        try {
            const response = await CustomAxios({
                method: 'PUT',
                baseURL: API_BASE_NORMALSCAP,
                url: `user/verify/forgetpassword/${role}/${uid}/${resetToken}/${newValues.password}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                onClose();
                console.log('Password reset successful');
            } else {
                console.log('Password reset failed');
            }
        } catch (error) {
            onClose();
            console.error('Error resetting password:', error);
        }
    };

    const validate = Yup.object({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .max(100, 'Must be less than 100 characters')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[@_]/, 'Password must contain at least one symbol (@ or _)')
            .required('*Password is Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], '*Passwords must match')
            .required('*Confirm password is required'),
    });

    const handlePwToggle = ()=>{
        if (type === "password") {
            setImg(showpw); 
            setType("text"); 
        } else {
            setImg(hidepw); 
            setType("password");
        }
    }

    //handle password toggle
    const handleConfirmPwToggle = () => {
      if (confirmtype === "password") {
          setConfirmImg(showpw); 
          setConfirmtype("text"); 
      } else {
          setConfirmImg(hidepw); 
          setConfirmtype("password");
      }
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gradient-to-r max-w-[400px] w-full from-agro_greenlight via-agro_darkgreen to-agro_darkyellow p-1 rounded-lg">
                <div className="bg-white p-6 sm:rounded-lg shadow-lg flex flex-col gap-4 relative overflow-y-auto no-scrollbar">
                    <h3 className="text-xl font-bold text-agro_darkgreen text-center">Reset Password</h3>
                    <p className="text-gray-500 text-center">Enter your new password below.</p>
                    <button
                        className="absolute top-2 right-4 font-bold text-lg text-red-500"
                        onClick={()=>onClose()}
                    >
                        ✕
                    </button>
                    <Formik
                        initialValues={{
                            password: '',
                            confirm_password: '',
                        }}
                        validationSchema={validate}
                        onSubmit={handlePasswordReset}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col gap-2">
                                <div className="flex flex-col h-16 relative">
                                    <Field
                                        type={type}
                                        id="password"
                                        name="password"
                                        placeholder="New password"
                                        className={`border focus:outline-none border-agro_darkgreen/50 p-3 text-black w-full rounded-md ${errors.password && touched.password ? 'border-red-500' : ''}`}
                                    />
                                    <button onClick={(e) => {e.preventDefault(); handlePwToggle();}}>
                                        <img src={img} className='absolute w-[20px] h-[20px] bottom-8 opacity-50 right-4'/>
                                    </button>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="text-red-500 text-[9px] font-medium italic mt-1 "
                                    />
                                </div>

                                <div className="flex flex-col h-16 relative">
                                    <Field
                                        type={confirmtype}
                                        id="confirm_password"
                                        name="confirm_password"
                                        placeholder="Confirm Password"
                                        className={`border focus:outline-none border-agro_darkgreen/50 p-3 text-black w-full rounded-md ${errors.confirm_password && touched.confirm_password ? 'border-red-500' : ''}`}
                                    />
                                    
                                    <button onClick={(e) =>{e.preventDefault(); handleConfirmPwToggle();}}>
                                        <img src={confirmImg} className='absolute w-[20px] h-[20px] opacity-50 bottom-8 right-4'/>
                                    </button>

                                    <ErrorMessage
                                        name="confirm_password"
                                        component="div"
                                        className="text-red-500 text-[9px] font-medium italic mt-1"
                                    />
                                </div>

                                {/* Submit Button */}
                                <button
                                    type="submit"
                                    className="bg-agro_darkgreen px-4 py-2 text-white rounded-md hover:bg-agro_darkgreen/80 transition mt-4"
                                >
                                    Reset Password
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
