import { createSlice } from "@reduxjs/toolkit";
import { clearSignup } from "./signupSlice";
import { clearAuth } from "./authSlice";
import { clearHeaderContent } from "./headercontentSlice";
import { useNavigate } from "react-router-dom";

export const logoutSlice = createSlice({
  name: "logout",
  initialState: {},
  reducers: {
    logout: (state) => {
    },
  },
});


export const handleLogout = () => (dispatch) => {
  dispatch(clearSignup());
  dispatch(clearAuth());
  dispatch(clearHeaderContent());
  // window.location.replace("/");
};

export const { logout } = logoutSlice.actions;

export default logoutSlice.reducer;
