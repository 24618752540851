import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomAxios from '../../API/CustomAxios';
import Alert from '../../Components/Alert';
import edit from '../../Assests/edit.png';
import deleteimg from "../../Assests/delete.png";
import { FaEdit, FaTrash } from 'react-icons/fa';


function UserLandDetails({setSelectedLand,setGetAllLands}) {
    const API_BASE_SMARTSCAP = process.env.REACT_APP_API_SMARTSCAP;
    const [userId, setUserID] = useState(useSelector((state)=>state.auth.userID));
    const [alertProps, setAlertProps] = useState({ message: "", severity: "success", show: false });
    const [myLands, setMyLands] = useState([]);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [DeleteLand, setDeleteLand]=useState("");
    
    useEffect(()=>{
        setGetAllLands(()=>getAllLands);
        getAllLands();
    },[])

    //select a land
    const handleEdit = (land) => {
        setSelectedLand(land);
    };

    //hide alerts
    const hideAlert = () => {
        setAlertProps({ ...alertProps, show: false });
    };
      
    //fetch all the lands
    const getAllLands =async()=>{
        try{
            const response = await CustomAxios({
                method:"GET",
                baseURL:API_BASE_SMARTSCAP,
                url:"getAllLandsByFarmer",
                params:{
                    farmerID:userId
                },
                headers:{
                "Content-Type":"application/json",
                },
            })
            if(response.status === 200){
                setMyLands(response.data)
            }
        }catch(erorr){

        }
    }
 
    //handling the land delete
    const hadnleDeletingLand =async()=>{
        try{
            const response = await CustomAxios({
                method:"DELETE",
                baseURL:API_BASE_SMARTSCAP,
                url:"deleteLandByID",
                params:{
                    id:DeleteLand.LandID
                },
                headers:{
                "Content-Type":"application/json",
                },
            })
            if(response.status === 200){
                getAllLands();
                setShowDeleteModel(false);
                setAlertProps({ severity: "success", message: `${DeleteLand.Location} Land was Deleted Successfully!`, show: true });
            }else{
                // setShowDeleteModel(false);
                setAlertProps({ severity: "error", message: response.data.error, show: true });
            }
        }catch(erorr){
            setShowDeleteModel(false);
            setAlertProps({ severity: "error", message: "An Error Occurred", show: true });
        }
    }

    //set the deleting lan to state
    const handleSelectingDelete =(deletingLand)=>{
        setDeleteLand(deletingLand);
        setShowDeleteModel(true);
    }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full h-full ">
            <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">User Lands</h1>
            <div className="relative">
                <div className="absolute sm:left-4 mob:left-2  mob2:left-[4px] top-0 h-full border-l-2 border-gray-300"></div>
                <div className="mob:pl-10 mob2:pl-6 space-y-4">
                    {myLands && myLands.map((land, index) => (
                        <div key={land.LandID} className="relative flex items-center">
                            <div className="absolute left-[-5px] top-[-5px] w-4 h-4 bg-green-500 rounded-full"></div>
                            <div className="bg-gray-100 flex flex-row justify-between w-full items-center p-3 rounded-md shadow-sm flex-1">
                                <div className='flex flex-col w-3/4'>
                                    <h3 className="font-semibold text-gray-700">{land.Location}</h3>
                                    <p className="text-sm text-gray-600">
                                        <span className="text-xs">Owned Hectares:</span> {land.LandSize} ha
                                    </p>
                                    <p className="text-sm text-gray-600 ">
                                        <span className="text-xs ">Cultivating Hectares:</span> {land.CultivatedSize} ha
                                    </p>
                                </div>

                                <div className='flex flex-row gap-2'>
                                    <button className='w-4 h-4' onClick={() => handleEdit(land)} >
                                        <img src={edit}/>
                                    </button>
                                    <button className='w-4 h-4' onClick={() => handleSelectingDelete(land)} >
                                        <img src={deleteimg}/>
                                    </button>
                                </div>
                            </div>                   
                        </div>
                    ))}
                </div>
            </div>
      <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
       />



{showDeleteModel &&(
       <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
       <div className='bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center justify-center gap-4'>
         <h1 className='text-sm text-center'>
           Are you sure you want to delete the Land{' '}
           <span className='font-semibold text-md'>{DeleteLand.Location}</span>?
         </h1>
         <div className='flex flex-row justify-between gap-12 text-white text-sm font-semibold'>
           <button className='bg-agro_greenlight py-2 rounded-md px-4' onClick={() => hadnleDeletingLand()}>
             Delete
           </button>
           <button
             className='bg-red-500 py-2 rounded-md px-4'
             onClick={() => setShowDeleteModel(false)}
           >
             Cancel
           </button>
         </div>
       </div>
     </div>
)}
    </div>
  );
}

export default UserLandDetails;
