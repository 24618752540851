import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import CustomAxios from '../../API/CustomAxios';
import { useSelector } from 'react-redux';
import Alert from '../../Components/Alert';
import {API_BASE_NORMALSCAP} from '../../API/APIUrls';


function UserRequests() {
  const [displayList, setDisplayList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const token = useSelector((state) => state.auth.ACCESSTOKEN);
  const [alertProps, setAlertProps] = useState({ message: '', severity: 'success', show: false });
  const [userRequestType, setUserRequestType] = useState('farmers'); // Default to farmers


  // Hide alerts
  const hideAlert = () => {
    setAlertProps({ ...alertProps, show: false });
  };


  // Fetch user requests
  const getUserRequests = async () => {
    try {
      const endpoint = userRequestType === 'farmers' ? 'listUserRegistrations' : 'listSuppliersRegistrations';
      const response = await CustomAxios({
        method: 'GET',
        baseURL: API_BASE_NORMALSCAP,
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      });
  
      if (response.status === 200) {
        userRequestType === 'farmers' ? setDisplayList(response.data.users || []) :setDisplayList(response.data.suppliers || []);
        
      }else{
        setDisplayList([]); 
      } 
    } catch (error) {
      setDisplayList([]); 
    //   setAlertProps({severity: 'error',message: `Failed to fetch ${userRequestType} data. Please try again later.`,show: true,});
    }
  };
  

  useEffect(() => {
    getUserRequests();
  }, [userRequestType]);


  // Handle verifying a single user
  const handleVerify = async (userId) => {
    const endpoint = userRequestType === 'farmers' ? 'user/updateVerifyStatus/' : 'supplier/updateVerifyStatus/';
    try {
      const response = await CustomAxios({
        method: 'PUT',
        baseURL: API_BASE_NORMALSCAP,
        url: `${endpoint}${userId}`,
        data: { 
          isVerified: true 
        },
        headers: {
          'Content-Type': 'application/json',
           token: token,
        },
      });

      if (response.status === 200) {
        setAlertProps({ severity: 'success', message: 'Verified Successfully!', show: true });
        setDisplayList((prevState) =>
          prevState.map((user) => (user.uid === userId ? { ...user, isVerified: true } : user))
        );
        getUserRequests(); // Refresh the list
      }
    } catch (error) {
      console.log(error);
    }
  };


  // Handle changing the user request type
  const handleTypeOfUser = (event) => {
    setUserRequestType(event.target.value);
    setPage(0);
  };

  // Handle verifying all selected users
  const handleAcceptAll = () => {
    selectedUsers.forEach((user) => handleVerify(user));
  };

  // Handle selecting all checkboxes
  const handleSelectAllCheckboxes = () => {
    selectedUsers.length === displayList.length
      ? setSelectedUsers([])
      : setSelectedUsers(displayList.map((user) => user.uid));
  };

  // Handle checkbox selection
  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="flex flex-col rounded-lg mt-4">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between mb-2">
            <div className="flex flex-row gap-4">
              <select
                className="rounded-md p-2 text-sm focus:outline-none"
                onChange={handleTypeOfUser}
              >
                <option value="farmers">Farmers</option>
                <option value="suppliers">Suppliers</option>
              </select>
              <button
                onClick={handleAcceptAll}
                className="bg-agro_greenlight text-white text-sm p-2 px-4 rounded-md"
              >
                Verify All
              </button>
            </div>

            <TablePagination
              component="div"
              rowsPerPageOptions={[2, 10, 25, 50]}
              page={page}
              count={displayList ? displayList.length : 0}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <div className="overflow-x-auto rounded-lg bg-white">
            <table className="min-w-full rounded-lg">
              <thead className="bg-agro_lightgreen/60 text-black/70 text-left">
                <tr>
                  <th className="px-6 py-3 text-xs">
                    <input
                      type="checkbox"
                      checked={selectedUsers.length === displayList.length}
                      onChange={handleSelectAllCheckboxes}
                    />
                  </th>
                  <th className="px-6 py-3 text-xs">No</th>
                  <th className="px-6 py-3 text-xs">Name</th>
                  <th className="px-6 py-3 text-xs">Username</th>
                  <th className="px-6 py-3 text-xs">Email</th>
                  <th className="px-6 py-3 text-xs">Status</th>
                  <th className="px-6 py-3 text-xs">Phone No</th>
                  <th className="px-6 py-3 text-xs">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayList.length > 0 && (
                    displayList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => (
                        <tr key={index} className="text-xs font-normal border border-b-agro_lightgreen/50">
                        <td className="px-6 py-3">
                            <input
                            type="checkbox"
                            checked={selectedUsers.includes(user.uid)}
                            onChange={() => handleCheckboxChange(user.uid)}
                            />
                        </td>
                        <td className="px-6 py-3">{page * rowsPerPage + index + 1}</td>
                        <td className="px-6 py-3">{user.name}</td>
                        <td className="px-6 py-3">{user.username}</td>
                        <td className="px-6 py-3">{user.email}</td>
                        <td className="px-6 py-3">{user.status}</td>
                        <td className="px-6 py-3">{user.contactno}</td>
                        <td className="px-6 py-3">
                            <button
                            className="px-4 py-2 rounded-md text-black/60 font-semibold bg-agro_lightgreen/60 hover:bg-agro_lightgreen/40"
                            onClick={() => handleVerify(user.uid)}
                            >
                            Verify
                            </button>
                        </td>
                        </tr>
                    ))
                ) }
                </tbody>

            </table>
          </div>
        </div>
        <Alert
          message={alertProps.message}
          severity={alertProps.severity}
          show={alertProps.show}
          onClose={hideAlert}
        />
      </div>
    </>
  );
}

export default UserRequests;
