import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

function Model({ showModel }) {
  return (
    <>
      {showModel && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 bg-opacity-70">
          <GridLoader
            color="#0c3e26"
            size={22}
            margin={5}
            speedMultiplier={1}
            className="z-50"
          />
        </div>
      )}
    </>
  );
}

export default Model;
